/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: "Mobiscroll";
  src: url("~@mobiscroll/angular/dist/css/icons_mobiscroll.woff?fbiheo") format("woff"), url("~@mobiscroll/angular/dist/css/icons_mobiscroll.woff") format("woff"), url("~@mobiscroll/angular/dist/css/icons_mobiscroll.ttf?fbiheo") format("truetype");
  font-weight: normal;
  font-style: normal;
}
.mbsc-font-icon:before {
  font-family: "Mobiscroll";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Icons */
.mbsc-icon-arrow-down5::before {
  content: "\ea02";
}

.mbsc-icon-arrow-left5::before {
  content: "\ea01";
}

.mbsc-icon-arrow-left6::before {
  content: "\ea03";
}

.mbsc-icon-arrow-right5::before {
  content: "\ea04";
}

.mbsc-icon-arrow-right6::before {
  content: "\ea06";
}

.mbsc-icon-arrow-up5::before {
  content: "\ea05";
}

.mbsc-icon-eye-blocked::before {
  content: "\ea09";
}

.mbsc-icon-eye::before {
  content: "\ea08";
}

.mbsc-icon-ion-ios7-arrow-back::before {
  content: "\ea0a";
}

.mbsc-icon-ion-ios7-arrow-forward::before {
  content: "\ea07";
}

.mbsc-icon-ion-ios7-checkmark-empty::before {
  content: "\ea0c";
}

.mbsc-icon-ios-backspace::before {
  content: "\ea0d";
}

.mbsc-icon-minus::before {
  content: "\ea0e";
}

.mbsc-icon-plus::before {
  content: "\ea0b";
}

.mbsc-icon-star::before {
  content: "\ea10";
}

.mbsc-icon-star3::before {
  content: "\ea0f";
}

.mbsc-grid,
.mbsc-grid-unresp,
.mbsc-grid-fixed {
  width: 100%;
  padding-right: 1em;
  padding-left: 1em;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
}
.mbsc-grid *,
.mbsc-grid-unresp *,
.mbsc-grid-fixed * {
  box-sizing: border-box;
}

.mbsc-form-grid {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}

.mbsc-no-padding .mbsc-col,
.mbsc-no-padding [class*=mbsc-col-],
.mbsc-form-grid .mbsc-col,
.mbsc-form-grid [class*=mbsc-col-] {
  padding-right: 0;
  padding-left: 0;
}

.mbsc-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -1em;
  margin-left: -1em;
}

.mbsc-col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.mbsc-col-1,
.mbsc-col-2,
.mbsc-col-3,
.mbsc-col-4,
.mbsc-col-5,
.mbsc-col-6,
.mbsc-col-7,
.mbsc-col-8,
.mbsc-col-9,
.mbsc-col-10,
.mbsc-col-11,
.mbsc-col-12,
.mbsc-col,
.mbsc-col-auto,
.mbsc-col-sm-1,
.mbsc-col-sm-2,
.mbsc-col-sm-3,
.mbsc-col-sm-4,
.mbsc-col-sm-5,
.mbsc-col-sm-6,
.mbsc-col-sm-7,
.mbsc-col-sm-8,
.mbsc-col-sm-9,
.mbsc-col-sm-10,
.mbsc-col-sm-11,
.mbsc-col-sm-12,
.mbsc-col-sm,
.mbsc-col-sm-auto,
.mbsc-col-md-1,
.mbsc-col-md-2,
.mbsc-col-md-3,
.mbsc-col-md-4,
.mbsc-col-md-5,
.mbsc-col-md-6,
.mbsc-col-md-7,
.mbsc-col-md-8,
.mbsc-col-md-9,
.mbsc-col-md-10,
.mbsc-col-md-11,
.mbsc-col-md-12,
.mbsc-col-md,
.mbsc-col-md-auto,
.mbsc-col-lg-1,
.mbsc-col-lg-2,
.mbsc-col-lg-3,
.mbsc-col-lg-4,
.mbsc-col-lg-5,
.mbsc-col-lg-6,
.mbsc-col-lg-7,
.mbsc-col-lg-8,
.mbsc-col-lg-9,
.mbsc-col-lg-10,
.mbsc-col-lg-11,
.mbsc-col-lg-12,
.mbsc-col-lg,
.mbsc-col-lg-auto,
.mbsc-col-xl-1,
.mbsc-col-xl-2,
.mbsc-col-xl-3,
.mbsc-col-xl-4,
.mbsc-col-xl-5,
.mbsc-col-xl-6,
.mbsc-col-xl-7,
.mbsc-col-xl-8,
.mbsc-col-xl-9,
.mbsc-col-xl-10,
.mbsc-col-xl-11,
.mbsc-col-xl-12,
.mbsc-col-xl,
.mbsc-col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 1em;
  padding-left: 1em;
}

.mbsc-col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.mbsc-col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.mbsc-col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.mbsc-col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.mbsc-col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.mbsc-col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.mbsc-col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.mbsc-col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.mbsc-col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.mbsc-col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.mbsc-col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.mbsc-col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.mbsc-col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.mbsc-offset-1 {
  margin-left: 8.333333%;
}

.mbsc-offset-2 {
  margin-left: 16.666667%;
}

.mbsc-offset-3 {
  margin-left: 25%;
}

.mbsc-offset-4 {
  margin-left: 33.333333%;
}

.mbsc-offset-5 {
  margin-left: 41.666667%;
}

.mbsc-offset-6 {
  margin-left: 50%;
}

.mbsc-offset-7 {
  margin-left: 58.333333%;
}

.mbsc-offset-8 {
  margin-left: 66.666667%;
}

.mbsc-offset-9 {
  margin-left: 75%;
}

.mbsc-offset-10 {
  margin-left: 83.333333%;
}

.mbsc-offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .mbsc-grid.mbsc-grid-fixed {
    max-width: 540px;
  }
  .mbsc-grid .mbsc-col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .mbsc-grid .mbsc-col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .mbsc-grid .mbsc-col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .mbsc-grid .mbsc-col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .mbsc-grid .mbsc-col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .mbsc-grid .mbsc-col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .mbsc-grid .mbsc-col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .mbsc-grid .mbsc-col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .mbsc-grid .mbsc-col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .mbsc-grid .mbsc-col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .mbsc-grid .mbsc-col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .mbsc-grid .mbsc-col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .mbsc-grid .mbsc-col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .mbsc-grid .mbsc-col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .mbsc-grid .mbsc-offset-sm-0 {
    margin-left: 0;
  }
  .mbsc-grid .mbsc-offset-sm-1 {
    margin-left: 8.333333%;
  }
  .mbsc-grid .mbsc-offset-sm-2 {
    margin-left: 16.666667%;
  }
  .mbsc-grid .mbsc-offset-sm-3 {
    margin-left: 25%;
  }
  .mbsc-grid .mbsc-offset-sm-4 {
    margin-left: 33.333333%;
  }
  .mbsc-grid .mbsc-offset-sm-5 {
    margin-left: 41.666667%;
  }
  .mbsc-grid .mbsc-offset-sm-6 {
    margin-left: 50%;
  }
  .mbsc-grid .mbsc-offset-sm-7 {
    margin-left: 58.333333%;
  }
  .mbsc-grid .mbsc-offset-sm-8 {
    margin-left: 66.666667%;
  }
  .mbsc-grid .mbsc-offset-sm-9 {
    margin-left: 75%;
  }
  .mbsc-grid .mbsc-offset-sm-10 {
    margin-left: 83.333333%;
  }
  .mbsc-grid .mbsc-offset-sm-11 {
    margin-left: 91.666667%;
  }
  .mbsc-grid .mbsc-push-sm-0 {
    left: auto;
  }
  .mbsc-grid .mbsc-push-sm-1 {
    left: 8.33333333%;
  }
  .mbsc-grid .mbsc-push-sm-2 {
    left: 16.66666667%;
  }
  .mbsc-grid .mbsc-push-sm-3 {
    left: 25%;
  }
  .mbsc-grid .mbsc-push-sm-4 {
    left: 33.33333333%;
  }
  .mbsc-grid .mbsc-push-sm-5 {
    left: 41.66666667%;
  }
  .mbsc-grid .mbsc-push-sm-6 {
    left: 50%;
  }
  .mbsc-grid .mbsc-push-sm-7 {
    left: 58.33333333%;
  }
  .mbsc-grid .mbsc-push-sm-8 {
    left: 66.66666667%;
  }
  .mbsc-grid .mbsc-push-sm-9 {
    left: 75%;
  }
  .mbsc-grid .mbsc-push-sm-10 {
    left: 83.33333333%;
  }
  .mbsc-grid .mbsc-push-sm-11 {
    left: 91.66666667%;
  }
  .mbsc-grid .mbsc-push-sm-12 {
    left: 100%;
  }
  .mbsc-grid .mbsc-pull-sm-0 {
    right: auto;
  }
  .mbsc-grid .mbsc-pull-sm-1 {
    right: 8.33333333%;
  }
  .mbsc-grid .mbsc-pull-sm-2 {
    right: 16.66666667%;
  }
  .mbsc-grid .mbsc-pull-sm-3 {
    right: 25%;
  }
  .mbsc-grid .mbsc-pull-sm-4 {
    right: 33.33333333%;
  }
  .mbsc-grid .mbsc-pull-sm-5 {
    right: 41.66666667%;
  }
  .mbsc-grid .mbsc-pull-sm-6 {
    right: 50%;
  }
  .mbsc-grid .mbsc-pull-sm-7 {
    right: 58.33333333%;
  }
  .mbsc-grid .mbsc-pull-sm-8 {
    right: 66.66666667%;
  }
  .mbsc-grid .mbsc-pull-sm-9 {
    right: 75%;
  }
  .mbsc-grid .mbsc-pull-sm-10 {
    right: 83.33333333%;
  }
  .mbsc-grid .mbsc-pull-sm-11 {
    right: 91.66666667%;
  }
  .mbsc-grid .mbsc-pull-sm-12 {
    right: 100%;
  }
}
@media (min-width: 768px) {
  .mbsc-grid.mbsc-grid-fixed {
    max-width: 720px;
  }
  .mbsc-grid .mbsc-col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .mbsc-grid .mbsc-col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .mbsc-grid .mbsc-col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .mbsc-grid .mbsc-col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .mbsc-grid .mbsc-col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .mbsc-grid .mbsc-col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .mbsc-grid .mbsc-col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .mbsc-grid .mbsc-col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .mbsc-grid .mbsc-col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .mbsc-grid .mbsc-col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .mbsc-grid .mbsc-col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .mbsc-grid .mbsc-col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .mbsc-grid .mbsc-col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .mbsc-grid .mbsc-col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .mbsc-grid .mbsc-offset-md-0 {
    margin-left: 0;
  }
  .mbsc-grid .mbsc-offset-md-1 {
    margin-left: 8.333333%;
  }
  .mbsc-grid .mbsc-offset-md-2 {
    margin-left: 16.666667%;
  }
  .mbsc-grid .mbsc-offset-md-3 {
    margin-left: 25%;
  }
  .mbsc-grid .mbsc-offset-md-4 {
    margin-left: 33.333333%;
  }
  .mbsc-grid .mbsc-offset-md-5 {
    margin-left: 41.666667%;
  }
  .mbsc-grid .mbsc-offset-md-6 {
    margin-left: 50%;
  }
  .mbsc-grid .mbsc-offset-md-7 {
    margin-left: 58.333333%;
  }
  .mbsc-grid .mbsc-offset-md-8 {
    margin-left: 66.666667%;
  }
  .mbsc-grid .mbsc-offset-md-9 {
    margin-left: 75%;
  }
  .mbsc-grid .mbsc-offset-md-10 {
    margin-left: 83.333333%;
  }
  .mbsc-grid .mbsc-offset-md-11 {
    margin-left: 91.666667%;
  }
  .mbsc-grid .mbsc-push-md-0 {
    left: auto;
  }
  .mbsc-grid .mbsc-push-md-1 {
    left: 8.33333333%;
  }
  .mbsc-grid .mbsc-push-md-2 {
    left: 16.66666667%;
  }
  .mbsc-grid .mbsc-push-md-3 {
    left: 25%;
  }
  .mbsc-grid .mbsc-push-md-4 {
    left: 33.33333333%;
  }
  .mbsc-grid .mbsc-push-md-5 {
    left: 41.66666667%;
  }
  .mbsc-grid .mbsc-push-md-6 {
    left: 50%;
  }
  .mbsc-grid .mbsc-push-md-7 {
    left: 58.33333333%;
  }
  .mbsc-grid .mbsc-push-md-8 {
    left: 66.66666667%;
  }
  .mbsc-grid .mbsc-push-md-9 {
    left: 75%;
  }
  .mbsc-grid .mbsc-push-md-10 {
    left: 83.33333333%;
  }
  .mbsc-grid .mbsc-push-md-11 {
    left: 91.66666667%;
  }
  .mbsc-grid .mbsc-push-md-12 {
    left: 100%;
  }
  .mbsc-grid .mbsc-pull-md-0 {
    right: auto;
  }
  .mbsc-grid .mbsc-pull-md-1 {
    right: 8.33333333%;
  }
  .mbsc-grid .mbsc-pull-md-2 {
    right: 16.66666667%;
  }
  .mbsc-grid .mbsc-pull-md-3 {
    right: 25%;
  }
  .mbsc-grid .mbsc-pull-md-4 {
    right: 33.33333333%;
  }
  .mbsc-grid .mbsc-pull-md-5 {
    right: 41.66666667%;
  }
  .mbsc-grid .mbsc-pull-md-6 {
    right: 50%;
  }
  .mbsc-grid .mbsc-pull-md-7 {
    right: 58.33333333%;
  }
  .mbsc-grid .mbsc-pull-md-8 {
    right: 66.66666667%;
  }
  .mbsc-grid .mbsc-pull-md-9 {
    right: 75%;
  }
  .mbsc-grid .mbsc-pull-md-10 {
    right: 83.33333333%;
  }
  .mbsc-grid .mbsc-pull-md-11 {
    right: 91.66666667%;
  }
  .mbsc-grid .mbsc-pull-md-12 {
    right: 100%;
  }
}
@media (min-width: 992px) {
  .mbsc-grid.mbsc-grid-fixed {
    max-width: 960px;
  }
  .mbsc-grid .mbsc-col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .mbsc-grid .mbsc-col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .mbsc-grid .mbsc-col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .mbsc-grid .mbsc-col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .mbsc-grid .mbsc-col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .mbsc-grid .mbsc-col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .mbsc-grid .mbsc-col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .mbsc-grid .mbsc-col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .mbsc-grid .mbsc-col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .mbsc-grid .mbsc-col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .mbsc-grid .mbsc-col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .mbsc-grid .mbsc-col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .mbsc-grid .mbsc-col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .mbsc-grid .mbsc-col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .mbsc-grid .mbsc-offset-lg-0 {
    margin-left: 0;
  }
  .mbsc-grid .mbsc-offset-lg-1 {
    margin-left: 8.333333%;
  }
  .mbsc-grid .mbsc-offset-lg-2 {
    margin-left: 16.666667%;
  }
  .mbsc-grid .mbsc-offset-lg-3 {
    margin-left: 25%;
  }
  .mbsc-grid .mbsc-offset-lg-4 {
    margin-left: 33.333333%;
  }
  .mbsc-grid .mbsc-offset-lg-5 {
    margin-left: 41.666667%;
  }
  .mbsc-grid .mbsc-offset-lg-6 {
    margin-left: 50%;
  }
  .mbsc-grid .mbsc-offset-lg-7 {
    margin-left: 58.333333%;
  }
  .mbsc-grid .mbsc-offset-lg-8 {
    margin-left: 66.666667%;
  }
  .mbsc-grid .mbsc-offset-lg-9 {
    margin-left: 75%;
  }
  .mbsc-grid .mbsc-offset-lg-10 {
    margin-left: 83.333333%;
  }
  .mbsc-grid .mbsc-offset-lg-11 {
    margin-left: 91.666667%;
  }
  .mbsc-grid .mbsc-push-lg-0 {
    left: auto;
  }
  .mbsc-grid .mbsc-push-lg-1 {
    left: 8.33333333%;
  }
  .mbsc-grid .mbsc-push-lg-2 {
    left: 16.66666667%;
  }
  .mbsc-grid .mbsc-push-lg-3 {
    left: 25%;
  }
  .mbsc-grid .mbsc-push-lg-4 {
    left: 33.33333333%;
  }
  .mbsc-grid .mbsc-push-lg-5 {
    left: 41.66666667%;
  }
  .mbsc-grid .mbsc-push-lg-6 {
    left: 50%;
  }
  .mbsc-grid .mbsc-push-lg-7 {
    left: 58.33333333%;
  }
  .mbsc-grid .mbsc-push-lg-8 {
    left: 66.66666667%;
  }
  .mbsc-grid .mbsc-push-lg-9 {
    left: 75%;
  }
  .mbsc-grid .mbsc-push-lg-10 {
    left: 83.33333333%;
  }
  .mbsc-grid .mbsc-push-lg-11 {
    left: 91.66666667%;
  }
  .mbsc-grid .mbsc-push-lg-12 {
    left: 100%;
  }
  .mbsc-grid .mbsc-pull-lg-0 {
    right: auto;
  }
  .mbsc-grid .mbsc-pull-lg-1 {
    right: 8.33333333%;
  }
  .mbsc-grid .mbsc-pull-lg-2 {
    right: 16.66666667%;
  }
  .mbsc-grid .mbsc-pull-lg-3 {
    right: 25%;
  }
  .mbsc-grid .mbsc-pull-lg-4 {
    right: 33.33333333%;
  }
  .mbsc-grid .mbsc-pull-lg-5 {
    right: 41.66666667%;
  }
  .mbsc-grid .mbsc-pull-lg-6 {
    right: 50%;
  }
  .mbsc-grid .mbsc-pull-lg-7 {
    right: 58.33333333%;
  }
  .mbsc-grid .mbsc-pull-lg-8 {
    right: 66.66666667%;
  }
  .mbsc-grid .mbsc-pull-lg-9 {
    right: 75%;
  }
  .mbsc-grid .mbsc-pull-lg-10 {
    right: 83.33333333%;
  }
  .mbsc-grid .mbsc-pull-lg-11 {
    right: 91.66666667%;
  }
  .mbsc-grid .mbsc-pull-lg-12 {
    right: 100%;
  }
}
@media (min-width: 1200px) {
  .mbsc-grid.mbsc-grid-fixed {
    max-width: 1140px;
  }
  .mbsc-grid .mbsc-col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .mbsc-grid .mbsc-col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .mbsc-grid .mbsc-col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .mbsc-grid .mbsc-col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .mbsc-grid .mbsc-col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .mbsc-grid .mbsc-col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .mbsc-grid .mbsc-col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .mbsc-grid .mbsc-col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .mbsc-grid .mbsc-col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .mbsc-grid .mbsc-col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .mbsc-grid .mbsc-col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .mbsc-grid .mbsc-col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .mbsc-grid .mbsc-col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .mbsc-grid .mbsc-col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .mbsc-grid .mbsc-offset-xl-0 {
    margin-left: 0;
  }
  .mbsc-grid .mbsc-offset-xl-1 {
    margin-left: 8.333333%;
  }
  .mbsc-grid .mbsc-offset-xl-2 {
    margin-left: 16.666667%;
  }
  .mbsc-grid .mbsc-offset-xl-3 {
    margin-left: 25%;
  }
  .mbsc-grid .mbsc-offset-xl-4 {
    margin-left: 33.333333%;
  }
  .mbsc-grid .mbsc-offset-xl-5 {
    margin-left: 41.666667%;
  }
  .mbsc-grid .mbsc-offset-xl-6 {
    margin-left: 50%;
  }
  .mbsc-grid .mbsc-offset-xl-7 {
    margin-left: 58.333333%;
  }
  .mbsc-grid .mbsc-offset-xl-8 {
    margin-left: 66.666667%;
  }
  .mbsc-grid .mbsc-offset-xl-9 {
    margin-left: 75%;
  }
  .mbsc-grid .mbsc-offset-xl-10 {
    margin-left: 83.333333%;
  }
  .mbsc-grid .mbsc-offset-xl-11 {
    margin-left: 91.666667%;
  }
  .mbsc-grid .mbsc-push-xl-0 {
    left: auto;
  }
  .mbsc-grid .mbsc-push-xl-1 {
    left: 8.33333333%;
  }
  .mbsc-grid .mbsc-push-xl-2 {
    left: 16.66666667%;
  }
  .mbsc-grid .mbsc-push-xl-3 {
    left: 25%;
  }
  .mbsc-grid .mbsc-push-xl-4 {
    left: 33.33333333%;
  }
  .mbsc-grid .mbsc-push-xl-5 {
    left: 41.66666667%;
  }
  .mbsc-grid .mbsc-push-xl-6 {
    left: 50%;
  }
  .mbsc-grid .mbsc-push-xl-7 {
    left: 58.33333333%;
  }
  .mbsc-grid .mbsc-push-xl-8 {
    left: 66.66666667%;
  }
  .mbsc-grid .mbsc-push-xl-9 {
    left: 75%;
  }
  .mbsc-grid .mbsc-push-xl-10 {
    left: 83.33333333%;
  }
  .mbsc-grid .mbsc-push-xl-11 {
    left: 91.66666667%;
  }
  .mbsc-grid .mbsc-push-xl-12 {
    left: 100%;
  }
  .mbsc-grid .mbsc-pull-xl-0 {
    right: auto;
  }
  .mbsc-grid .mbsc-pull-xl-1 {
    right: 8.33333333%;
  }
  .mbsc-grid .mbsc-pull-xl-2 {
    right: 16.66666667%;
  }
  .mbsc-grid .mbsc-pull-xl-3 {
    right: 25%;
  }
  .mbsc-grid .mbsc-pull-xl-4 {
    right: 33.33333333%;
  }
  .mbsc-grid .mbsc-pull-xl-5 {
    right: 41.66666667%;
  }
  .mbsc-grid .mbsc-pull-xl-6 {
    right: 50%;
  }
  .mbsc-grid .mbsc-pull-xl-7 {
    right: 58.33333333%;
  }
  .mbsc-grid .mbsc-pull-xl-8 {
    right: 66.66666667%;
  }
  .mbsc-grid .mbsc-pull-xl-9 {
    right: 75%;
  }
  .mbsc-grid .mbsc-pull-xl-10 {
    right: 83.33333333%;
  }
  .mbsc-grid .mbsc-pull-xl-11 {
    right: 91.66666667%;
  }
  .mbsc-grid .mbsc-pull-xl-12 {
    right: 100%;
  }
}
.mbsc-align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.mbsc-align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.mbsc-align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.mbsc-justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.mbsc-justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.mbsc-justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.mbsc-justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.mbsc-justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.mbsc-icon {
  display: inline-block;
  vertical-align: middle;
  width: 1.5em;
  height: 1.5em;
  line-height: 1.5em;
  text-align: center;
  flex: 0 0 auto;
}

.mbsc-icon > svg {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  fill: currentColor;
}

:root {
  --mbsc-safe-top: 0;
  --mbsc-safe-right: 0;
  --mbsc-safe-bottom: 0;
  --mbsc-safe-left: 0;
}
@supports (top: constant(safe-area-inset-top)) {
  :root {
    --mbsc-safe-top: constant(safe-area-inset-top);
    --mbsc-safe-right: constant(safe-area-inset-right);
    --mbsc-safe-bottom: constant(safe-area-inset-bottom);
    --mbsc-safe-left: constant(safe-area-inset-left);
  }
}
@supports (top: env(safe-area-inset-top)) {
  :root {
    --mbsc-safe-top: env(safe-area-inset-top);
    --mbsc-safe-right: env(safe-area-inset-right);
    --mbsc-safe-bottom: env(safe-area-inset-bottom);
    --mbsc-safe-left: env(safe-area-inset-left);
  }
}

.mbsc-font {
  font-family: -apple-system, "Segoe UI", Roboto, sans-serif;
  font-size: 16px;
  font-weight: normal;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
}

.mbsc-reset {
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.mbsc-resize {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: -1;
}

.mbsc-resize-i {
  position: absolute;
  left: 0;
  top: 0;
}

.mbsc-resize-y {
  width: 200%;
  height: 200%;
}

.mbsc-hidden {
  visibility: hidden;
}

.mbsc-hidden-content {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  user-select: none;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
}

.mbsc-ltr {
  direction: ltr;
}

.mbsc-rtl {
  direction: rtl;
}

.mbsc-ripple {
  background: currentColor;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  border-radius: 1000em;
  pointer-events: none;
  transform: scale(0);
}

/* Flex util classes */
.mbsc-flex,
.mbsc-flex-col {
  display: flex;
}

.mbsc-flex-col {
  flex-direction: column;
}

.mbsc-flex-1-1 {
  flex: 1 1 auto;
}

.mbsc-flex-1-0 {
  flex: 1 0 auto;
}

.mbsc-flex-1-0-0 {
  flex: 1 0 0;
}

/* IE11 hack, where flex-basis auto does not work correctly */
@media all and (-ms-high-contrast: none) {
  .mbsc-flex-1-0-0 {
    flex: 1 0 auto;
  }
}
.mbsc-flex-none {
  flex: none;
}

@media (-webkit-min-device-pixel-ratio: 2) {
  .mbsc-hb,
  .mbsc-hb:before,
  .mbsc-hb:after {
    border-style: none;
    border-width: 0.5px !important;
  }
}
/* Base colors */
/* Form colors */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
.mbsc-ios.mbsc-form-control-wrapper {
  margin-top: -1px;
}
.mbsc-ios.mbsc-form-control-wrapper.mbsc-error {
  z-index: 2;
}
.mbsc-ios.mbsc-form-control-wrapper:before, .mbsc-ios.mbsc-form-control-wrapper:after {
  content: "";
  position: absolute;
  border-top: 1px solid transparent;
}
.mbsc-ios.mbsc-form-control-wrapper.mbsc-ltr:after, .mbsc-ios.mbsc-form-control-wrapper.mbsc-ltr:before {
  right: 0;
  left: 1em;
}
.mbsc-ios.mbsc-form-control-wrapper.mbsc-rtl:after, .mbsc-ios.mbsc-form-control-wrapper.mbsc-rtl:before {
  left: 0;
  right: 1em;
}
.mbsc-ios.mbsc-form-control-wrapper:before {
  top: 0;
}
.mbsc-ios.mbsc-form-control-wrapper:after {
  bottom: 0;
}
.mbsc-block-title + .mbsc-ios.mbsc-form-control-wrapper.mbsc-ltr:before, .mbsc-form-group-title + .mbsc-ios.mbsc-form-control-wrapper.mbsc-ltr:before, .mbsc-ios.mbsc-form-control-wrapper.mbsc-ltr:first-child:before, .mbsc-ios.mbsc-form-control-wrapper.mbsc-ltr:last-child:after {
  left: 0;
}
.mbsc-block-title + .mbsc-ios.mbsc-form-control-wrapper.mbsc-rtl:before, .mbsc-form-group-title + .mbsc-ios.mbsc-form-control-wrapper.mbsc-rtl:before, .mbsc-ios.mbsc-form-control-wrapper.mbsc-rtl:first-child:before, .mbsc-ios.mbsc-form-control-wrapper.mbsc-rtl:last-child:after {
  right: 0;
}
.mbsc-ios.mbsc-form-control-label.mbsc-disabled, .mbsc-ios.mbsc-description.mbsc-disabled {
  opacity: 0.3;
}
.mbsc-ios .mbsc-form-group-inset .mbsc-form-group-title + .mbsc-form-control-wrapper,
.mbsc-ios .mbsc-form-group-inset .mbsc-form-control-wrapper:first-child {
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}
.mbsc-ios .mbsc-form-group-inset .mbsc-form-control-wrapper:last-child {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
}
.mbsc-ios .mbsc-form-group-inset .mbsc-form-group-title,
.mbsc-ios .mbsc-form-group-inset .mbsc-form-group-title + .mbsc-form-control-wrapper:before,
.mbsc-ios .mbsc-form-group-inset .mbsc-form-control-wrapper:first-child:before,
.mbsc-ios .mbsc-form-group-inset .mbsc-form-control-wrapper:last-child:after {
  border-width: 0;
}

.mbsc-ios {
  /* Wrapper */
  /* Inside popup */
}
.mbsc-ios.mbsc-form-control-wrapper {
  background: white;
}
.mbsc-ios.mbsc-form-control-wrapper:before, .mbsc-ios.mbsc-form-control-wrapper:after {
  border-color: #cccccc;
}
.mbsc-ios.mbsc-form-control-wrapper.mbsc-error:after, .mbsc-ios.mbsc-form-control-wrapper.mbsc-error + .mbsc-form-control-wrapper:before {
  border-color: #d8332a;
}

.mbsc-ios-dark {
  /* Wrapper */
  /* Inside popup */
}
.mbsc-ios-dark.mbsc-form-control-wrapper {
  background: #1c1c1c;
}
.mbsc-ios-dark.mbsc-form-control-wrapper:before, .mbsc-ios-dark.mbsc-form-control-wrapper:after {
  border-color: #333333;
}
.mbsc-ios-dark.mbsc-form-control-wrapper.mbsc-error:after, .mbsc-ios-dark.mbsc-form-control-wrapper.mbsc-error + .mbsc-form-control-wrapper:before {
  border-color: #d8332a;
}
.mbsc-ios-dark.mbsc-popup .mbsc-ios-dark.mbsc-form-control-wrapper {
  background: #2b2b2b;
}

/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
.mbsc-form-control-label {
  display: block;
}

.mbsc-form-control-input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 0;
  opacity: 0;
  margin: 0;
  z-index: 3;
}

.mbsc-ios {
  /* Wrapper */
  /* Inner element */
  /* Form element */
  /* Icons */
  /* Label */
  /* Error message */
  /* Underline input --------------------------------------------------------------------------- */
  /* Form element*/
  /* Label */
  /* Error message */
  /* ------------------------------------------------------------------------------------------- */
  /* Box & outline input common styles --------------------------------------------------------- */
  /* Wrapper */
  /* Form element */
  /* Label */
  /* Error message */
  /* ------------------------------------------------------------------------------------------- */
  /* Select */
  /* Select icon */
  /* Textarea */
  /* Input tags */
}
.mbsc-ios.mbsc-textfield-wrapper-has-icon-right.mbsc-rtl, .mbsc-ios.mbsc-textfield-wrapper-has-icon-left.mbsc-ltr {
  z-index: 1;
}
.mbsc-ios.mbsc-textfield-wrapper-has-icon-left.mbsc-ltr:before, .mbsc-ios.mbsc-textfield-wrapper-has-icon-left.mbsc-ltr:after {
  left: 3.375em;
}
.mbsc-ios.mbsc-textfield-wrapper-has-icon-right.mbsc-rtl:after, .mbsc-ios.mbsc-textfield-wrapper-has-icon-right.mbsc-rtl:before {
  right: 3.375em;
}
.mbsc-ios.mbsc-textfield-inner {
  position: static;
  flex: 1 auto;
  z-index: -1;
}
.mbsc-ios.mbsc-textfield-inner.mbsc-disabled {
  opacity: 0.5;
}
.mbsc-ios.mbsc-textfield {
  display: flex;
  height: 2.75em;
  padding: 0 1em;
  background: transparent;
  height: 2.75em;
}
.mbsc-ios.mbsc-textfield-has-icon-left {
  padding-left: 3.375em;
}
.mbsc-ios.mbsc-textfield-has-icon-right {
  padding-right: 3.375em;
}
.mbsc-ios.mbsc-textfield-stacked, .mbsc-ios.mbsc-textfield-floating {
  height: 3.5em;
  padding-top: 1.25em;
}
.mbsc-ios.mbsc-textfield-icon {
  top: 0.625em;
  z-index: 1;
}
.mbsc-ios.mbsc-textfield-icon-left {
  left: 0.9375em;
}
.mbsc-ios.mbsc-textfield-icon-right {
  right: 0.9375em;
}
.mbsc-ios.mbsc-textfield-icon-floating, .mbsc-ios.mbsc-textfield-icon-stacked {
  top: 1em;
}
.mbsc-ios.mbsc-label-inline {
  line-height: 2.75em;
}
.mbsc-ios.mbsc-label-inline.mbsc-ltr {
  padding-left: 1em;
}
.mbsc-ios.mbsc-label-inline.mbsc-rtl {
  padding-right: 1em;
}
.mbsc-ios.mbsc-label-stacked {
  top: 0.666667em;
  font-size: 0.75em;
  line-height: 1em;
}
.mbsc-ios.mbsc-label-stacked.mbsc-ltr {
  left: 1.333334em;
}
.mbsc-ios.mbsc-label-stacked.mbsc-rtl {
  right: 1.333334em;
}
.mbsc-ios.mbsc-label-floating {
  top: 0.875em;
  line-height: 2em;
}
.mbsc-ios.mbsc-label-floating.mbsc-ltr {
  left: 1em;
}
.mbsc-ios.mbsc-label-floating.mbsc-rtl {
  right: 1em;
}
.mbsc-ios.mbsc-label-floating-active.mbsc-label {
  transform: translateY(-0.75em) scale(0.75);
}
.mbsc-ios.mbsc-label.mbsc-disabled {
  opacity: 0.5;
}
.mbsc-ios.mbsc-error-message {
  display: block;
  padding: 0 1.333334em;
  line-height: 1.666667em;
}
.mbsc-ios.mbsc-error-message-underline {
  position: static;
}
.mbsc-ios.mbsc-error-message-has-icon-left.mbsc-ltr {
  padding-left: 4.5em;
}
.mbsc-ios.mbsc-error-message-has-icon-right.mbsc-rtl {
  padding-right: 4.5em;
}
.mbsc-ios .mbsc-textfield-wrapper-underline {
  overflow: hidden;
}
.mbsc-ios.mbsc-textfield-underline-inline-has-icon-left.mbsc-ltr {
  padding-left: 1em;
}
.mbsc-ios.mbsc-textfield-underline-inline-has-icon-right.mbsc-rtl {
  padding-right: 1em;
}
.mbsc-ios.mbsc-label-underline {
  margin: 0;
}
.mbsc-ios.mbsc-label-underline-stacked-has-icon-left.mbsc-ltr {
  left: 4.5em;
}
.mbsc-ios.mbsc-label-underline-stacked-has-icon-right.mbsc-rtl {
  right: 4.5em;
}
.mbsc-ios.mbsc-label-underline-inline-has-icon-left.mbsc-ltr {
  padding-left: 3.375em;
}
.mbsc-ios.mbsc-label-underline-inline-has-icon-right.mbsc-rtl {
  padding-right: 3.375em;
}
.mbsc-ios.mbsc-label-underline-floating-has-icon-left.mbsc-ltr {
  left: 3.375em;
}
.mbsc-ios.mbsc-label-underline-floating-has-icon-right.mbsc-rtl {
  right: 3.375em;
}
.mbsc-ios.mbsc-error-message-underline.mbsc-error-message-inline.mbsc-ltr {
  padding-left: 1.333334em;
}
.mbsc-ios.mbsc-error-message-underline.mbsc-error-message-inline.mbsc-rtl {
  padding-right: 1.333334em;
}
.mbsc-ios.mbsc-textfield-wrapper-outline, .mbsc-ios.mbsc-textfield-wrapper-box {
  margin: 1.5em 1em;
}
.mbsc-ios.mbsc-textfield-wrapper-outline.mbsc-font::after, .mbsc-ios.mbsc-textfield-wrapper-outline.mbsc-font::before, .mbsc-ios.mbsc-textfield-wrapper-box.mbsc-font::after, .mbsc-ios.mbsc-textfield-wrapper-box.mbsc-font::before {
  border: none;
}
.mbsc-ios.mbsc-textfield-box, .mbsc-ios.mbsc-textfield-outline {
  border-radius: 0.5em;
  border: 1px solid transparent;
}
.mbsc-ios.mbsc-textfield-inner-box, .mbsc-ios.mbsc-textfield-inner-outline {
  position: relative;
}
.mbsc-ios.mbsc-label-box-stacked-has-icon-left.mbsc-ltr, .mbsc-ios.mbsc-label-outline-stacked-has-icon-left.mbsc-ltr {
  left: 4.5em;
}
.mbsc-ios.mbsc-label-outline-stacked-has-icon-right.mbsc-rtl, .mbsc-ios.mbsc-label-box-stacked-has-icon-right.mbsc-rtl {
  right: 4.5em;
}
.mbsc-ios.mbsc-label-box-stacked, .mbsc-ios.mbsc-label-outline-stacked, .mbsc-ios.mbsc-label-box-floating, .mbsc-ios.mbsc-label-outline-floating {
  margin: 0 1px;
}
.mbsc-ios.mbsc-label-outline-floating-has-icon-left.mbsc-ltr, .mbsc-ios.mbsc-label-box-floating-has-icon-left.mbsc-ltr {
  left: 3.375em;
}
.mbsc-ios.mbsc-label-outline-floating-has-icon-right.mbsc-rtl, .mbsc-ios.mbsc-label-box-floating-has-icon-right.mbsc-rtl {
  right: 3.375em;
}
.mbsc-ios.mbsc-error-message-outline, .mbsc-ios.mbsc-error-message-box {
  margin: 0 1px;
}
.mbsc-ios.mbsc-select.mbsc-ltr {
  padding-right: 3.375em;
}
.mbsc-ios.mbsc-select.mbsc-rtl {
  padding-left: 3.375em;
}
.mbsc-ios.mbsc-select.mbsc-textfield-has-icon-right.mbsc-ltr {
  padding-right: 4.875em;
}
.mbsc-ios.mbsc-select.mbsc-textfield-has-icon-left.mbsc-rtl {
  padding-left: 4.875em;
}
.mbsc-ios.mbsc-select-icon {
  top: 0.625em;
}
.mbsc-ios.mbsc-select-icon-stacked, .mbsc-ios.mbsc-select-icon-floating {
  top: 1em;
}
.mbsc-ios.mbsc-select-icon.mbsc-ltr {
  right: 0.9375em;
}
.mbsc-ios.mbsc-select-icon.mbsc-rtl {
  left: 0.9375em;
}
.mbsc-ios.mbsc-select-icon-right.mbsc-ltr {
  right: 3.375em;
}
.mbsc-ios.mbsc-select-icon-left.mbsc-rtl {
  left: 3.375em;
}
.mbsc-ios.mbsc-textarea {
  height: 3em;
  border: 0;
}
.mbsc-ios.mbsc-textarea.mbsc-textfield-stacked, .mbsc-ios.mbsc-textarea.mbsc-textfield-floating {
  padding-top: 0;
}
.mbsc-ios.mbsc-textarea-inner {
  padding-top: 0.625em;
  padding-bottom: 0.625em;
}
.mbsc-ios.mbsc-textarea-inner.mbsc-textfield-inner-box, .mbsc-ios.mbsc-textarea-inner.mbsc-textfield-inner-outline {
  border: 1px solid transparent;
  border-radius: 0.5em;
}
.mbsc-ios.mbsc-textarea-inner.mbsc-textfield-inner-stacked, .mbsc-ios.mbsc-textarea-inner.mbsc-textfield-inner-floating {
  padding-top: 1.375em;
}
.mbsc-ios.mbsc-textfield-tags-inner {
  padding-top: 0;
  padding-bottom: 0;
}
.mbsc-ios.mbsc-textfield.mbsc-textfield-tags {
  padding-top: 0.3125em;
  padding-bottom: 0.3125em;
  min-height: 2.75em;
}
.mbsc-ios.mbsc-textfield-tags.mbsc-textfield-stacked, .mbsc-ios.mbsc-textfield-tags.mbsc-textfield-floating {
  min-height: 2.125em;
  padding-top: 0;
  padding-bottom: 0;
}
.mbsc-ios.mbsc-textfield-tag {
  border-radius: 1em;
}

.mbsc-ios {
  /* Wrapper */
  /* Form element */
  /* Icon */
  /* Error */
  /* Select */
  /* Textarea */
  /* Inside popup */
  /* Input tags */
}
.mbsc-ios.mbsc-textfield-wrapper {
  background: none;
}
.mbsc-ios.mbsc-textfield-wrapper-underline {
  background: white;
}
.mbsc-ios.mbsc-label, .mbsc-ios.mbsc-textfield {
  color: #000000;
}
.mbsc-ios.mbsc-textfield-box, .mbsc-ios.mbsc-textfield-outline {
  background: white;
}
.mbsc-ios.mbsc-textfield-outline {
  border-color: #cccccc;
}
.mbsc-ios.mbsc-textfield-icon, .mbsc-ios.mbsc-select-icon {
  color: #8c8c8c;
}
.mbsc-ios.mbsc-textfield-outline.mbsc-error, .mbsc-ios.mbsc-textfield-box.mbsc-error {
  border-color: #d8332a;
}
.mbsc-ios.mbsc-error-message {
  color: #d8332a;
}
.mbsc-ios.mbsc-select {
  background: white;
}
.mbsc-ios.mbsc-textarea-inner.mbsc-textfield-inner-box, .mbsc-ios.mbsc-textarea-inner.mbsc-textfield-inner-outline {
  background: white;
}
.mbsc-ios.mbsc-textarea-inner.mbsc-textfield-inner-outline {
  border-color: #cccccc;
}
.mbsc-ios.mbsc-textarea-inner.mbsc-error {
  border-color: #d8332a;
}
.mbsc-ios.mbsc-textfield-tag {
  background: #dedede;
}
.mbsc-ios.mbsc-textfield-tag-clear {
  color: #8c8c8c;
}

.mbsc-ios-dark {
  /* Wrapper */
  /* Form element */
  /* Icon */
  /* Error */
  /* Select */
  /* Textarea */
  /* Inside popup */
  /* Input tags */
}
.mbsc-ios-dark.mbsc-textfield-wrapper {
  background: none;
}
.mbsc-ios-dark.mbsc-textfield-wrapper-underline {
  background: #1c1c1c;
}
.mbsc-ios-dark.mbsc-label, .mbsc-ios-dark.mbsc-textfield {
  color: #ffffff;
}
.mbsc-ios-dark.mbsc-textfield-box, .mbsc-ios-dark.mbsc-textfield-outline {
  background: #1c1c1c;
}
.mbsc-ios-dark.mbsc-textfield-outline {
  border-color: #333333;
}
.mbsc-ios-dark.mbsc-textfield-icon, .mbsc-ios-dark.mbsc-select-icon {
  color: #ffffff;
}
.mbsc-ios-dark.mbsc-textfield-outline.mbsc-error, .mbsc-ios-dark.mbsc-textfield-box.mbsc-error {
  border-color: #d8332a;
}
.mbsc-ios-dark.mbsc-error-message {
  color: #d8332a;
}
.mbsc-ios-dark.mbsc-select {
  background: #1c1c1c;
}
.mbsc-ios-dark.mbsc-textarea-inner.mbsc-textfield-inner-box, .mbsc-ios-dark.mbsc-textarea-inner.mbsc-textfield-inner-outline {
  background: #1c1c1c;
}
.mbsc-ios-dark.mbsc-textarea-inner.mbsc-textfield-inner-outline {
  border-color: #333333;
}
.mbsc-ios-dark.mbsc-textarea-inner.mbsc-error {
  border-color: #d8332a;
}
.mbsc-ios-dark.mbsc-popup .mbsc-ios-dark.mbsc-textfield-wrapper {
  background: none;
}
.mbsc-ios-dark.mbsc-popup .mbsc-ios-dark.mbsc-select, .mbsc-ios-dark.mbsc-popup .mbsc-ios-dark.mbsc-textfield-wrapper-underline, .mbsc-ios-dark.mbsc-popup .mbsc-ios-dark.mbsc-textfield-box, .mbsc-ios-dark.mbsc-popup .mbsc-ios-dark.mbsc-textfield-outline, .mbsc-ios-dark.mbsc-popup .mbsc-ios-dark.mbsc-textarea-inner.mbsc-textfield-inner-box, .mbsc-ios-dark.mbsc-popup .mbsc-ios-dark.mbsc-textarea-inner.mbsc-textfield-inner-outline {
  background: #2b2b2b;
}
.mbsc-ios-dark.mbsc-textfield-tag {
  background: #3b3b3b;
}
.mbsc-ios-dark.mbsc-textfield-tag-clear {
  color: #ffffff;
}

@keyframes autofill {
  from {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
}
/* Wrapper */
.mbsc-textfield-wrapper {
  position: relative;
  display: block;
  margin: 0;
  z-index: 0;
  user-select: none;
}

.mbsc-textfield-wrapper-inline {
  display: flex;
}

.mbsc-form-grid .mbsc-textfield-wrapper-box,
.mbsc-form-grid .mbsc-textfield-wrapper-outline {
  margin: 0.75em 1em;
}

/* Inner wrapper */
.mbsc-textfield-inner {
  box-sizing: border-box;
  position: relative;
  display: block;
}

.mbsc-textfield-inner-inline {
  flex: 1 auto;
}

/* Form element */
.mbsc-textfield {
  display: block;
  width: 100%;
  font-size: 1em;
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 0;
  outline: 0;
  font-family: inherit;
  box-sizing: border-box;
  appearance: none;
  -webkit-appearance: none;
}

.mbsc-textfield:-webkit-autofill {
  animation-name: autofill;
}

.mbsc-textfield::-webkit-inner-spin-button {
  height: 2em;
  align-self: center;
}

.mbsc-textfield::-moz-placeholder {
  opacity: 0.5;
  color: inherit;
}

.mbsc-textfield::-webkit-input-placeholder {
  opacity: 0.5;
  color: inherit;
}

/* Floating label */
.mbsc-textfield-floating:-ms-input-placeholder {
  color: transparent;
}

.mbsc-textfield-floating::-moz-placeholder {
  opacity: 0;
  transition: opacity 0.2s;
}

.mbsc-textfield-floating::-webkit-input-placeholder {
  opacity: 0;
  transition: opacity 0.2s;
}

.mbsc-textfield-floating::-webkit-datetime-edit {
  color: transparent;
}

.mbsc-textfield-floating-active:-ms-input-placeholder {
  color: inherit;
}

.mbsc-textfield-floating-active::-moz-placeholder {
  opacity: 0.5;
}

.mbsc-textfield-floating-active::-webkit-input-placeholder {
  opacity: 0.5;
}

.mbsc-textfield-floating-active::-webkit-datetime-edit {
  color: inherit;
}

.mbsc-textfield-floating .mbsc-textfield-tags-placeholder {
  opacity: 0;
  transition: opacity 0.2s;
}

.mbsc-textfield-floating-active .mbsc-textfield-tags-placeholder {
  opacity: 0.5;
}

.mbsc-textfield-fieldset {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 0 1em;
  pointer-events: none;
}

/* Icon */
.mbsc-textfield-icon {
  position: absolute;
  top: 0;
  text-align: center;
}

.mbsc-textfield-icon-left {
  left: 0;
}

.mbsc-textfield-icon-right {
  right: 0;
}

/* Label */
.mbsc-label {
  position: absolute;
  z-index: 1;
  top: 0;
  line-height: 1em;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.mbsc-label-inline {
  position: static;
  overflow: hidden;
  flex: 0 0 auto;
  width: 30%;
  max-width: 12.5em;
  box-sizing: border-box;
  pointer-events: auto;
}

.mbsc-label-floating-animate {
  transition: transform 0.2s;
}

.mbsc-label-floating.mbsc-ltr {
  transform-origin: top left;
}

.mbsc-label-floating.mbsc-rtl {
  transform-origin: top right;
}

/* Error message */
.mbsc-error-message {
  position: absolute;
  top: 100%;
  font-size: 0.75em;
  line-height: 1.5em;
}

/* File input */
.mbsc-textfield-file {
  position: absolute;
  left: 0;
  opacity: 0;
}

/* Select */
.mbsc-select {
  cursor: pointer;
}

.mbsc-select-icon {
  position: absolute;
  text-align: center;
  pointer-events: none;
}

/* Textarea */
.mbsc-textarea {
  resize: none;
  overflow: hidden;
  line-height: 1.5em;
}

/* Password toggle */
.mbsc-toggle-icon:hover {
  cursor: pointer;
}

/* Input tags */
.mbsc-textfield.mbsc-textfield-hidden.mbsc-textarea {
  padding: 0;
  width: 100%;
  height: 100%;
  border: 0;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0;
}

.mbsc-textfield.mbsc-textfield-tags.mbsc-textarea {
  display: block;
  overflow: auto;
  height: auto;
  max-height: 5.5em;
}

.mbsc-textfield-tag {
  display: inline-block;
  margin: 0.25em 0;
  line-height: normal;
}

.mbsc-textfield-tag.mbsc-ltr {
  margin-right: 0.5em;
  padding-left: 0.75em;
}

.mbsc-textfield-tag.mbsc-rtl {
  margin-left: 0.5em;
  padding-right: 0.75em;
}

.mbsc-textfield-tag-text {
  font-size: 0.875em;
  line-height: 1.857143em;
}

.mbsc-textfield-tag-clear.mbsc-icon {
  width: 1.625em;
  height: 1.625em;
  vertical-align: top;
  border: 0.25em solid transparent;
  box-sizing: border-box;
  cursor: pointer;
}

.mbsc-textfield-tags-placeholder {
  opacity: 0.5;
  line-height: 2.125em;
}

.mbsc-ios.mbsc-button {
  padding: 0 0.5em;
  margin: 0.5em 0.25em;
  line-height: 2.25em;
  border-radius: 0.25em;
  transition: opacity 0.1s ease-out, background-color 0.1s ease-out;
}
.mbsc-ios.mbsc-icon-button {
  padding: 0.5em;
  line-height: normal;
  border-radius: 4em;
}
.mbsc-ios.mbsc-button.mbsc-hover {
  opacity: 0.7;
}
.mbsc-ios.mbsc-button.mbsc-active {
  opacity: 0.5;
}
.mbsc-ios.mbsc-button:disabled, .mbsc-ios.mbsc-button.mbsc-disabled {
  opacity: 0.2;
}
.mbsc-ios.mbsc-button-outline.mbsc-active {
  opacity: 1;
}
.mbsc-ios.mbsc-ltr.mbsc-button-icon-start {
  padding-right: 0.375em;
}
.mbsc-ios.mbsc-ltr.mbsc-button-icon-end {
  padding-left: 0.375em;
}
.mbsc-ios.mbsc-rtl.mbsc-button-icon-start {
  padding-left: 0.375em;
}
.mbsc-ios.mbsc-rtl.mbsc-button-icon-end {
  padding-right: 0.375em;
}
.mbsc-ios .mbsc-button-group,
.mbsc-ios .mbsc-button-group-justified,
.mbsc-ios .mbsc-button-group-block {
  margin: 0.5em 0.75em;
}
.mbsc-ios .mbsc-button-group-block {
  margin: 0.5em 1em;
}

.mbsc-ios {
  /* Flat buttons */
  /* Outline buttons */
  /* Predefined colors */
}
.mbsc-ios.mbsc-button-standard {
  background: white;
  color: #007aff;
}
.mbsc-ios.mbsc-button-flat {
  color: #007aff;
}
.mbsc-ios.mbsc-button-outline {
  border: 1px solid #1273de;
  color: #1273de;
}
.mbsc-ios.mbsc-button-outline.mbsc-active {
  background: #1273de;
  color: #f7f7f7;
}
.mbsc-ios.mbsc-button.mbsc-focus {
  background: rgba(0, 0, 0, 0.05);
}
.mbsc-ios.mbsc-button-primary.mbsc-button-standard {
  background: #3f97f6;
  color: #fff;
}
.mbsc-ios.mbsc-button-secondary.mbsc-button-standard {
  background: #90979e;
  color: #fff;
}
.mbsc-ios.mbsc-button-success.mbsc-button-standard {
  background: #43be5f;
  color: #fff;
}
.mbsc-ios.mbsc-button-danger.mbsc-button-standard {
  background: #f5504e;
  color: #fff;
}
.mbsc-ios.mbsc-button-warning.mbsc-button-standard {
  background: #f8b042;
  color: #fff;
}
.mbsc-ios.mbsc-button-info.mbsc-button-standard {
  background: #5bb7c5;
  color: #fff;
}
.mbsc-ios.mbsc-button-dark.mbsc-button-standard {
  background: #47494a;
  color: #fff;
}
.mbsc-ios.mbsc-button-light.mbsc-button-standard {
  background: #fff;
  color: #000;
}
.mbsc-ios.mbsc-button-primary.mbsc-button-flat {
  color: #3f97f6;
}
.mbsc-ios.mbsc-button-secondary.mbsc-button-flat {
  color: #90979e;
}
.mbsc-ios.mbsc-button-success.mbsc-button-flat {
  color: #43be5f;
}
.mbsc-ios.mbsc-button-danger.mbsc-button-flat {
  color: #f5504e;
}
.mbsc-ios.mbsc-button-warning.mbsc-button-flat {
  color: #f8b042;
}
.mbsc-ios.mbsc-button-info.mbsc-button-flat {
  color: #5bb7c5;
}
.mbsc-ios.mbsc-button-dark.mbsc-button-flat {
  color: #47494a;
}
.mbsc-ios.mbsc-button-light.mbsc-button-flat {
  color: #cccccc;
}
.mbsc-ios.mbsc-button-primary.mbsc-button-outline {
  border-color: #3f97f6;
  color: #3f97f6;
}
.mbsc-ios.mbsc-button-primary.mbsc-button-outline.mbsc-active {
  background: #3f97f6;
  color: #fff;
}
.mbsc-ios.mbsc-button-secondary.mbsc-button-outline {
  border-color: #90979e;
  color: #90979e;
}
.mbsc-ios.mbsc-button-secondary.mbsc-button-outline.mbsc-active {
  background: #90979e;
  color: #fff;
}
.mbsc-ios.mbsc-button-success.mbsc-button-outline {
  border-color: #43be5f;
  color: #43be5f;
}
.mbsc-ios.mbsc-button-success.mbsc-button-outline.mbsc-active {
  background: #43be5f;
  color: #fff;
}
.mbsc-ios.mbsc-button-danger.mbsc-button-outline {
  border-color: #f5504e;
  color: #f5504e;
}
.mbsc-ios.mbsc-button-danger.mbsc-button-outline.mbsc-active {
  background: #f5504e;
  color: #fff;
}
.mbsc-ios.mbsc-button-warning.mbsc-button-outline {
  border-color: #f8b042;
  color: #f8b042;
}
.mbsc-ios.mbsc-button-warning.mbsc-button-outline.mbsc-active {
  background: #f8b042;
  color: #fff;
}
.mbsc-ios.mbsc-button-info.mbsc-button-outline {
  border-color: #5bb7c5;
  color: #5bb7c5;
}
.mbsc-ios.mbsc-button-info.mbsc-button-outline.mbsc-active {
  background: #5bb7c5;
  color: #fff;
}
.mbsc-ios.mbsc-button-dark.mbsc-button-outline {
  border-color: #47494a;
  color: #47494a;
}
.mbsc-ios.mbsc-button-dark.mbsc-button-outline.mbsc-active {
  background: #47494a;
  color: #fff;
}
.mbsc-ios.mbsc-button-light.mbsc-button-outline {
  border-color: #bfbfbf;
  color: #bfbfbf;
}
.mbsc-ios.mbsc-button-light.mbsc-button-outline.mbsc-active {
  background: #bfbfbf;
  color: #fff;
}

.mbsc-ios-dark {
  /* Flat buttons */
  /* Outline buttons */
  /* Predefined colors */
}
.mbsc-ios-dark.mbsc-button-standard {
  background: #3b3b3b;
  color: #ff9f0a;
}
.mbsc-ios-dark.mbsc-button-flat {
  color: #ff9f0a;
}
.mbsc-ios-dark.mbsc-button-outline {
  border: 1px solid #ffa519;
  color: #ffa519;
}
.mbsc-ios-dark.mbsc-button-outline.mbsc-active {
  background: #ffa519;
  color: #000000;
}
.mbsc-ios-dark.mbsc-button.mbsc-focus {
  background: rgba(255, 255, 255, 0.05);
}
.mbsc-ios-dark.mbsc-button-primary.mbsc-button-standard {
  background: #3f97f6;
  color: #fff;
}
.mbsc-ios-dark.mbsc-button-secondary.mbsc-button-standard {
  background: #90979e;
  color: #fff;
}
.mbsc-ios-dark.mbsc-button-success.mbsc-button-standard {
  background: #43be5f;
  color: #fff;
}
.mbsc-ios-dark.mbsc-button-danger.mbsc-button-standard {
  background: #f5504e;
  color: #fff;
}
.mbsc-ios-dark.mbsc-button-warning.mbsc-button-standard {
  background: #f8b042;
  color: #fff;
}
.mbsc-ios-dark.mbsc-button-info.mbsc-button-standard {
  background: #5bb7c5;
  color: #fff;
}
.mbsc-ios-dark.mbsc-button-dark.mbsc-button-standard {
  background: #47494a;
  color: #fff;
}
.mbsc-ios-dark.mbsc-button-light.mbsc-button-standard {
  background: #fff;
  color: #000;
}
.mbsc-ios-dark.mbsc-button-primary.mbsc-button-flat {
  color: #3f97f6;
}
.mbsc-ios-dark.mbsc-button-secondary.mbsc-button-flat {
  color: #90979e;
}
.mbsc-ios-dark.mbsc-button-success.mbsc-button-flat {
  color: #43be5f;
}
.mbsc-ios-dark.mbsc-button-danger.mbsc-button-flat {
  color: #f5504e;
}
.mbsc-ios-dark.mbsc-button-warning.mbsc-button-flat {
  color: #f8b042;
}
.mbsc-ios-dark.mbsc-button-info.mbsc-button-flat {
  color: #5bb7c5;
}
.mbsc-ios-dark.mbsc-button-dark.mbsc-button-flat {
  color: #47494a;
}
.mbsc-ios-dark.mbsc-button-light.mbsc-button-flat {
  color: #cccccc;
}
.mbsc-ios-dark.mbsc-button-primary.mbsc-button-outline {
  border-color: #3f97f6;
  color: #3f97f6;
}
.mbsc-ios-dark.mbsc-button-primary.mbsc-button-outline.mbsc-active {
  background: #3f97f6;
  color: #fff;
}
.mbsc-ios-dark.mbsc-button-secondary.mbsc-button-outline {
  border-color: #90979e;
  color: #90979e;
}
.mbsc-ios-dark.mbsc-button-secondary.mbsc-button-outline.mbsc-active {
  background: #90979e;
  color: #fff;
}
.mbsc-ios-dark.mbsc-button-success.mbsc-button-outline {
  border-color: #43be5f;
  color: #43be5f;
}
.mbsc-ios-dark.mbsc-button-success.mbsc-button-outline.mbsc-active {
  background: #43be5f;
  color: #fff;
}
.mbsc-ios-dark.mbsc-button-danger.mbsc-button-outline {
  border-color: #f5504e;
  color: #f5504e;
}
.mbsc-ios-dark.mbsc-button-danger.mbsc-button-outline.mbsc-active {
  background: #f5504e;
  color: #fff;
}
.mbsc-ios-dark.mbsc-button-warning.mbsc-button-outline {
  border-color: #f8b042;
  color: #f8b042;
}
.mbsc-ios-dark.mbsc-button-warning.mbsc-button-outline.mbsc-active {
  background: #f8b042;
  color: #fff;
}
.mbsc-ios-dark.mbsc-button-info.mbsc-button-outline {
  border-color: #5bb7c5;
  color: #5bb7c5;
}
.mbsc-ios-dark.mbsc-button-info.mbsc-button-outline.mbsc-active {
  background: #5bb7c5;
  color: #fff;
}
.mbsc-ios-dark.mbsc-button-dark.mbsc-button-outline {
  border-color: #47494a;
  color: #47494a;
}
.mbsc-ios-dark.mbsc-button-dark.mbsc-button-outline.mbsc-active {
  background: #47494a;
  color: #fff;
}
.mbsc-ios-dark.mbsc-button-light.mbsc-button-outline {
  border-color: #bfbfbf;
  color: #bfbfbf;
}
.mbsc-ios-dark.mbsc-button-light.mbsc-button-outline.mbsc-active {
  background: #bfbfbf;
  color: #fff;
}

.mbsc-button {
  position: relative;
  z-index: 1;
  display: inline-block;
  vertical-align: middle;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  font-size: 1em;
  box-sizing: border-box;
  touch-action: manipulation;
  user-select: none;
  white-space: nowrap;
  cursor: pointer;
}

.mbsc-button:disabled,
.mbsc-button.mbsc-disabled {
  cursor: not-allowed;
}

.mbsc-button:focus {
  outline: 0;
}

.mbsc-button::-moz-focus-inner {
  border: 0;
}

.mbsc-icon-button {
  width: 2.5em;
  height: 2.5em;
  padding: 0.5em;
  flex: 0 0 auto;
}

.mbsc-button-icon > *,
.mbsc-button-icon > * > * {
  height: 100%;
}

.mbsc-button-icon-end {
  order: 1;
}

.mbsc-button-group,
.mbsc-button-group-block,
.mbsc-button-group-justified {
  border: 1px solid transparent;
}

.mbsc-button-group-block .mbsc-button,
.mbsc-button-block {
  display: block;
  width: 100%;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mbsc-button-group-justified,
.mbsc-button-group-justified mbsc-button {
  display: flex;
  flex-wrap: wrap;
}

.mbsc-button-group-justified .mbsc-button,
.mbsc-button-group-justified mbsc-button {
  flex: 1 auto;
}

.mbsc-ios {
  /* Button styling for centered popup and desktop styled top/bottom popup */
  /* Desktop styling */
}
.mbsc-ios.mbsc-popup-wrapper-bottom-full {
  padding-top: 1em;
}
.mbsc-ios.mbsc-popup-wrapper-top-full {
  padding-bottom: 1em;
}
.mbsc-ios.mbsc-popup-top {
  border-bottom: 1px solid;
}
.mbsc-ios.mbsc-popup-bottom {
  border-top: 1px solid;
}
.mbsc-ios.mbsc-popup-body-center {
  border-radius: 0.9375em;
}
.mbsc-ios.mbsc-popup-body-bottom-full {
  border-radius: 0.75em 0.75em 0 0;
}
.mbsc-ios.mbsc-popup-body-top-full {
  border-radius: 0 0 0.75em 0.75em;
}
.mbsc-ios.mbsc-popup-body-anchored {
  border-radius: 0.9375em;
}
.mbsc-ios.mbsc-popup-arrow {
  border-radius: 0 0.375em;
}
.mbsc-ios.mbsc-popup-header {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
  line-height: 1.25em;
  padding: 0.75em 4.375em;
  font-weight: bold;
  text-align: center;
}
.mbsc-ios.mbsc-popup-header-no-buttons {
  position: relative;
  border-bottom: 1px solid;
}
.mbsc-ios.mbsc-popup-header-center {
  position: relative;
  padding: 0.75em 0.5em;
  border-bottom: 1px solid;
}
.mbsc-ios.mbsc-popup-top-full .mbsc-popup-header, .mbsc-ios.mbsc-popup-bottom-full .mbsc-popup-header {
  padding: 1.125em 4.375em;
}
.mbsc-ios.mbsc-popup-buttons {
  order: -1;
  border-bottom: 1px solid;
}
.mbsc-ios.mbsc-popup-buttons.mbsc-ltr {
  text-align: right;
}
.mbsc-ios.mbsc-popup-buttons.mbsc-rtl {
  text-align: left;
}
.mbsc-ios.mbsc-popup-button.mbsc-font {
  margin-top: 0.25em;
  margin-bottom: 0.25em;
  z-index: 2;
}
.mbsc-ios.mbsc-popup-button-primary {
  font-weight: bold;
}
.mbsc-ios.mbsc-popup-button-close.mbsc-ltr {
  float: left;
}
.mbsc-ios.mbsc-popup-button-close.mbsc-rtl {
  float: right;
}
.mbsc-ios.mbsc-popup-buttons-anchored, .mbsc-ios.mbsc-popup-buttons-top, .mbsc-ios.mbsc-popup-buttons-bottom {
  padding: 0 0.25em;
}
.mbsc-ios.mbsc-popup-buttons-top-full, .mbsc-ios.mbsc-popup-buttons-bottom-full {
  padding: 0.375em 0.25em;
}
.mbsc-ios.mbsc-popup-buttons-flex {
  order: 0;
  border: 0;
  padding: 0;
}
.mbsc-ios.mbsc-popup-buttons-flex.mbsc-popup-buttons {
  background: none;
}
.mbsc-ios.mbsc-popup-button-flex.mbsc-font {
  flex: 1 1 100%;
  display: block;
}
.mbsc-ios.mbsc-popup-button-flex.mbsc-font.mbsc-button {
  opacity: 1;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.mbsc-ios.mbsc-popup-button-flex.mbsc-font.mbsc-button-flat {
  background: none;
  border-top: 1px solid;
  border-radius: 0;
  margin: 0;
  line-height: 2.75em;
}
.mbsc-ios.mbsc-popup-button-flex.mbsc-button-flat.mbsc-ltr {
  border-right-style: solid;
  border-right-width: 1px;
}
.mbsc-ios.mbsc-popup-button-flex.mbsc-button-flat.mbsc-rtl {
  border-left-style: solid;
  border-left-width: 1px;
}
.mbsc-ios.mbsc-popup-button-flex.mbsc-button-flat:last-child {
  border-left: 0;
  border-right: 0;
}
.mbsc-ios.mbsc-popup-body-round {
  border-radius: 0.9375em;
}
.mbsc-ios.mbsc-popup-pointer .mbsc-popup-body-round {
  border-radius: 0.5em;
}
.mbsc-ios.mbsc-popup-round .mbsc-popup {
  border: 0;
}
.mbsc-ios.mbsc-popup-round .mbsc-popup-body-bottom-full {
  border-radius: 0.75em 0.75em 0 0;
}
.mbsc-ios.mbsc-popup-round .mbsc-popup-body-top-full {
  border-radius: 0 0 0.75em 0.75em;
}

.mbsc-ios.mbsc-popup-arrow {
  background: #f2f2f7;
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.2);
}
.mbsc-ios.mbsc-popup-top, .mbsc-ios.mbsc-popup-bottom {
  border-color: #cccccc;
}
.mbsc-ios.mbsc-popup-body {
  background: #f2f2f7;
  color: #000000;
}
.mbsc-ios.mbsc-popup-body-round, .mbsc-ios.mbsc-popup-body-center, .mbsc-ios.mbsc-popup-body-anchored {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mbsc-ios.mbsc-popup-header-center {
  border-color: #cccccc;
}
.mbsc-ios.mbsc-popup-buttons, .mbsc-ios.mbsc-popup-header-no-buttons {
  border-color: #cccccc;
}
.mbsc-ios.mbsc-popup-buttons-bottom, .mbsc-ios.mbsc-popup-buttons-top {
  background: #f7f7f7;
}
.mbsc-ios.mbsc-popup-button-flex.mbsc-button-flat.mbsc-font {
  border-color: #cccccc;
}
.mbsc-ios.mbsc-popup-button-flex.mbsc-font.mbsc-hover, .mbsc-ios.mbsc-popup-button-flex.mbsc-font.mbsc-focus {
  background: #eaeaea;
}
.mbsc-ios.mbsc-popup-button-flex.mbsc-font.mbsc-active {
  background: #cccccc;
}
.mbsc-ios.mbsc-popup-button-flex.mbsc-button.mbsc-disabled {
  color: rgba(0, 122, 255, 0.2);
}

.mbsc-ios-dark.mbsc-popup-arrow {
  background: #1c1c1c;
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.2);
}
.mbsc-ios-dark.mbsc-popup-top, .mbsc-ios-dark.mbsc-popup-bottom {
  border-color: #333333;
}
.mbsc-ios-dark.mbsc-popup-body {
  background: #1c1c1c;
  color: #ffffff;
}
.mbsc-ios-dark.mbsc-popup-body-round, .mbsc-ios-dark.mbsc-popup-body-center, .mbsc-ios-dark.mbsc-popup-body-anchored {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mbsc-ios-dark.mbsc-popup-header-center {
  border-color: #333333;
}
.mbsc-ios-dark.mbsc-popup-buttons, .mbsc-ios-dark.mbsc-popup-header-no-buttons {
  border-color: #333333;
}
.mbsc-ios-dark.mbsc-popup-buttons-bottom, .mbsc-ios-dark.mbsc-popup-buttons-top {
  background: #292929;
}
.mbsc-ios-dark.mbsc-popup-buttons-anchored {
  background: #292929;
}
.mbsc-ios-dark.mbsc-popup-button-flex.mbsc-button-flat.mbsc-font {
  border-color: #333333;
}
.mbsc-ios-dark.mbsc-popup-button-flex.mbsc-font.mbsc-hover, .mbsc-ios-dark.mbsc-popup-button-flex.mbsc-font.mbsc-focus {
  background: #242424;
}
.mbsc-ios-dark.mbsc-popup-button-flex.mbsc-font.mbsc-active {
  background: #333333;
}
.mbsc-ios-dark.mbsc-popup-button-flex.mbsc-button.mbsc-disabled {
  color: rgba(255, 159, 10, 0.2);
}

@keyframes mbsc-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes mbsc-fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes mbsc-pop-in {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes mbsc-pop-out {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.8);
  }
}
@keyframes mbsc-slide-up-in {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes mbsc-slide-up-out {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}
@keyframes mbsc-slide-down-in {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes mbsc-slide-down-out {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}
.mbsc-popup-open-ios {
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
}

.mbsc-popup-ctx {
  position: relative;
  --mbsc-safe-top: 0;
  --mbsc-safe-right: 0;
  --mbsc-safe-bottom: 0;
  --mbsc-safe-left: 0;
}

.mbsc-popup-limits {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.mbsc-popup-limits-anchored,
.mbsc-popup-limits-center {
  margin: 1em;
}

.mbsc-popup,
.mbsc-popup-wrapper {
  box-sizing: border-box;
}

.mbsc-popup-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  justify-content: center;
  border: 0 solid transparent;
  z-index: 99998;
  user-select: none;
}

.mbsc-popup-wrapper-ctx {
  position: absolute;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.mbsc-popup-wrapper-center {
  padding: 1em;
  align-items: center;
  border-width: var(--mbsc-safe-top) var(--mbsc-safe-right) var(--mbsc-safe-bottom) var(--mbsc-safe-left);
}

.mbsc-popup-wrapper-top {
  align-items: flex-start;
  border-bottom: var(--mbsc-safe-bottom) solid transparent;
}

.mbsc-popup-wrapper-bottom {
  align-items: flex-end;
  border-top-width: var(--mbsc-safe-top);
}

.mbsc-popup-wrapper-anchored {
  border-width: 0 var(--mbsc-safe-right) var(--mbsc-safe-bottom) var(--mbsc-safe-left);
}

.mbsc-popup-hidden {
  opacity: 0;
}

.mbsc-popup-overlay {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: -10em;
  pointer-events: auto;
  background: rgba(0, 0, 0, 0.5);
  transform: translateZ(0);
}

.mbsc-popup-overlay-in {
  opacity: 1;
  animation: mbsc-fade-in 150ms cubic-bezier(0, 0, 0.2, 1);
}

.mbsc-popup-overlay-out {
  opacity: 0;
  animation: mbsc-fade-out 75ms cubic-bezier(0.4, 0, 1, 1);
}

.mbsc-popup {
  max-height: 100%;
  min-width: 12em;
  position: relative;
  z-index: 2;
  pointer-events: auto;
  text-shadow: none;
  user-select: none;
}

.mbsc-popup-center {
  min-width: 16em;
}

.mbsc-popup-bottom {
  width: 100%;
  bottom: 0;
}

.mbsc-popup-top {
  width: 100%;
  top: 0;
}

.mbsc-popup-anchored {
  position: absolute;
}

.mbsc-popup-anchored-left {
  margin-left: -1em;
}

.mbsc-popup-anchored-right {
  margin-left: 1em;
}

.mbsc-popup-arrow-wrapper {
  position: absolute;
  z-index: 1;
  overflow: hidden;
  pointer-events: none;
}

.mbsc-popup-arrow-wrapper-top,
.mbsc-popup-arrow-wrapper-bottom {
  left: 0;
  right: 0;
  height: 2em;
}

.mbsc-popup-arrow-wrapper-left,
.mbsc-popup-arrow-wrapper-right {
  top: 0;
  bottom: 0;
  width: 2em;
}

.mbsc-popup-arrow-wrapper-top {
  top: 100%;
}

.mbsc-popup-arrow-wrapper-bottom {
  bottom: 100%;
}

.mbsc-popup-arrow-wrapper-left {
  left: 100%;
}

.mbsc-popup-arrow-wrapper-right {
  right: 100%;
}

.mbsc-popup-arrow {
  position: absolute;
  box-sizing: border-box;
  width: 1.5em;
  height: 1.5em;
  pointer-events: auto;
}

.mbsc-popup-arrow-top {
  bottom: 1.25em;
}

.mbsc-popup-arrow-bottom {
  top: 1.25em;
}

.mbsc-popup-arrow-top,
.mbsc-popup-arrow-bottom {
  left: 50%;
  margin-left: -0.75em;
  transform: rotate(-45deg);
}

.mbsc-popup-arrow-left {
  right: 1.25em;
}

.mbsc-popup-arrow-right {
  left: 1.25em;
}

.mbsc-popup-arrow-left,
.mbsc-popup-arrow-right {
  top: 50%;
  margin-top: -0.75em;
  transform: rotate(45deg);
}

.mbsc-popup-focus {
  outline: 0;
}

.mbsc-popup-body {
  overflow: hidden;
  transform: translateZ(0);
}

.mbsc-popup-body-top {
  padding-top: var(--mbsc-safe-top);
}

.mbsc-popup-body-bottom {
  padding-bottom: var(--mbsc-safe-bottom);
}

.mbsc-popup-body-top,
.mbsc-popup-body-bottom {
  padding-left: var(--mbsc-safe-left);
  padding-right: var(--mbsc-safe-right);
}

.mbsc-popup-body-round {
  padding: 0;
}

.mbsc-popup-header {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mbsc-popup-content {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.mbsc-popup-padding {
  padding: 1em;
}

/* Animations */
.mbsc-popup-pop-in {
  opacity: 1;
  animation: mbsc-pop-in 150ms cubic-bezier(0, 0, 0.2, 1);
}

.mbsc-popup-pop-out {
  opacity: 0;
  animation: mbsc-pop-out 75ms cubic-bezier(0.4, 0, 1, 1);
}

.mbsc-popup-slide-up-in {
  animation: mbsc-slide-up-in 250ms cubic-bezier(0, 0, 0.2, 1);
}

.mbsc-popup-slide-up-out {
  animation: mbsc-slide-up-out 200ms cubic-bezier(0.4, 0, 1, 1);
}

.mbsc-popup-slide-down-in {
  animation: mbsc-slide-down-in 250ms cubic-bezier(0, 0, 0.2, 1);
}

.mbsc-popup-slide-down-out {
  animation: mbsc-slide-down-out 200ms cubic-bezier(0.4, 0, 1, 1);
}

/* Desktop styling */
.mbsc-popup-pointer.mbsc-picker .mbsc-popup-overlay,
.mbsc-popup-pointer .mbsc-popup-overlay-anchored {
  background: none;
}

.mbsc-popup-round .mbsc-popup-slide-up-in {
  animation-name: mbsc-slide-up-in, mbsc-fade-in;
}
.mbsc-popup-round .mbsc-popup-slide-up-out {
  animation-name: mbsc-slide-up-out, mbsc-fade-out;
}
.mbsc-popup-round .mbsc-popup-slide-down-in {
  animation-name: mbsc-slide-down-in, mbsc-fade-in;
}
.mbsc-popup-round .mbsc-popup-slide-down-out {
  animation-name: mbsc-slide-down-out, mbsc-fade-out;
}
.mbsc-popup-round .mbsc-popup-top,
.mbsc-popup-round .mbsc-popup-bottom {
  width: auto;
  margin-top: 3em;
  margin-bottom: 3em;
}

.mbsc-ios .mbsc-toast-message {
  line-height: 1.857143em;
  border-radius: 1.785715em;
}
.mbsc-ios .mbsc-alert-title {
  margin: 0.277778em 0;
  font-size: 1.125em;
  text-align: center;
}
.mbsc-ios .mbsc-alert-message {
  margin: 0.357143em 0;
  font-size: 0.875em;
  text-align: center;
}
.mbsc-ios.mbsc-prompt-input.mbsc-font {
  margin-top: 1em;
  border-radius: 0.5em;
  border-width: 1px;
  border-style: solid;
}
.mbsc-ios.mbsc-prompt-input::after, .mbsc-ios.mbsc-prompt-input::before {
  display: none;
}
.mbsc-ios.mbsc-prompt-input .mbsc-textfield {
  height: 1.75em;
  line-height: 1.75em;
  padding: 0 0.25em;
}
.mbsc-ios.mbsc-prompt-input .mbsc-label {
  height: 1.75em;
  line-height: 1.75em;
  padding-left: 0.25em;
}
.mbsc-ios.mbsc-primary .mbsc-toast-background {
  background: #3f97f6;
  color: #fff;
}
.mbsc-ios.mbsc-secondary .mbsc-toast-background {
  background: #90979e;
  color: #fff;
}
.mbsc-ios.mbsc-success .mbsc-toast-background {
  background: #43be5f;
  color: #fff;
}
.mbsc-ios.mbsc-danger .mbsc-toast-background {
  background: #f5504e;
  color: #fff;
}
.mbsc-ios.mbsc-warning .mbsc-toast-background {
  background: #f8b042;
  color: #fff;
}
.mbsc-ios.mbsc-info .mbsc-toast-background {
  background: #5bb7c5;
  color: #fff;
}

.mbsc-ios .mbsc-toast-background {
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
}
.mbsc-ios.mbsc-prompt-input {
  border-color: #cccccc;
}
.mbsc-ios.mbsc-color-none .mbsc-snackbar-button.mbsc-button {
  color: #007aff;
}

.mbsc-ios-dark .mbsc-toast-background {
  background: rgba(255, 255, 255, 0.8);
  color: #000;
}
.mbsc-ios-dark.mbsc-prompt-input {
  border-color: #333333;
}
.mbsc-ios-dark.mbsc-color-none .mbsc-snackbar-button.mbsc-button {
  color: #ff9f0a;
}

/* Toast */
.mbsc-toast.mbsc-font .mbsc-popup-body,
.mbsc-toast.mbsc-font .mbsc-popup,
.mbsc-snackbar.mbsc-font .mbsc-popup-body,
.mbsc-snackbar.mbsc-font .mbsc-popup {
  background: none;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  margin: 0;
  pointer-events: none;
}

.mbsc-toast.mbsc-font .mbsc-popup-content {
  text-align: center;
  padding-bottom: 4em;
}

.mbsc-toast-message {
  display: inline-block;
  min-width: 10em;
  max-width: 50em;
  padding: 0.857143em 2em;
  font-size: 0.875em;
  line-height: 1.428572;
}

/* Snackbar */
.mbsc-snackbar-cont {
  align-items: center;
  min-width: 18em;
  max-width: 36em;
  margin: 0 auto;
  padding: 0.5em;
  pointer-events: auto;
}

.mbsc-snackbar-message {
  padding: 0.5em 1em;
  font-size: 0.875em;
  line-height: 1.571429em;
}

.mbsc-snackbar-button.mbsc-button.mbsc-font {
  margin: 0;
  color: #fff;
}

/* Alert, Confirm, Prompt*/
.mbsc-alert-content {
  max-width: 20em;
}

.mbsc-alert-title {
  margin: 0 0 1em 0;
  padding: 0;
  font-size: 1.428572em;
  font-weight: bold;
}

.mbsc-alert-message {
  margin: 1em 0;
  padding: 0;
  font-size: 1em;
}

.mbsc-ios {
  /* checkmark */
  /* Desktop styling */
}
.mbsc-ios.mbsc-scroller-wheel-multi.mbsc-scroller-wheel-cont-3d {
  visibility: hidden;
}
.mbsc-ios.mbsc-scroller-wheel-group-cont {
  padding: 0 0.625em;
}
.mbsc-ios.mbsc-scroller-wheel-group {
  padding: 0.625em;
}
.mbsc-ios.mbsc-wheel-group-checkmark {
  padding: 0.625em 0;
}
.mbsc-ios.mbsc-scroller-wheel-3d {
  overflow: visible;
}
.mbsc-ios.mbsc-scroller-wheel-line {
  display: block;
  z-index: 1;
  border-radius: 0.5em;
  margin: 0 0.625em;
}
.mbsc-ios.mbsc-scroller-wheel-overlay {
  display: block;
}
.mbsc-ios.mbsc-scroller-wheel-item {
  padding: 0 0.5em;
  font-size: 1.25em;
  text-align: center;
  box-sizing: border-box;
  border-radius: 0.5em;
}
.mbsc-ios.mbsc-scroller-wheel-item-3d {
  font-size: 1.125em;
}
.mbsc-ios.mbsc-wheel-item-checkmark {
  padding-left: 1.75em;
  padding-right: 1.75em;
}
.mbsc-ios.mbsc-scroller-wheel-header {
  font-size: 0.875em;
}
.mbsc-ios.mbsc-wheel-checkmark {
  display: none;
  position: absolute;
  width: 1.75em;
  height: 1.75em;
  top: 50%;
  margin-top: -0.875em;
}
.mbsc-ios.mbsc-wheel-checkmark::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  display: block;
  opacity: 0;
  transform: rotate(-45deg);
  top: 32%;
  left: 26%;
  width: 0.75em;
  height: 0.375em;
  border: 0.125em solid currentColor;
  border-top: 0;
  border-right: 0;
  transition: opacity 0.2s ease-in-out;
}
.mbsc-ios.mbsc-wheel-checkmark.mbsc-selected::after {
  opacity: 1;
}
.mbsc-ios.mbsc-wheel-checkmark.mbsc-ltr {
  left: 0;
}
.mbsc-ios.mbsc-wheel-checkmark.mbsc-rtl {
  right: 0;
}
.mbsc-ios.mbsc-wheel-item-multi .mbsc-ios.mbsc-wheel-checkmark, .mbsc-ios.mbsc-scroller-pointer .mbsc-ios.mbsc-wheel-item-checkmark .mbsc-wheel-checkmark {
  display: block;
}
.mbsc-ios.mbsc-scroller-pointer .mbsc-scroller-wheel-group-cont,
.mbsc-ios.mbsc-scroller-pointer .mbsc-scroller-wheel-group {
  padding: 0;
}
.mbsc-ios.mbsc-scroller-pointer .mbsc-scroller-wheel-wrapper {
  padding: 0.1875em 0;
}
.mbsc-ios.mbsc-scroller-pointer .mbsc-scroller-wheel-overlay {
  display: none;
}
.mbsc-ios.mbsc-scroller-pointer .mbsc-scroller-wheel-item {
  font-size: 1em;
  padding: 0 0.75em;
  margin: 0 0.1875em;
}
.mbsc-ios.mbsc-scroller-pointer .mbsc-scroller-wheel-line {
  margin: 0 0.1875em;
}
.mbsc-ios.mbsc-scroller-pointer .mbsc-wheel-item-checkmark.mbsc-ltr {
  text-align: left;
  padding-left: 1.75em;
}
.mbsc-ios.mbsc-scroller-pointer .mbsc-wheel-item-checkmark.mbsc-rtl {
  text-align: right;
  padding-right: 1.75em;
}
.mbsc-ios.mbsc-scroller-pointer .mbsc-scroller-wheel-header {
  font-size: 0.75em;
  font-weight: 700;
}

.mbsc-ios {
  /* 3D */
  /* Desktop style */
}
.mbsc-ios.mbsc-scroller {
  background: white;
}
.mbsc-ios.mbsc-scroller-top, .mbsc-ios.mbsc-scroller-bottom {
  background: #d1d5db;
}
.mbsc-ios.mbsc-scroller-wheel-overlay {
  background: linear-gradient(white, rgba(255, 255, 255, 0) 52%, rgba(255, 255, 255, 0) 48%, white);
}
.mbsc-ios.mbsc-scroller-wheel-overlay-top, .mbsc-ios.mbsc-scroller-wheel-overlay-bottom {
  background: linear-gradient(#d1d5db, rgba(209, 213, 219, 0) 52%, rgba(209, 213, 219, 0) 48%, #d1d5db);
}
.mbsc-ios.mbsc-scroller-wheel-line {
  background: rgba(0, 0, 0, 0.1);
}
.mbsc-ios.mbsc-scroller-wheel-item {
  color: hsl(0, 0%, 62%);
}
.mbsc-ios.mbsc-scroller-wheel-item.mbsc-active, .mbsc-ios.mbsc-scroller-wheel-item.mbsc-hover, .mbsc-ios.mbsc-scroller-wheel-item.mbsc-focus, .mbsc-ios.mbsc-scroller-wheel-header.mbsc-focus {
  background: rgba(0, 122, 255, 0.15);
}
.mbsc-ios.mbsc-scroller-wheel-item-2d, .mbsc-ios.mbsc-scroller-wheel-item.mbsc-selected {
  color: #000000;
}
.mbsc-ios.mbsc-scroller-wheel-cont-3d {
  background: white;
}
.mbsc-ios.mbsc-scroller-wheel-item-3d {
  color: #a3a3a3;
}
.mbsc-ios.mbsc-scroller-wheel-header, .mbsc-ios.mbsc-scroller-wheel-item-3d.mbsc-wheel-item-multi {
  color: #000000;
}
.mbsc-ios.mbsc-scroller-wheel-item-3d.mbsc-wheel-item-multi.mbsc-selected-3d {
  color: #007aff;
}
.mbsc-ios.mbsc-scroller-wheel-cont-top.mbsc-scroller-wheel-cont-3d, .mbsc-ios.mbsc-scroller-wheel-cont-bottom.mbsc-scroller-wheel-cont-3d {
  background: #d1d5db;
}
.mbsc-ios.mbsc-scroller-wheel-cont-inline.mbsc-scroller-wheel-cont-3d {
  background: white;
}
.mbsc-ios.mbsc-scroller-pointer.mbsc-scroller {
  background: white;
}
.mbsc-ios.mbsc-scroller-pointer.mbsc-scroller-inline {
  background: white;
}
.mbsc-ios.mbsc-scroller-pointer .mbsc-scroller-wheel-item {
  color: #000000;
}
.mbsc-ios.mbsc-scroller-pointer .mbsc-scroller-wheel-item.mbsc-selected {
  color: #007aff;
}

.mbsc-ios-dark {
  /* 3D */
  /* Desktop style */
}
.mbsc-ios-dark.mbsc-scroller {
  background: #1c1c1c;
}
.mbsc-ios-dark.mbsc-scroller-top, .mbsc-ios-dark.mbsc-scroller-bottom {
  background: #1c1c1c;
}
.mbsc-ios-dark.mbsc-scroller-wheel-overlay {
  background: linear-gradient(#1c1c1c, rgba(28, 28, 28, 0) 52%, rgba(28, 28, 28, 0) 48%, #1c1c1c);
}
.mbsc-ios-dark.mbsc-scroller-wheel-overlay-top, .mbsc-ios-dark.mbsc-scroller-wheel-overlay-bottom {
  background: linear-gradient(#1c1c1c, rgba(28, 28, 28, 0) 52%, rgba(28, 28, 28, 0) 48%, #1c1c1c);
}
.mbsc-ios-dark.mbsc-scroller-wheel-line {
  background: rgba(255, 255, 255, 0.1);
}
.mbsc-ios-dark.mbsc-scroller-wheel-item {
  color: hsl(0, 0%, 62%);
}
.mbsc-ios-dark.mbsc-scroller-wheel-item.mbsc-active, .mbsc-ios-dark.mbsc-scroller-wheel-item.mbsc-hover, .mbsc-ios-dark.mbsc-scroller-wheel-item.mbsc-focus, .mbsc-ios-dark.mbsc-scroller-wheel-header.mbsc-focus {
  background: rgba(255, 159, 10, 0.15);
}
.mbsc-ios-dark.mbsc-scroller-wheel-item-2d, .mbsc-ios-dark.mbsc-scroller-wheel-item.mbsc-selected {
  color: #ffffff;
}
.mbsc-ios-dark.mbsc-scroller-wheel-cont-3d {
  background: #1c1c1c;
}
.mbsc-ios-dark.mbsc-scroller-wheel-item-3d {
  color: #666666;
}
.mbsc-ios-dark.mbsc-scroller-wheel-header, .mbsc-ios-dark.mbsc-scroller-wheel-item-3d.mbsc-wheel-item-multi {
  color: #ffffff;
}
.mbsc-ios-dark.mbsc-scroller-wheel-item-3d.mbsc-wheel-item-multi.mbsc-selected-3d {
  color: #ff9f0a;
}
.mbsc-ios-dark.mbsc-scroller-wheel-cont-top.mbsc-scroller-wheel-cont-3d, .mbsc-ios-dark.mbsc-scroller-wheel-cont-bottom.mbsc-scroller-wheel-cont-3d {
  background: #1c1c1c;
}
.mbsc-ios-dark.mbsc-scroller-wheel-cont-inline.mbsc-scroller-wheel-cont-3d {
  background: #1c1c1c;
}
.mbsc-ios-dark.mbsc-scroller-pointer.mbsc-scroller {
  background: #1c1c1c;
}
.mbsc-ios-dark.mbsc-scroller-pointer.mbsc-scroller-inline {
  background: #1c1c1c;
}
.mbsc-ios-dark.mbsc-scroller-pointer .mbsc-scroller-wheel-item {
  color: #ffffff;
}
.mbsc-ios-dark.mbsc-scroller-pointer .mbsc-scroller-wheel-item.mbsc-selected {
  color: #ff9f0a;
}

.mbsc-scroller {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  text-align: center;
  user-select: none;
}

.mbsc-scroller-wheel-group-cont {
  position: relative;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  overflow: hidden;
}

.mbsc-scroller-wheel-group-cont:first-child:last-child {
  display: block;
}

.mbsc-scroller-wheel-group {
  margin: 0 auto;
  position: relative;
  justify-content: center;
}

.mbsc-scroller-wheel-group-3d {
  perspective: 100em;
}

.mbsc-scroller-wheel-wrapper {
  display: block;
  max-width: 100%;
  position: relative;
  touch-action: none;
  /* Temp */
  min-width: 80px;
}

.mbsc-scroller-pointer .mbsc-scroller-wheel-wrapper-0:last-child {
  flex: 1 1 auto;
}

.mbsc-scroller-wheel-line {
  display: none;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  pointer-events: none;
  transform: translateY(-50%);
}

.mbsc-scroller-wheel-overlay {
  display: none;
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
}

.mbsc-scroller-wheel {
  display: block;
  overflow: hidden;
  /* Fixes Firefox rendering issues */
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  margin: -1px 0;
}

.mbsc-scroller-wheel-cont {
  position: relative;
  z-index: 1;
  top: 50%;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  transform: translateY(-50%);
}

.mbsc-scroller-wheel-cont-3d {
  overflow: hidden;
  border: 0;
}

.mbsc-scroller-wheel-cont-3d > div {
  position: relative;
  top: -1px;
}

.mbsc-scroller-wheel-wrapper-3d,
.mbsc-scroller-wheel-3d {
  transform-style: preserve-3d;
}

.mbsc-scroller-items-3d {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform-style: preserve-3d;
  transform: translateY(-50%);
}

.mbsc-scroller .mbsc-scroller-wheel,
.mbsc-scroller .mbsc-scroller-wheel-cont {
  box-sizing: content-box;
}

.mbsc-scroller-wheel-item {
  display: block;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mbsc-scroller-wheel-item:focus,
.mbsc-scroller-wheel-header:focus {
  outline: 0;
}

.mbsc-scroller-wheel-item-3d {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  cursor: pointer;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.mbsc-scroller-wheel-item.mbsc-disabled {
  opacity: 0.3;
}

.mbsc-scroller-wheel-header {
  display: block;
  padding: 0 0.5em;
}
.mbsc-scroller-wheel-header.mbsc-ltr {
  text-align: left;
}
.mbsc-scroller-wheel-header.mbsc-rtl {
  text-align: right;
}

.mbsc-scroller-bar-cont {
  position: absolute;
  z-index: 4;
  top: 0;
  bottom: 0;
  width: 10px;
  opacity: 0;
  background: rgba(0, 0, 0, 0.05);
  transform: translateZ(0);
  transition: opacity 0.2s;
}
.mbsc-scroller-bar-cont.mbsc-ltr {
  right: 0;
}
.mbsc-scroller-bar-cont.mbsc-rtl {
  left: 0;
}

.mbsc-scroller-bar-hidden {
  display: none;
}

.mbsc-scroller-wheel:hover .mbsc-scroller-bar-cont,
.mbsc-scroller-bar-started {
  opacity: 1;
}

.mbsc-scroller-bar {
  position: absolute;
  right: 0;
  top: 0;
  width: 10px;
}
.mbsc-scroller-bar::after {
  content: "";
  position: absolute;
  top: 2px;
  right: 2px;
  bottom: 2px;
  left: 2px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 3px;
}

.mbsc-ios.mbsc-select-scroller-inline {
  border-top: 1px solid;
  border-bottom: 1px solid;
  margin-top: -1px;
  margin-bottom: -1px;
}
.mbsc-ios.mbsc-select-group-wheel-multi .mbsc-scroller-wheel-cont-3d {
  visibility: hidden;
}
.mbsc-ios.mbsc-select-scroller.mbsc-scroller-pointer .mbsc-select-group-wheel.mbsc-ltr {
  border-right-width: 1px;
  border-right-style: solid;
}
.mbsc-ios.mbsc-select-scroller.mbsc-scroller-pointer .mbsc-select-group-wheel.mbsc-rtl {
  border-left-width: 1px;
  border-left-style: solid;
}
.mbsc-ios.mbsc-select-filter-cont {
  padding: 0.5em;
}
.mbsc-ios.mbsc-select-filter {
  margin: 0;
}
.mbsc-ios.mbsc-select-filter-input {
  height: 2em;
}
.mbsc-ios.mbsc-select-empty-text {
  font-size: 1.25em;
}

.mbsc-ios.mbsc-select-scroller-inline {
  border-color: #cccccc;
}
.mbsc-ios.mbsc-select-filter-cont {
  background-color: #f2f2f7;
}
.mbsc-ios.mbsc-select-filter-clear {
  color: #cccccc;
}
.mbsc-ios.mbsc-select-filter-clear:hover {
  color: #b2b2b2;
}
.mbsc-ios.mbsc-select-empty-text {
  color: hsl(0, 0%, 62%);
}
.mbsc-ios.mbsc-select-group-wheel {
  border-color: #cccccc;
}
.mbsc-ios.mbsc-select-group-wheel-multi .mbsc-scroller-wheel-item-3d {
  color: #000000;
}
.mbsc-ios.mbsc-select-group-wheel-multi .mbsc-selected-3d {
  color: #007aff;
}

.mbsc-ios-dark.mbsc-select-scroller-inline {
  border-color: #333333;
}
.mbsc-ios-dark.mbsc-select-filter-cont {
  background-color: #1c1c1c;
}
.mbsc-ios-dark.mbsc-select-filter-clear {
  color: #404040;
}
.mbsc-ios-dark.mbsc-select-filter-clear:hover {
  color: #4d4d4d;
}
.mbsc-ios-dark.mbsc-select-empty-text {
  color: hsl(0, 0%, 62%);
}
.mbsc-ios-dark.mbsc-select-group-wheel {
  border-color: #333333;
}
.mbsc-ios-dark.mbsc-select-group-wheel-multi .mbsc-scroller-wheel-item-3d {
  color: #ffffff;
}
.mbsc-ios-dark.mbsc-select-group-wheel-multi .mbsc-selected-3d {
  color: #ff9f0a;
}

.mbsc-select-scroller .mbsc-scroller-wheel-wrapper {
  flex: 1 1 auto;
}

.mbsc-select-filter-cont {
  position: relative;
}

.mbsc-select-filter-clear {
  position: absolute;
  width: 1em;
  height: 1em;
  top: 50%;
  margin-top: -1em;
  padding: 0.5em;
  cursor: pointer;
  box-sizing: content-box;
}
.mbsc-select-filter-clear.mbsc-rtl {
  left: 0.5em;
  right: auto;
}
.mbsc-select-filter-clear.mbsc-ltr {
  right: 0.5em;
  left: auto;
}

.mbsc-select-empty .mbsc-scroller-wheel-group-cont {
  visibility: hidden;
}

.mbsc-select-empty-text {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
}

.mbsc-select-scroller.mbsc-scroller-pointer .mbsc-scroller-wheel-item.mbsc-ltr {
  text-align: left;
}
.mbsc-select-scroller.mbsc-scroller-pointer .mbsc-scroller-wheel-item.mbsc-rtl {
  text-align: right;
}

.mbsc-ios {
  /* Color presets */
}
.mbsc-ios.mbsc-checkbox-right {
  padding: 0.875em 3.75em 0.875em 1em;
}
.mbsc-ios.mbsc-checkbox-left {
  padding: 0.875em 1em 0.875em 3.75em;
}
.mbsc-ios.mbsc-checkbox-box {
  width: 1.75em;
  height: 1.75em;
  margin-top: -0.875em;
  border: 0.125em solid currentColor;
  border-radius: 2em;
}
.mbsc-ios.mbsc-checkbox-box-right {
  right: 1em;
}
.mbsc-ios.mbsc-checkbox-box-left {
  left: 1em;
}
.mbsc-ios.mbsc-checkbox-box:after {
  top: 32%;
  left: 26%;
  width: 0.75em;
  height: 0.375em;
  border: 0.125em solid currentColor;
  border-top: 0;
  border-right: 0;
  transition: opacity 0.2s ease-in-out;
}
.mbsc-ios.mbsc-checkbox-box:before {
  content: "";
  position: absolute;
  top: -0.5em;
  left: -0.5em;
  z-index: -1;
  width: 2.5em;
  height: 2.5em;
  opacity: 0;
  border-radius: 2.5em;
  background: #ccc;
  transition: opacity 0.2s ease-in-out;
}
.mbsc-ios.mbsc-checkbox-box.mbsc-focus:before {
  opacity: 0.12;
}
.mbsc-ios.mbsc-checkbox-box.mbsc-disabled {
  opacity: 0.3;
}
.mbsc-ios.mbsc-checkbox-box.mbsc-checkbox-box-primary {
  color: #3f97f6;
}
.mbsc-ios.mbsc-checkbox-box.mbsc-checkbox-box-secondary {
  color: #90979e;
}
.mbsc-ios.mbsc-checkbox-box.mbsc-checkbox-box-success {
  color: #43be5f;
}
.mbsc-ios.mbsc-checkbox-box.mbsc-checkbox-box-danger {
  color: #f5504e;
}
.mbsc-ios.mbsc-checkbox-box.mbsc-checkbox-box-warning {
  color: #f8b042;
}
.mbsc-ios.mbsc-checkbox-box.mbsc-checkbox-box-info {
  color: #5bb7c5;
}

.mbsc-ios.mbsc-checkbox-box {
  color: #1273de;
}

.mbsc-ios-dark.mbsc-checkbox-box {
  color: #ffa519;
}

.mbsc-checkbox {
  line-height: 1.25em;
  position: relative;
  display: block;
  margin: 0;
  z-index: 0;
  user-select: none;
}

.mbsc-checkbox-box {
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  display: block;
  width: 1.375em;
  height: 1.375em;
}

.mbsc-checkbox-box:after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  display: block;
  opacity: 0;
  transform: rotate(-45deg);
}

.mbsc-checkbox-box.mbsc-checked:after {
  opacity: 1;
}

.mbsc-description {
  display: block;
  font-size: 0.75em;
  opacity: 0.6;
}

.mbsc-ios .mbsc-block-title,
.mbsc-ios .mbsc-form-group-title {
  padding: 0.5em 1.333334em;
  font-size: 0.75em;
  line-height: 1.5em;
  text-transform: uppercase;
}

.mbsc-ios.mbsc-page {
  background: #f2f2f7;
  color: #000000;
}
.mbsc-ios .mbsc-block-title,
.mbsc-ios .mbsc-form-group-title {
  color: #6e6e6e;
}
.mbsc-ios a {
  color: #007aff;
}

.mbsc-ios-dark.mbsc-page {
  background: #000000;
  color: #ffffff;
}
.mbsc-ios-dark .mbsc-block-title,
.mbsc-ios-dark .mbsc-form-group-title {
  color: #919191;
}
.mbsc-ios-dark a {
  color: #ff9f0a;
}

.mbsc-page {
  display: block;
  min-height: 100%;
  padding: var(--mbsc-safe-top) var(--mbsc-safe-right) var(--mbsc-safe-bottom) var(--mbsc-safe-left);
}

.mbsc-page:before,
.mbsc-page:after {
  content: "";
  display: table;
}

/* Block */
.mbsc-block,
.mbsc-form-group {
  margin: 1.5em 0;
}

.mbsc-form-group-inset {
  margin: 2em 1.5em;
}

.mbsc-form-grid .mbsc-form-group-title {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

/* Typography */
.mbsc-page h1,
.mbsc-page h2,
.mbsc-page h3,
.mbsc-page h4,
.mbsc-page h5,
.mbsc-page h6,
.mbsc-h1,
.mbsc-h2,
.mbsc-h3,
.mbsc-h4,
.mbsc-h5,
.mbsc-h6 {
  margin: 0;
  padding: 0;
  color: inherit;
  font-weight: normal;
  font-family: inherit;
}

.mbsc-page p,
.mbsc-p {
  margin: 1em 0;
  padding: 0;
  line-height: 1.5;
}

.mbsc-page a,
.mbsc-a {
  text-decoration: none;
}

.mbsc-page a:hover,
.mbsc-a:hover {
  text-decoration: underline;
}

.mbsc-page a.mbsc-btn:hover,
.mbsc-a.mbsc-btn:hover {
  text-decoration: none;
}

.mbsc-page h1,
.mbsc-h1 {
  margin: 0.347826em 0;
  font-size: 2.875em;
}

.mbsc-page h2,
.mbsc-h2 {
  margin: 0.470588em 0;
  font-size: 2.125em;
}

.mbsc-page h3,
.mbsc-h3 {
  margin: 0.666666em 0;
  font-size: 1.5em;
}

.mbsc-page h4,
.mbsc-h4 {
  margin: 0.8em 0;
  font-size: 1.25em;
}

.mbsc-page h5,
.mbsc-h5 {
  margin: 1.066666em 0;
  font-size: 0.9375em;
}

.mbsc-page h6,
.mbsc-h6 {
  margin: 1.333333em 0;
  font-size: 0.75em;
}

/* Padding, margin */
.mbsc-padding {
  padding: 1em;
}

.mbsc-padding > p:first-child {
  margin-top: 0;
}

.mbsc-padding > p:last-child {
  margin-bottom: 0;
}

.mbsc-margin {
  margin: 1em 0;
}

.mbsc-margin:first-child {
  margin-top: 0;
}

.mbsc-margin:last-child {
  margin-bottom: 0;
}

/* Lists */
.mbsc-page ul,
.mbsc-page ol,
.mbsc-ul,
.mbsc-ol {
  padding: 0;
  margin: 1em 0 1em 1.25em;
  line-height: 1.5;
}

.mbsc-page ul ul,
.mbsc-page ol ol,
.mbsc-ul .mbsc-ul,
.mbsc-ol .mbsc-ol {
  margin: 0 0 0 1.25em;
}

/* Font sizes */
.mbsc-txt-xs {
  font-size: 0.625em;
}

.mbsc-txt-s {
  font-size: 0.75em;
}

.mbsc-txt-m {
  font-size: 1.25em;
}

.mbsc-txt-l {
  font-size: 1.5em;
}

.mbsc-txt-xl {
  font-size: 2em;
}

.mbsc-txt-muted {
  opacity: 0.6;
}

/* Line heights */
.mbsc-line-height-xs {
  line-height: 1;
}

.mbsc-line-height-s {
  line-height: 1.25;
}

.mbsc-line-height-m {
  line-height: 1.5;
}

.mbsc-line-height-l {
  line-height: 1.75;
}

.mbsc-line-height-xl {
  line-height: 2;
}

/* Font weights */
.mbsc-ultra-bold {
  font-weight: 900;
}

.mbsc-bold {
  font-weight: bold;
}

.mbsc-medium {
  font-weight: 500;
}

.mbsc-light {
  font-weight: 300;
}

.mbsc-thin {
  font-weight: 100;
}

.mbsc-italic {
  font-style: italic;
}

/* Text align */
.mbsc-align-left {
  text-align: left;
}

.mbsc-align-right {
  text-align: right;
}

.mbsc-align-center {
  text-align: center;
}

.mbsc-align-justify {
  text-align: justify;
}

/* Float */
.mbsc-pull-right {
  float: right;
}

.mbsc-pull-left {
  float: left;
}

/* Image section */
.mbsc-media-fluid {
  display: block;
  width: 100%;
}

.mbsc-img-thumbnail {
  width: 6em;
  height: 6em;
  margin: 1em;
}

/* Avatar image */
.mbsc-avatar {
  width: 2.5em;
  height: 2.5em;
  padding: 0;
  border-radius: 1.25em;
}

/* Note */
.mbsc-note {
  position: relative;
  padding: 0.75em 1.25em;
  margin: 1em;
  border: 1px solid transparent;
  font-size: 0.875em;
}

@media (max-width: 600px) {
  .mbsc-note {
    text-align: center;
  }
}
.mbsc-ios {
  /* Color presets */
}
.mbsc-ios.mbsc-radio-left {
  padding: 0.875em 1em 0.875em 3.75em;
}
.mbsc-ios.mbsc-radio-right {
  padding: 0.875em 3.75em 0.875em 1em;
}
.mbsc-ios.mbsc-radio-box:after {
  top: 44%;
  left: 23%;
  width: 0.875em;
  height: 0.375em;
  border: 0.125em solid;
  border-top: 0;
  border-right: 0;
  border-radius: 0;
  transform: rotate(-45deg);
  transition: opacity 0.2s ease-in-out;
}
.mbsc-ios.mbsc-radio-box:before {
  content: "";
  position: absolute;
  top: -0.75em;
  left: -0.75em;
  z-index: -1;
  width: 2.5em;
  height: 2.5em;
  opacity: 0;
  border-radius: 2.5em;
  transition: opacity 0.2s ease-in-out;
  background: #ccc;
}
.mbsc-ios.mbsc-radio-box.mbsc-focus:before {
  opacity: 0.12;
}
.mbsc-ios.mbsc-radio-box-left {
  left: 1.125em;
}
.mbsc-ios.mbsc-radio-box-right {
  right: 1.125em;
}
.mbsc-ios.mbsc-radio-box.mbsc-disabled {
  opacity: 0.3;
}
.mbsc-ios.mbsc-radio-box.mbsc-radio-box-primary:after {
  border-color: #3f97f6;
}
.mbsc-ios.mbsc-radio-box.mbsc-radio-box-secondary:after {
  border-color: #90979e;
}
.mbsc-ios.mbsc-radio-box.mbsc-radio-box-success:after {
  border-color: #43be5f;
}
.mbsc-ios.mbsc-radio-box.mbsc-radio-box-danger:after {
  border-color: #f5504e;
}
.mbsc-ios.mbsc-radio-box.mbsc-radio-box-warning:after {
  border-color: #f8b042;
}
.mbsc-ios.mbsc-radio-box.mbsc-radio-box-info:after {
  border-color: #5bb7c5;
}

.mbsc-ios.mbsc-radio-box:after {
  border-color: #1273de;
}
.mbsc-ios.mbsc-radio-label.mbsc-disabled {
  color: #c4c4c4;
}

.mbsc-ios-dark.mbsc-radio-box:after {
  border-color: #ffa519;
}
.mbsc-ios-dark.mbsc-radio-label.mbsc-disabled {
  color: #3b3b3b;
}

.mbsc-radio {
  position: relative;
  display: block;
  margin: 0;
  z-index: 0;
  line-height: 1.25em;
  user-select: none;
}

.mbsc-radio-box {
  position: absolute;
  top: 50%;
  display: block;
  width: 1.25em;
  height: 1.25em;
  margin-top: -0.625em;
  border-radius: 1.25em;
  box-sizing: border-box;
}

.mbsc-radio-box:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0.625em;
  height: 0.625em;
  margin-top: -0.3125em;
  margin-left: -0.3125em;
  border-radius: 0.625em;
  opacity: 0;
}

.mbsc-radio-box.mbsc-checked:after {
  opacity: 1;
}

.mbsc-ios.mbsc-segmented {
  margin: 0.75em;
  padding: 0.0625em;
  position: relative;
  border-radius: 0.5625em;
}
.mbsc-ios.mbsc-segmented-item:before {
  content: "";
  position: absolute;
  border-left: 1px solid;
  top: 0.3125em;
  bottom: 0.3125em;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}
.mbsc-ios.mbsc-segmented-item.mbsc-ltr:before {
  left: 0;
}
.mbsc-ios.mbsc-segmented-item.mbsc-rtl:before {
  right: 0;
}
.mbsc-ios.mbsc-segmented-item:first-child:before, .mbsc-ios.mbsc-segmented-item-selected:before, .mbsc-ios.mbsc-segmented-item-selected + .mbsc-segmented-item:before {
  opacity: 0;
}
.mbsc-ios.mbsc-segmented-selectbox {
  position: absolute;
  padding: 0.0625em;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  user-select: none;
  border-radius: 0.4375em;
  display: none;
}
.mbsc-ios.mbsc-segmented-selectbox.mbsc-selected {
  display: block;
}
.mbsc-ios.mbsc-segmented-selectbox-animate {
  transition: transform 0.2s ease-in-out;
}
.mbsc-ios.mbsc-segmented-selectbox-inner {
  height: 100%;
  border-radius: 0.4375em;
  transition: transform 0.1s ease-in-out;
  visibility: hidden;
}
.mbsc-ios.mbsc-segmented-selectbox-inner-visible {
  visibility: visible;
}
.mbsc-ios.mbsc-segmented-item:first-child .mbsc-segmented-selectbox-inner {
  transform-origin: left;
}
.mbsc-ios.mbsc-segmented-item:last-child .mbsc-segmented-selectbox-inner {
  transform-origin: right;
}
.mbsc-ios.mbsc-segmented-dragging .mbsc-segmented-selectbox-inner {
  transform: scale(0.97, 0.95);
}
.mbsc-ios.mbsc-segmented-dragging .mbsc-segmented-item:first-child .mbsc-segmented-selectbox-inner {
  transform: scale(0.97, 0.95) translateX(0.0625em);
}
.mbsc-ios.mbsc-segmented-dragging .mbsc-segmented-item:last-child .mbsc-segmented-selectbox-inner {
  transform: scale(0.97, 0.95) translateX(-0.0625em);
}
.mbsc-ios.mbsc-segmented-button.mbsc-icon-button {
  height: 2.307693em;
}
.mbsc-ios.mbsc-segmented-button.mbsc-button {
  margin: 0;
  padding: 0 0.615385em;
  border-radius: 0;
  font-size: 0.8125em;
  line-height: 2.307693em;
  transition: opacity 0.1s ease-out, background-color 0.1s ease-out, transform 0.1s ease-in-out;
  -webkit-font-smoothing: subpixel-antialiased;
}
.mbsc-ios.mbsc-segmented-button.mbsc-button.mbsc-active, .mbsc-ios.mbsc-segmented-button.mbsc-button.mbsc-hover {
  opacity: 1;
}
.mbsc-ios.mbsc-segmented-dragging .mbsc-segmented-item-selected .mbsc-segmented-button {
  transform: scale(0.97, 0.95);
}
.mbsc-ios.mbsc-segmented-item-selected:first-child .mbsc-segmented-button {
  transform-origin: left;
}
.mbsc-ios.mbsc-segmented-item-selected:last-child .mbsc-segmented-button {
  transform-origin: right;
}

.mbsc-ios {
  /* Inside popup and calendar header */
  /* Color presets */
}
.mbsc-ios.mbsc-segmented {
  background: #dedede;
}
.mbsc-ios.mbsc-segmented-item:before {
  border-color: rgba(0, 0, 0, 0.2);
}
.mbsc-ios.mbsc-segmented-item.mbsc-focus .mbsc-segmented-selectbox {
  box-shadow: 0 0 0 0.0625em rgba(0, 0, 0, 0.5) inset;
}
.mbsc-ios.mbsc-segmented-selectbox-inner {
  background: #fff;
}
.mbsc-ios.mbsc-segmented-selectbox-inner.mbsc-selected {
  box-shadow: #bababa 3px 3px 8px -4px;
}
.mbsc-ios.mbsc-segmented-button.mbsc-button {
  background: transparent;
  color: #000;
}
.mbsc-ios.mbsc-segmented-primary {
  background: #3f97f6;
}
.mbsc-ios.mbsc-segmented-secondary {
  background: #90979e;
}
.mbsc-ios.mbsc-segmented-success {
  background: #43be5f;
}
.mbsc-ios.mbsc-segmented-warning {
  background: #f8b042;
}
.mbsc-ios.mbsc-segmented-danger {
  background: #f5504e;
}
.mbsc-ios.mbsc-segmented-info {
  background: #5bb7c5;
}
.mbsc-ios.mbsc-segmented-light {
  background: #fff;
}
.mbsc-ios.mbsc-segmented-dark {
  background: #47494a;
}

.mbsc-ios-dark {
  /* Inside popup and calendar header */
  /* Color presets */
}
.mbsc-ios-dark.mbsc-segmented {
  background: #1c1c1c;
}
.mbsc-ios-dark.mbsc-segmented-item:before {
  border-color: rgba(255, 255, 255, 0.2);
}
.mbsc-ios-dark.mbsc-segmented-item.mbsc-focus .mbsc-segmented-selectbox {
  box-shadow: 0 0 0 0.0625em rgba(255, 255, 255, 0.5) inset;
}
.mbsc-ios-dark.mbsc-segmented-selectbox-inner {
  background: #5a5a5a;
}
.mbsc-ios-dark.mbsc-segmented-selectbox-inner.mbsc-selected {
  box-shadow: #000000 3px 3px 8px -4px;
}
.mbsc-ios-dark.mbsc-segmented-button.mbsc-button {
  background: transparent;
  color: #fff;
}
.mbsc-ios-dark.mbsc-datepicker .mbsc-segmented, .mbsc-ios-dark.mbsc-popup .mbsc-ios-dark.mbsc-segmented, .mbsc-ios-dark.mbsc-calendar-header .mbsc-ios-dark.mbsc-segmented {
  background: #2b2b2b;
}
.mbsc-ios-dark.mbsc-segmented-primary {
  background: #3f97f6;
}
.mbsc-ios-dark.mbsc-segmented-secondary {
  background: #90979e;
}
.mbsc-ios-dark.mbsc-segmented-success {
  background: #43be5f;
}
.mbsc-ios-dark.mbsc-segmented-warning {
  background: #f8b042;
}
.mbsc-ios-dark.mbsc-segmented-danger {
  background: #f5504e;
}
.mbsc-ios-dark.mbsc-segmented-info {
  background: #5bb7c5;
}
.mbsc-ios-dark.mbsc-segmented-light {
  background: #fff;
}
.mbsc-ios-dark.mbsc-segmented-dark {
  background: #47494a;
}

.mbsc-segmented {
  overflow: hidden;
}

.mbsc-segmented-item {
  margin: 0;
  position: relative;
  text-align: center;
  font-size: 1em;
  flex: 1 1 0;
  user-select: none;
  min-width: 0;
}

.mbsc-segmented-label {
  display: block;
  margin: 0;
  padding: 0;
}

.mbsc-segmented-input {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  position: absolute;
}

.mbsc-segmented-button.mbsc-button {
  width: 100%;
}

.mbsc-ios.mbsc-stepper-input {
  margin-left: -1px;
  margin-right: -1px;
  z-index: 1;
  border: 1px solid;
}
.mbsc-ios.mbsc-stepper-button.mbsc-button {
  line-height: 1em;
}
.mbsc-ios.mbsc-stepper-button.mbsc-disabled {
  opacity: 1;
}
.mbsc-ios.mbsc-stepper-inner {
  font-weight: normal;
}
.mbsc-ios.mbsc-disabled .mbsc-stepper-inner {
  opacity: 0.2;
}
.mbsc-ios.mbsc-stepper-plus:before {
  content: "";
  position: absolute;
  border-left: 1px solid;
  top: 0.4125em;
  bottom: 0.4125em;
}
.mbsc-ios.mbsc-stepper-plus.mbsc-ltr:before {
  left: 0;
}
.mbsc-ios.mbsc-stepper-plus.mbsc-rtl:before {
  right: 0;
}
.mbsc-ios.mbsc-stepper-center .mbsc-stepper-plus:before {
  display: none;
}
.mbsc-ios.mbsc-primary .mbsc-stepper-button {
  color: #3f97f6;
}
.mbsc-ios.mbsc-secondary .mbsc-stepper-button {
  color: #90979e;
}
.mbsc-ios.mbsc-success .mbsc-stepper-button {
  color: #43be5f;
}
.mbsc-ios.mbsc-danger .mbsc-stepper-button {
  color: #f5504e;
}
.mbsc-ios.mbsc-warning .mbsc-stepper-button {
  color: #f8b042;
}
.mbsc-ios.mbsc-info .mbsc-stepper-button {
  color: #5bb7c5;
}

.mbsc-ios.mbsc-stepper-input {
  border-color: #dedede;
  color: #000000;
}
.mbsc-ios.mbsc-stepper-input.mbsc-disabled {
  color: rgba(0, 0, 0, 0.2);
}
.mbsc-ios.mbsc-stepper-button {
  background: #dedede;
  color: #000000;
}
.mbsc-ios.mbsc-stepper-plus:before {
  border-color: rgba(0, 0, 0, 0.2);
}

.mbsc-ios-dark.mbsc-stepper-input {
  border-color: #2b2b2b;
  color: #ffffff;
}
.mbsc-ios-dark.mbsc-stepper-input.mbsc-disabled {
  color: rgba(255, 255, 255, 0.2);
}
.mbsc-ios-dark.mbsc-stepper-button {
  background: #2b2b2b;
  color: #ffffff;
}
.mbsc-ios-dark.mbsc-stepper-plus:before {
  border-color: rgba(255, 255, 255, 0.2);
}

.mbsc-stepper {
  line-height: 1.25em;
  position: relative;
  display: block;
  margin: 0;
  z-index: 0;
  user-select: none;
  box-sizing: border-box;
  padding: 1.5em 11.75em 1.5em 1em;
}

.mbsc-stepper.mbsc-rtl {
  padding: 1.5em 1em 1.5em 11.75em;
}

.mbsc-stepper-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.mbsc-stepper-input {
  width: 3.571429em;
  padding: 0;
  margin: 0;
  box-shadow: none;
  border-radius: 0;
  font-size: 0.875em;
  text-align: center;
  opacity: 1;
  z-index: 3;
  background: transparent;
  appearance: textfield;
}

.mbsc-stepper-input::-webkit-outer-spin-button,
.mbsc-stepper-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mbsc-stepper-input:focus {
  outline-width: 0;
}

.mbsc-stepper-input.mbsc-disabled {
  cursor: not-allowed;
}

.mbsc-stepper-control {
  position: absolute;
  z-index: 2;
  margin-top: -1em;
  top: 50%;
  height: 2em;
}

.mbsc-stepper-control.mbsc-ltr {
  right: 1em;
}

.mbsc-stepper-control.mbsc-rtl {
  left: 1em;
}

.mbsc-stepper-start .mbsc-stepper-input {
  border: none;
  order: -1;
}

.mbsc-stepper-start.mbsc-ltr .mbsc-stepper-minus.mbsc-button {
  margin-right: -1px;
}

.mbsc-stepper-start.mbsc-rtl .mbsc-stepper-minus.mbsc-button {
  margin-left: -1px;
}

.mbsc-stepper-end .mbsc-stepper-input {
  border: none;
  order: 2;
}

.mbsc-stepper-end.mbsc-ltr .mbsc-stepper-minus.mbsc-button {
  margin-right: -1px;
}

.mbsc-stepper-end.mbsc-rtl .mbsc-stepper-minus.mbsc-button {
  margin-left: -1px;
}

.mbsc-stepper-button.mbsc-button.mbsc-font {
  width: 3.25em;
  margin: 0;
  display: inline-block;
}

.mbsc-stepper-inner {
  font-size: 2em;
  font-weight: bold;
}

.mbsc-stepper-minus.mbsc-button.mbsc-ltr {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.mbsc-stepper-plus.mbsc-button.mbsc-ltr {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.mbsc-stepper-minus.mbsc-button.mbsc-rtl {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.mbsc-stepper-plus.mbsc-button.mbsc-rtl {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.mbsc-ios.mbsc-switch-right {
  padding: 0.875em 5em 0.875em 1em;
}
.mbsc-ios.mbsc-switch-left {
  padding: 0.875em 1em 0.875em 5em;
}
.mbsc-ios.mbsc-switch-track {
  width: 1.25em;
  height: 2em;
  padding: 0;
  margin: -0.96875em 0.875em 0 0.875em;
  border-radius: 1.25em;
}
.mbsc-ios.mbsc-switch-track-right {
  right: 1em;
}
.mbsc-ios.mbsc-switch-track-left {
  left: 1em;
}
.mbsc-ios.mbsc-switch-track:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  right: -1em;
  bottom: 0;
  left: -1em;
  border-radius: 1.25em;
  transition: background-color 0.2s ease-out;
}
.mbsc-ios.mbsc-switch-track.mbsc-disabled {
  opacity: 0.3;
}
.mbsc-ios.mbsc-switch-handle {
  z-index: 2;
  top: 50%;
  left: 50%;
  width: 1.75em;
  height: 1.75em;
  margin: -0.875em 0 0 -0.875em;
  border-radius: 1.75em;
  box-shadow: 0 0.1875em 0.75em rgba(0, 0, 0, 0.16), 0 0.1875em 0.0625em rgba(0, 0, 0, 0.1);
  background: #fff;
}
.mbsc-ios.mbsc-switch-track.mbsc-switch-primary.mbsc-checked:after {
  background: #3f97f6;
}
.mbsc-ios.mbsc-switch-track.mbsc-switch-secondary.mbsc-checked:after {
  background: #90979e;
}
.mbsc-ios.mbsc-switch-track.mbsc-switch-success.mbsc-checked:after {
  background: #43be5f;
}
.mbsc-ios.mbsc-switch-track.mbsc-switch-danger.mbsc-checked:after {
  background: #f5504e;
}
.mbsc-ios.mbsc-switch-track.mbsc-switch-warning.mbsc-checked:after {
  background: #f8b042;
}
.mbsc-ios.mbsc-switch-track.mbsc-switch-info.mbsc-checked:after {
  background: #5bb7c5;
}

.mbsc-ios.mbsc-switch-track:after {
  background: #e5e5e5;
}
.mbsc-ios.mbsc-switch-track.mbsc-focus:after {
  box-shadow: 0 0 0 0.125em rgba(0, 0, 0, 0.5);
}
.mbsc-ios.mbsc-switch-track.mbsc-checked:after {
  background: #4cd764;
}

.mbsc-ios-dark.mbsc-switch-track:after {
  background: #383838;
}
.mbsc-ios-dark.mbsc-switch-track.mbsc-focus:after {
  box-shadow: 0 0 0 0.125em rgba(255, 255, 255, 0.5);
}
.mbsc-ios-dark.mbsc-switch-track.mbsc-checked:after {
  background: #4cd764;
}

.mbsc-switch {
  position: relative;
  display: block;
  margin: 0;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  line-height: 1.25em;
}

.mbsc-switch-track {
  position: absolute;
  top: 50%;
  z-index: 4;
  display: block;
  box-sizing: border-box;
}

.mbsc-switch-handle {
  display: block;
  position: absolute;
  cursor: pointer;
}
.mbsc-switch-handle.mbsc-disabled {
  cursor: not-allowed;
}

.mbsc-switch-handle-animate {
  transition: left 0.1s ease-in-out;
}

.mbsc-description {
  display: block;
  font-size: 0.75em;
  opacity: 0.6;
}

.mbsc-ios {
  /* Range Highlight */
  /* Marks */
  /* Colors */
  /* Labels */
  /* Desktop styling */
  /* Multi month grid view */
}
.mbsc-ios.mbsc-calendar-controls {
  /* IE11 needs px size here to avoid subpixel values */
  padding: 2px;
  /* padding: .125em; */
}
.mbsc-ios.mbsc-calendar-cell {
  border-top-style: solid;
  border-top-width: 1px;
}
.mbsc-ios.mbsc-calendar-cell-text {
  height: 2em;
  padding: 0 0.5em;
  line-height: 2em;
  border-radius: 2em;
}
.mbsc-ios.mbsc-calendar-week-day {
  height: 1.7em;
  line-height: 1.7em;
  font-size: 0.625em;
}
.mbsc-ios.mbsc-calendar-week-nr {
  width: 2em;
  height: auto;
  font-size: 0.75em;
  font-weight: bold;
  line-height: 3em;
}
.mbsc-ios.mbsc-calendar-day-text {
  width: 1.625em;
  height: 1.625em;
  margin: 0.1875em;
  padding: 0;
  line-height: 1.625em;
  border: 2px solid transparent;
  border-radius: 2em;
}
.mbsc-ios.mbsc-range-day .mbsc-calendar-cell-inner {
  z-index: 1;
}
.mbsc-ios.mbsc-range-day::after, .mbsc-ios.mbsc-range-hover::before {
  content: "";
  height: 1.875em;
  position: absolute;
  left: -1px;
  right: -1px;
  top: 0.25em;
  z-index: 0;
  box-sizing: border-box;
}
.mbsc-ios.mbsc-range-hover::before {
  border-top: 2px dashed;
  border-bottom: 2px dashed;
}
.mbsc-ios.mbsc-range-day-start.mbsc-ltr::after, .mbsc-ios.mbsc-range-day-end.mbsc-rtl::after, .mbsc-ios.mbsc-range-hover-start.mbsc-ltr::before, .mbsc-ios.mbsc-range-hover-end.mbsc-rtl::before {
  left: 50%;
  margin-left: -0.9375em;
  border-radius: 2em 0 0 2em;
}
.mbsc-ios.mbsc-range-day-end.mbsc-ltr::after, .mbsc-ios.mbsc-range-day-start.mbsc-rtl::after, .mbsc-ios.mbsc-range-hover-end.mbsc-ltr::before, .mbsc-ios.mbsc-range-hover-start.mbsc-rtl::before {
  right: 50%;
  margin-right: -0.9375em;
  border-radius: 0 2em 2em 0;
}
.mbsc-ios.mbsc-range-day-start.mbsc-range-day-end::after, .mbsc-ios.mbsc-range-hover-start.mbsc-range-hover-end::before {
  display: none;
}
.mbsc-ios.mbsc-calendar-day-marked {
  padding-bottom: 0.4375em;
}
.mbsc-ios.mbsc-calendar-marks {
  margin-top: -0.0625em;
}
.mbsc-ios.mbsc-calendar-mark {
  width: 0.375em;
  height: 0.375em;
  border-radius: 0.375em;
  margin: 0 0.0625em;
}
.mbsc-ios.mbsc-calendar-day-colors .mbsc-calendar-day-text {
  background-clip: padding-box;
}
.mbsc-ios.mbsc-calendar-day-colors.mbsc-hover .mbsc-calendar-cell-text {
  background-clip: border-box;
}
.mbsc-ios.mbsc-calendar-text:before {
  border-radius: 0.4em;
}
.mbsc-ios.mbsc-calendar-label {
  font-weight: 600;
}
.mbsc-ios.mbsc-calendar-label-background {
  margin: 0 -0.1em;
  background: currentColor;
  opacity: 0.3;
  transition: opacity 0.15s ease-in-out;
}
.mbsc-ios.mbsc-calendar-label-start.mbsc-ltr .mbsc-calendar-label-background, .mbsc-ios.mbsc-calendar-label-end.mbsc-rtl .mbsc-calendar-label-background {
  margin-left: 0;
  border-top-left-radius: 0.4em;
  border-bottom-left-radius: 0.4em;
}
.mbsc-ios.mbsc-calendar-label-end.mbsc-ltr .mbsc-calendar-label-background, .mbsc-ios.mbsc-calendar-label-start.mbsc-rtl .mbsc-calendar-label-background {
  margin-right: 0;
  border-top-right-radius: 0.4em;
  border-bottom-right-radius: 0.4em;
}
.mbsc-ios.mbsc-calendar-label-hover .mbsc-calendar-label-background {
  opacity: 0.6;
}
.mbsc-ios.mbsc-calendar-label.mbsc-calendar-label-hover:before {
  background: none;
}
.mbsc-ios.mbsc-calendar-label-dragging .mbsc-calendar-label-background, .mbsc-ios.mbsc-calendar-label-active .mbsc-calendar-label-background {
  opacity: 0.9;
}
.mbsc-ios.mbsc-calendar-height-md .mbsc-calendar-day:after {
  position: absolute;
  top: 0;
  right: 100%;
  bottom: 0;
  z-index: 1;
  margin-right: -1px;
  border-left-width: 1px;
  border-left-style: solid;
  content: "";
}
.mbsc-ios.mbsc-calendar-width-md .mbsc-calendar-title {
  font-size: 1.5em;
  line-height: 1.666667em;
  padding: 0 0.166667em;
}
.mbsc-ios.mbsc-calendar-width-md .mbsc-calendar-week-day {
  height: 2.5em;
  padding: 0 0.5em;
  line-height: 2.5em;
  font-size: 1em;
  border-left: 1px solid transparent;
}
.mbsc-ios.mbsc-calendar-width-md .mbsc-calendar-day-inner {
  min-height: 2.5em;
}
.mbsc-ios.mbsc-calendar-width-md .mbsc-calendar-day-labels .mbsc-calendar-day-inner {
  min-height: 4.75em;
}
.mbsc-ios.mbsc-calendar-width-md .mbsc-calendar-marks {
  padding: 0 0.75em;
}
.mbsc-ios.mbsc-calendar-width-md .mbsc-calendar-day-text {
  width: 1.375em;
  height: 1.375em;
  line-height: 1.375em;
}
.mbsc-ios.mbsc-calendar-width-md .mbsc-calendar-text {
  height: 1.8em;
  line-height: 1.8em;
  margin: 0 0.5em 0.2em 0.6em;
}
.mbsc-ios.mbsc-calendar-width-md .mbsc-calendar-label {
  padding: 0 0.4em;
}
.mbsc-ios.mbsc-calendar-width-md .mbsc-calendar-label-text {
  font-size: 1.2em;
}
.mbsc-ios.mbsc-calendar-width-md .mbsc-calendar-label-background {
  margin: 0 -0.4em;
}
.mbsc-ios.mbsc-calendar-height-md.mbsc-calendar-width-md .mbsc-calendar-week-day.mbsc-ltr,
.mbsc-ios.mbsc-calendar-height-md.mbsc-calendar-width-md .mbsc-calendar-day.mbsc-ltr,
.mbsc-ios.mbsc-calendar-height-md.mbsc-calendar-width-md .mbsc-calendar-marks.mbsc-ltr {
  text-align: right;
}
.mbsc-ios.mbsc-calendar-height-md.mbsc-calendar-width-md .mbsc-calendar-week-day.mbsc-rtl,
.mbsc-ios.mbsc-calendar-height-md.mbsc-calendar-width-md .mbsc-calendar-day.mbsc-rtl,
.mbsc-ios.mbsc-calendar-height-md.mbsc-calendar-width-md .mbsc-calendar-marks.mbsc-rtl {
  text-align: left;
}
.mbsc-ios.mbsc-calendar-grid-view .mbsc-calendar-title {
  font-size: 1.5em;
  line-height: 1.666667em;
  padding: 0 0.166667em;
}
.mbsc-ios.mbsc-calendar-grid {
  border-top: 1px solid;
}
.mbsc-ios.mbsc-calendar-grid .mbsc-calendar-grid-item .mbsc-calendar-week-days {
  background: none;
}
.mbsc-ios.mbsc-calendar-grid .mbsc-calendar-cell {
  border: 0;
}

.mbsc-ios {
  /* range highlight and selection */
  /* Picker */
  /* Multi month grid view */
}
.mbsc-ios.mbsc-calendar {
  background: white;
  color: #000000;
}
.mbsc-ios.mbsc-calendar-wrapper {
  border-color: #cccccc;
}
.mbsc-ios.mbsc-calendar-header {
  border-color: #cccccc;
}
.mbsc-ios.mbsc-calendar-button.mbsc-button {
  color: #007aff;
}
.mbsc-ios.mbsc-calendar-cell {
  background: white;
  border-color: #cccccc;
  color: #000000;
}
.mbsc-ios.mbsc-calendar-day:after {
  border-color: #cccccc;
}
.mbsc-ios.mbsc-calendar-week-nr, .mbsc-ios.mbsc-calendar-today {
  color: #007aff;
}
.mbsc-ios.mbsc-hover .mbsc-calendar-cell-text {
  background-color: rgba(0, 122, 255, 0.3);
}
.mbsc-ios.mbsc-range-day::after {
  background-color: #cbe2fb;
}
.mbsc-ios.mbsc-range-day .mbsc-calendar-cell-text {
  color: #000;
}
.mbsc-ios.mbsc-range-hover::before {
  border-color: #dedede;
}
.mbsc-ios.mbsc-selected .mbsc-calendar-cell-text {
  border-color: #007aff;
  background: #007aff;
  color: #fff;
}
.mbsc-ios.mbsc-focus .mbsc-calendar-cell-text {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.5);
}
.mbsc-ios.mbsc-focus .mbsc-calendar-day-text {
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0.5);
}
.mbsc-ios.mbsc-calendar-mark {
  background: #cccccc;
}
.mbsc-ios.mbsc-calendar-label {
  color: #5ac8fa;
}
.mbsc-ios.mbsc-calendar-label-text {
  color: #000;
}
.mbsc-ios.mbsc-calendar-label-active .mbsc-calendar-label-inner, .mbsc-ios.mbsc-calendar-label-dragging .mbsc-calendar-label-inner {
  color: #fff;
}
.mbsc-ios.mbsc-calendar-label-active .mbsc-calendar-label-text, .mbsc-ios.mbsc-calendar-label-dragging .mbsc-calendar-label-text {
  color: inherit;
}
.mbsc-ios.mbsc-calendar-text-more .mbsc-calendar-label-text {
  color: #000000;
}
.mbsc-ios.mbsc-calendar-popup .mbsc-popup-arrow,
.mbsc-ios.mbsc-calendar-popup .mbsc-popup-body {
  background: white;
}
.mbsc-ios.mbsc-calendar-popup .mbsc-calendar-cell {
  background: white;
}
.mbsc-ios.mbsc-calendar-grid {
  border-color: #cccccc;
}
.mbsc-ios.mbsc-calendar-month-title {
  color: #007aff;
}

.mbsc-ios-dark {
  /* range highlight and selection */
  /* Picker */
  /* Multi month grid view */
}
.mbsc-ios-dark.mbsc-calendar {
  background: #000000;
  color: #ffffff;
}
.mbsc-ios-dark.mbsc-calendar-wrapper {
  border-color: #333333;
}
.mbsc-ios-dark.mbsc-calendar-header {
  border-color: #333333;
}
.mbsc-ios-dark.mbsc-calendar-button.mbsc-button {
  color: #ff9f0a;
}
.mbsc-ios-dark.mbsc-calendar-cell {
  background: #000000;
  border-color: #333333;
  color: #ffffff;
}
.mbsc-ios-dark.mbsc-calendar-day:after {
  border-color: #333333;
}
.mbsc-ios-dark.mbsc-calendar-week-nr, .mbsc-ios-dark.mbsc-calendar-today {
  color: #ff9f0a;
}
.mbsc-ios-dark.mbsc-hover .mbsc-calendar-cell-text {
  background-color: rgba(255, 159, 10, 0.3);
}
.mbsc-ios-dark.mbsc-range-day::after {
  background-color: #3d2705;
}
.mbsc-ios-dark.mbsc-range-day .mbsc-calendar-cell-text {
  color: #fff;
}
.mbsc-ios-dark.mbsc-range-hover::before {
  border-color: #2b2b2b;
}
.mbsc-ios-dark.mbsc-selected .mbsc-calendar-cell-text {
  border-color: #ff9f0a;
  background: #ff9f0a;
  color: #fff;
}
.mbsc-ios-dark.mbsc-focus .mbsc-calendar-cell-text {
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
}
.mbsc-ios-dark.mbsc-focus .mbsc-calendar-day-text {
  box-shadow: none;
  border-color: rgba(255, 255, 255, 0.5);
}
.mbsc-ios-dark.mbsc-calendar-mark {
  background: #333333;
}
.mbsc-ios-dark.mbsc-calendar-label {
  color: #5ac8fa;
}
.mbsc-ios-dark.mbsc-calendar-label-text {
  color: #fff;
}
.mbsc-ios-dark.mbsc-calendar-label-active .mbsc-calendar-label-inner, .mbsc-ios-dark.mbsc-calendar-label-dragging .mbsc-calendar-label-inner {
  color: #fff;
}
.mbsc-ios-dark.mbsc-calendar-label-active .mbsc-calendar-label-text, .mbsc-ios-dark.mbsc-calendar-label-dragging .mbsc-calendar-label-text {
  color: inherit;
}
.mbsc-ios-dark.mbsc-calendar-text-more .mbsc-calendar-label-text {
  color: #ffffff;
}
.mbsc-ios-dark.mbsc-calendar-popup .mbsc-popup-arrow,
.mbsc-ios-dark.mbsc-calendar-popup .mbsc-popup-body {
  background: #292929;
}
.mbsc-ios-dark.mbsc-calendar-popup .mbsc-calendar-cell {
  background: #1c1c1c;
}
.mbsc-ios-dark.mbsc-calendar-grid {
  border-color: #333333;
}
.mbsc-ios-dark.mbsc-calendar-month-title {
  color: #ff9f0a;
}

@keyframes mbsc-zoom-in-up {
  from {
    opacity: 0;
    transform: scale(2);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes mbsc-zoom-in-down {
  from {
    opacity: 0;
    transform: scale(0.5);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes mbsc-zoom-out-up {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(2);
  }
}
@keyframes mbsc-zoom-out-down {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.5);
  }
}
.mbsc-calendar {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
  position: relative;
}

.mbsc-calendar-wrapper {
  position: relative;
  z-index: 1;
  height: 100%;
  overflow: hidden;
}

/* Header */
.mbsc-calendar-header {
  position: relative;
  z-index: 1;
  will-change: opacity;
}
.mbsc-calendar-header .mbsc-calendar-week-days {
  margin-left: -1px;
}

.mbsc-calendar-controls {
  align-items: center;
  box-sizing: content-box;
  min-height: 2.5em;
}

.mbsc-calendar-button.mbsc-button.mbsc-reset {
  margin: 0;
}

.mbsc-calendar-button-prev-multi {
  order: -1;
}

.mbsc-calendar-button-next-multi {
  order: 1;
}

.mbsc-calendar-body {
  box-sizing: border-box;
  overflow: hidden;
}

.mbsc-calendar-body-inner {
  position: relative;
  overflow: hidden;
  margin-left: -1px;
}

.mbsc-calendar-wrapper-fixed {
  height: auto;
  overflow: visible;
}

.mbsc-calendar-title-wrapper {
  align-items: center;
  overflow: hidden;
}

.mbsc-calendar-title {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.125em;
  padding: 0 0.25em;
  display: inline-block;
  vertical-align: middle;
}

/* Scrollview */
.mbsc-calendar-scroll-wrapper {
  display: block;
  overflow: hidden;
  position: relative;
  height: 100%;
}

.mbsc-calendar-picker-wrapper {
  position: relative;
  width: 15em;
  height: 15em;
  overflow: hidden;
}

.mbsc-calendar-picker {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.mbsc-calendar-picker-main {
  position: relative;
  z-index: 0;
}

.mbsc-calendar-picker-in-up {
  opacity: 0;
  animation: mbsc-zoom-in-up 200ms cubic-bezier(0, 0, 0.2, 1) forwards;
}

.mbsc-calendar-picker-in-down {
  opacity: 0;
  animation: mbsc-zoom-in-down 200ms cubic-bezier(0, 0, 0.2, 1) forwards;
}

.mbsc-calendar-picker-out-up {
  opacity: 0;
  animation: mbsc-zoom-out-up 200ms cubic-bezier(0.4, 0, 1, 1) forwards;
}

.mbsc-calendar-picker-out-down {
  opacity: 0;
  animation: mbsc-zoom-out-down 200ms cubic-bezier(0, 0, 0.2, 1) forwards;
}

.mbsc-calendar-scroll-wrapper > div {
  height: 100%;
}

.mbsc-calendar-scroll-wrapper > div > div {
  height: 100%;
  transform: translate3d(0, 0, 0);
}

.mbsc-calendar-slide {
  position: absolute;
  z-index: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.mbsc-calendar-slide.mbsc-ltr {
  left: 0;
}

.mbsc-calendar-slide.mbsc-rtl {
  right: 0;
}

.mbsc-calendar-slide:first-child {
  position: relative;
}

.mbsc-calendar-table {
  height: 100%;
  transform: translateZ(0);
}

.mbsc-calendar-cell {
  position: relative;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
}

.mbsc-calendar-cell:focus {
  outline: 0;
}

.mbsc-calendar-cell-inner {
  display: inline-block;
  padding: 0 0.25em;
  vertical-align: middle;
}

.mbsc-calendar-cell.mbsc-disabled {
  cursor: not-allowed;
}

.mbsc-calendar-week-day {
  height: 2em;
  font-size: 0.6875em;
  line-height: 2em;
  text-align: center;
  border-bottom: 1px solid transparent;
}

.mbsc-calendar-year,
.mbsc-calendar-month {
  vertical-align: middle;
}

.mbsc-calendar-year-text,
.mbsc-calendar-month-text {
  margin: 0.875em 0;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 4.5em;
  box-sizing: border-box;
}

.mbsc-calendar-day {
  min-width: 0;
  text-align: center;
}

.mbsc-calendar-day-empty {
  cursor: default;
}

.mbsc-calendar-day-text {
  display: inline-block;
  text-align: center;
  box-sizing: content-box;
}

.mbsc-calendar-day-inner {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  border: 1px solid transparent;
  box-sizing: border-box;
}

.mbsc-calendar-day-hidden {
  visibility: hidden;
}

.mbsc-calendar-month-name {
  display: none;
}

.mbsc-calendar-width-md .mbsc-calendar-month-name {
  display: inline-block;
  font-weight: bold;
}

.mbsc-calendar-day-outer .mbsc-calendar-day-text,
.mbsc-calendar-day-outer .mbsc-calendar-month-name {
  opacity: 0.5;
}

.mbsc-calendar-day-outer.mbsc-selected .mbsc-calendar-day-text,
.mbsc-calendar-day-outer.mbsc-selected .mbsc-calendar-month-name {
  opacity: 1;
}

.mbsc-disabled .mbsc-calendar-cell-text,
.mbsc-disabled .mbsc-calendar-month-name {
  opacity: 0.2;
}

/* Day highlight */
.mbsc-calendar-day-highlight:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
}

/* Marks */
.mbsc-calendar-day-marked {
  padding-bottom: 0.25em;
}

.mbsc-calendar-marks {
  position: absolute;
  left: 0;
  right: 0;
  height: 0.375em;
  margin-top: -0.125em;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
}

.mbsc-calendar-mark {
  display: inline-block;
  vertical-align: top;
  width: 0.25em;
  height: 0.25em;
  margin: 0.0625em;
  border-radius: 0.25em;
}

/* Labels */
.mbsc-calendar-labels {
  position: absolute;
  left: -1px;
  right: -1px;
}

.mbsc-calendar-label-wrapper {
  position: absolute;
  z-index: 2;
  pointer-events: none;
}

.mbsc-calendar-label {
  padding: 0 0.3em;
}

.mbsc-calendar-text:focus {
  outline: 0;
}

.mbsc-calendar-label-text {
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  z-index: 2;
}

.mbsc-calendar-text:before,
.mbsc-calendar-label-background {
  content: "";
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s;
}

.mbsc-calendar-label.mbsc-calendar-label-hover:before {
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.mbsc-calendar-text-more {
  padding: 0 0.3em;
}

.mbsc-calendar-text-more.mbsc-calendar-label-active:before,
.mbsc-calendar-text-more.mbsc-calendar-label-hover:before {
  background-color: rgba(0, 0, 0, 0.1);
}

.mbsc-calendar-text {
  display: block;
  position: relative;
  height: 1.6em;
  line-height: 1.6em;
  font-size: 0.625em;
  white-space: nowrap;
  margin: 0 0.1em 0.2em 0.2em;
}

.mbsc-calendar-text-placeholder {
  z-index: -1;
}

.mbsc-calendar-text.mbsc-ltr {
  text-align: left;
}

.mbsc-calendar-text.mbsc-rtl {
  text-align: right;
}

/* Label drag & drop */
.mbsc-calendar-label-resize {
  position: absolute;
  top: 0;
  height: 100%;
  width: 0.5em;
  z-index: 2;
  cursor: ew-resize;
}

.mbsc-calendar-label-resize:after {
  content: "";
  display: none;
  position: absolute;
  top: 50%;
  width: 0.8em;
  height: 0.8em;
  margin-top: -0.4em;
  background: #fff;
  border-radius: 0.8em;
  border: 1px solid currentColor;
  box-sizing: border-box;
}

.mbsc-calendar-label-resize-start.mbsc-ltr,
.mbsc-calendar-label-resize-end.mbsc-rtl {
  left: 0;
}

.mbsc-calendar-label-resize-start.mbsc-rtl,
.mbsc-calendar-label-resize-end.mbsc-ltr {
  right: 0;
}

.mbsc-calendar-label-resize-start.mbsc-ltr:after,
.mbsc-calendar-label-resize-end.mbsc-rtl:after {
  left: -0.4em;
}

.mbsc-calendar-label-resize-end.mbsc-ltr:after,
.mbsc-calendar-label-resize-start.mbsc-rtl:after {
  right: -0.4em;
}

.mbsc-calendar-label-resize-start-touch:before,
.mbsc-calendar-label-resize-end-touch:before {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -1em;
  width: 2em;
  height: 2em;
}

.mbsc-calendar-label-resize-start-touch:before {
  left: -1em;
}

.mbsc-calendar-label-resize-end-touch:before {
  right: -1em;
}

.mbsc-calendar-label-hover .mbsc-calendar-label-resize:after,
.mbsc-calendar-label-dragging .mbsc-calendar-label-resize:after {
  display: block;
}

.mbsc-calendar-label-inactive {
  opacity: 0.4;
}

.mbsc-calendar-label-hidden {
  visibility: hidden;
}

.mbsc-calendar-labels-dragging {
  z-index: 3;
}

/* Multi month grid view */
.mbsc-calendar-grid {
  overflow: auto;
  padding: 0 2%;
}
.mbsc-calendar-grid .mbsc-calendar-table {
  margin: 0 5%;
  width: 90%;
}

.mbsc-calendar-grid-item {
  margin: 1em 0;
  padding-top: 2.5em;
}

.mbsc-calendar-month-title {
  padding-bottom: 0.8em;
  margin: 0 10%;
  margin-top: -2em;
  font-size: 1.25em;
  font-weight: bold;
  line-height: 1.2em;
}

/* Variable row */
.mbsc-calendar-body-inner-variable {
  overflow-y: auto;
}
.mbsc-calendar-body-inner-variable .mbsc-calendar-table {
  min-height: 20em;
}

.mbsc-ios {
  /* Schedule view */
  /* TODO: refactor this without cascade */
  /* Header */
  /* Timezone labels, indicators */
  /* Grid & All-day row */
  /* Schedule event style */
  /* Schedule event all-day style */
  /* Large screen styles */
  /* timeline resource icon */
}
.mbsc-ios.mbsc-eventcalendar .mbsc-calendar-header-vertical {
  margin-bottom: -1px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.mbsc-ios.mbsc-eventcalendar .mbsc-calendar-wrapper {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.mbsc-ios.mbsc-popover-list .mbsc-event:last-child:after {
  display: none;
}
.mbsc-ios.mbsc-event-day.mbsc-list-header {
  font-weight: bold;
  padding: 0.5em 1em;
  line-height: 1.5em;
  text-transform: none;
}
.mbsc-ios.mbsc-event-time {
  width: 6em;
  font-size: 0.875em;
  line-height: 1.428572em;
}
.mbsc-ios.mbsc-event-color {
  width: 0.25em;
  border-radius: 0.125em;
  margin: 0 0.5em 0 -0.5em;
}
.mbsc-ios.mbsc-event-color.mbsc-rtl {
  margin: 0 -0.5em 0 0.5em;
}
.mbsc-ios.mbsc-event-time.mbsc-ltr {
  text-align: right;
}
.mbsc-ios.mbsc-event-time.mbsc-rtl {
  text-align: left;
}
.mbsc-ios.mbsc-event-end {
  opacity: 0.7;
}
.mbsc-ios.mbsc-event-text {
  font-weight: 500;
}
.mbsc-ios.mbsc-event-sep {
  display: none;
}
.mbsc-ios.mbsc-calendar-button-today {
  font-weight: 500;
  padding: 0;
}
.mbsc-ios.mbsc-eventcalendar-schedule .mbsc-calendar-day, .mbsc-ios.mbsc-eventcalendar-schedule .mbsc-calendar-wrapper {
  border: 0;
}
.mbsc-ios.mbsc-schedule-wrapper {
  position: relative;
  margin-top: -1px;
  z-index: 2;
}
.mbsc-ios.mbsc-schedule-header {
  border-bottom: 1px solid;
}
.mbsc-ios.mbsc-schedule-header-item {
  padding: 0.4375em 0;
  line-height: 1.75em;
}
.mbsc-ios.mbsc-schedule-header-item.mbsc-ltr {
  border-left-style: solid;
  border-left-width: 1px;
}
.mbsc-ios.mbsc-schedule-header-item.mbsc-rtl {
  border-right-style: solid;
  border-right-width: 1px;
}
.mbsc-ios.mbsc-schedule-header-item:first-child {
  border: 0;
}
.mbsc-ios.mbsc-schedule-header-dayname {
  font-size: 1em;
  font-weight: 400;
}
.mbsc-ios.mbsc-schedule-header-day {
  display: inline-block;
  height: 1.625em;
  width: 1.625em;
  line-height: 1.625em;
  border-radius: 50%;
  text-align: center;
  font-size: 1em;
  font-weight: 400;
}
.mbsc-ios.mbsc-schedule-header-item-large {
  padding: 0.1875em 0;
}
.mbsc-ios.mbsc-schedule-header-item-large.mbsc-schedule-header-item {
  border-color: transparent;
}
.mbsc-ios.mbsc-schedule-header-item-large .mbsc-schedule-header-dayname {
  display: inline-block;
  padding: 0 0.375em;
  font-size: 1em;
  font-weight: 400;
}
.mbsc-ios.mbsc-schedule-all-day-wrapper {
  max-height: 3.125em;
  border-bottom: 1px solid;
}
.mbsc-ios.mbsc-schedule-all-day-text {
  padding: 0.5em 0.666667em;
  font-size: 0.75em;
  text-transform: lowercase;
}
.mbsc-ios.mbsc-schedule-timezone-label {
  line-height: 2.5em;
  padding: 0 0.666667em;
}
.mbsc-ios.mbsc-schedule-date-header {
  position: relative;
  z-index: 3;
  will-change: opacity;
  border-bottom: 1px solid;
}
.mbsc-ios.mbsc-schedule-date-header-text {
  padding: 0.5em;
  font-size: 0.75em;
  font-weight: bold;
}
.mbsc-ios.mbsc-schedule-resource.mbsc-ltr {
  border-left-width: 1px;
  border-left-style: solid;
}
.mbsc-ios.mbsc-schedule-resource.mbsc-rtl {
  border-right-width: 1px;
  border-right-style: solid;
}
.mbsc-ios.mbsc-schedule-resource:first-child {
  border: 0;
}
.mbsc-ios.mbsc-schedule-all-day-item {
  padding: 0.25em 0;
  min-height: 1.875em;
}
.mbsc-ios.mbsc-schedule-time {
  font-size: 0.625em;
  padding: 0 0.8em;
}
.mbsc-ios.mbsc-schedule-cursor-time-x {
  width: 6.8em;
  padding: 0 0.8em;
}
.mbsc-ios.mbsc-schedule-event:after {
  content: "";
  position: absolute;
  top: -0.5em;
  right: 1px;
  bottom: -0.5em;
  left: 1px;
  z-index: -1;
}
.mbsc-ios.mbsc-schedule-event-start:after {
  top: 1px;
}
.mbsc-ios.mbsc-schedule-event-end:after {
  bottom: 1px;
}
.mbsc-ios.mbsc-schedule-event-custom:after {
  display: none;
}
.mbsc-ios.mbsc-schedule-event-background {
  opacity: 0.3;
  transition: opacity 0.15s ease-in-out;
}
.mbsc-ios.mbsc-schedule-event-start:after,
.mbsc-ios.mbsc-schedule-event-start .mbsc-schedule-event-background {
  border-top-left-radius: 0.25em;
  border-top-right-radius: 0.25em;
}
.mbsc-ios.mbsc-schedule-event-end:after,
.mbsc-ios.mbsc-schedule-event-end .mbsc-schedule-event-background {
  border-bottom-left-radius: 0.25em;
  border-bottom-right-radius: 0.25em;
}
.mbsc-ios.mbsc-timeline-event:after {
  margin-top: 6px;
  top: 0;
  bottom: 0;
}
.mbsc-ios.mbsc-timeline-event-start.mbsc-ltr:after,
.mbsc-ios.mbsc-timeline-event-start.mbsc-ltr .mbsc-schedule-event-background, .mbsc-ios.mbsc-timeline-event-end.mbsc-rtl:after,
.mbsc-ios.mbsc-timeline-event-end.mbsc-rtl .mbsc-schedule-event-background {
  border-bottom-left-radius: 0.25em;
  border-top-left-radius: 0.25em;
}
.mbsc-ios.mbsc-timeline-event-end.mbsc-ltr:after,
.mbsc-ios.mbsc-timeline-event-end.mbsc-ltr .mbsc-schedule-event-background, .mbsc-ios.mbsc-timeline-event-start.mbsc-rtl:after,
.mbsc-ios.mbsc-timeline-event-start.mbsc-rtl .mbsc-schedule-event-background {
  border-bottom-right-radius: 0.25em;
  border-top-right-radius: 0.25em;
}
.mbsc-ios.mbsc-schedule-event-hover .mbsc-schedule-event-background {
  opacity: 0.6;
}
.mbsc-ios.mbsc-schedule-event-active .mbsc-schedule-event-background, .mbsc-ios.mbsc-schedule-event-dragging .mbsc-schedule-event-background {
  opacity: 0.9;
}
.mbsc-ios.mbsc-schedule-event-dragging {
  top: 0.25em;
}
.mbsc-ios.mbsc-timeline-event-dragging {
  top: 0;
}
.mbsc-ios.mbsc-schedule-event-bar {
  position: absolute;
  top: -0.5em;
  bottom: -0.5em;
  width: 0.1875em;
  background: currentColor;
}
.mbsc-ios.mbsc-schedule-event-bar.mbsc-ltr {
  left: 1px;
}
.mbsc-ios.mbsc-schedule-event-bar.mbsc-rtl {
  right: 1px;
}
.mbsc-ios.mbsc-schedule-event-start .mbsc-schedule-event-bar {
  top: 1px;
}
.mbsc-ios.mbsc-schedule-event-start .mbsc-schedule-event-bar.mbsc-ltr {
  border-top-left-radius: 0.1875em;
}
.mbsc-ios.mbsc-schedule-event-start .mbsc-schedule-event-bar.mbsc-rtl {
  border-top-left-radius: 0.1875em;
}
.mbsc-ios.mbsc-schedule-event-end .mbsc-schedule-event-bar {
  bottom: 1px;
}
.mbsc-ios.mbsc-schedule-event-end .mbsc-schedule-event-bar.mbsc-ltr {
  border-bottom-left-radius: 0.1875em;
}
.mbsc-ios.mbsc-schedule-event-end .mbsc-schedule-event-bar.mbsc-rtl {
  border-bottom-left-radius: 0.1875em;
}
.mbsc-ios.mbsc-schedule-event-title {
  font-weight: 600;
}
.mbsc-ios.mbsc-schedule-event-all-day:after {
  top: 0;
  bottom: 0;
}
.mbsc-ios.mbsc-schedule-event-all-day-background.mbsc-schedule-event-background {
  border-radius: 0;
}
.mbsc-ios.mbsc-timeline-event-start.mbsc-schedule-event-all-day.mbsc-ltr:after, .mbsc-ios.mbsc-timeline-event-end.mbsc-schedule-event-all-day.mbsc-rtl:after, .mbsc-ios.mbsc-timeline-event-start.mbsc-ltr .mbsc-schedule-event-all-day-background, .mbsc-ios.mbsc-timeline-event-end.mbsc-rtl .mbsc-schedule-event-all-day-background, .mbsc-ios.mbsc-schedule-event-start.mbsc-schedule-event-all-day.mbsc-ltr:after, .mbsc-ios.mbsc-schedule-event-end.mbsc-schedule-event-all-day.mbsc-rtl:after, .mbsc-ios.mbsc-schedule-event-start.mbsc-ltr .mbsc-schedule-event-all-day-background, .mbsc-ios.mbsc-schedule-event-end.mbsc-rtl .mbsc-schedule-event-all-day-background {
  left: 0.25em;
  border-top-left-radius: 0.25em;
  border-bottom-left-radius: 0.25em;
}
.mbsc-ios.mbsc-timeline-event-end.mbsc-schedule-event-all-day.mbsc-ltr:after, .mbsc-ios.mbsc-timeline-event-start.mbsc-schedule-event-all-day.mbsc-rtl:after, .mbsc-ios.mbsc-timeline-event-end.mbsc-ltr .mbsc-schedule-event-all-day-background, .mbsc-ios.mbsc-timeline-event-start.mbsc-rtl .mbsc-schedule-event-all-day-background, .mbsc-ios.mbsc-schedule-event-end.mbsc-schedule-event-all-day.mbsc-ltr:after, .mbsc-ios.mbsc-schedule-event-start.mbsc-schedule-event-all-day.mbsc-rtl:after, .mbsc-ios.mbsc-schedule-event-end.mbsc-ltr .mbsc-schedule-event-all-day-background, .mbsc-ios.mbsc-schedule-event-start.mbsc-rtl .mbsc-schedule-event-all-day-background {
  right: 0.25em;
  border-top-right-radius: 0.25em;
  border-bottom-right-radius: 0.25em;
}
.mbsc-ios.mbsc-calendar-width-md .mbsc-schedule-all-day-wrapper {
  max-height: 3.5em;
}
.mbsc-ios.mbsc-calendar-width-md .mbsc-schedule-time,
.mbsc-ios.mbsc-calendar-width-md .mbsc-schedule-time-indicator-time-x {
  font-size: 0.75em;
  padding: 0 0.666667em;
}
.mbsc-ios.mbsc-calendar-width-md .mbsc-schedule-cursor-time-x {
  font-size: 0.75em;
  width: 5.666667em;
  padding: 0 0.666667em;
}
.mbsc-ios.mbsc-timeline-resource-icon {
  width: 1.214286em;
  padding: 0 0.142858em;
}

.mbsc-ios {
  /* Calendar view */
  /* Agenda view */
  /* Popover events */
  /* Schedule view */
  /* TODO: refactor this without cascade */
  /* Header */
  /* Grid & All-day row */
  /* Event style */
  /* Invalid */
  /* Timeline */
  /* Connections */
}
.mbsc-ios.mbsc-eventcalendar .mbsc-calendar-header,
.mbsc-ios.mbsc-eventcalendar .mbsc-calendar-week-days {
  background: #f7f7f7;
}
.mbsc-ios.mbsc-eventcalendar .mbsc-calendar-day.mbsc-disabled {
  background: #efefef;
}
.mbsc-ios.mbsc-event-list-empty {
  color: #6e6e72;
}
.mbsc-ios.mbsc-event-day.mbsc-list-header {
  background: white;
  border-color: #cccccc;
  color: #000000;
}
.mbsc-ios.mbsc-event.mbsc-list-item {
  background: white;
  color: #000000;
}
.mbsc-ios.mbsc-event.mbsc-list-item:before, .mbsc-ios.mbsc-event.mbsc-list-item:after {
  border-color: #cccccc;
}
.mbsc-ios.mbsc-event.mbsc-list-item.mbsc-hover:before {
  background: rgba(0, 0, 0, 0.05);
}
.mbsc-ios.mbsc-event.mbsc-list-item.mbsc-focus .mbsc-list-item-background, .mbsc-ios.mbsc-event.mbsc-list-item.mbsc-selected .mbsc-list-item-background {
  background: rgba(0, 0, 0, 0.15);
}
.mbsc-ios.mbsc-event-color {
  background: #5ac8fa;
}
.mbsc-ios.mbsc-eventcalendar-schedule .mbsc-calendar-day {
  background: #f7f7f7;
}
.mbsc-ios.mbsc-schedule-wrapper {
  background: #f7f7f7;
}
.mbsc-ios.mbsc-schedule-header, .mbsc-ios.mbsc-schedule-header-item {
  border-color: #cccccc;
}
.mbsc-ios.mbsc-schedule-header-day-today {
  color: #007aff;
}
.mbsc-ios.mbsc-schedule-header-day.mbsc-hover {
  background: rgba(0, 122, 255, 0.3);
}
.mbsc-ios.mbsc-schedule-header-dayname-curr, .mbsc-ios.mbsc-schedule-header-day.mbsc-selected {
  color: #007aff;
}
.mbsc-ios.mbsc-schedule-header-day.mbsc-selected {
  background: #007aff;
  color: white;
}
.mbsc-ios.mbsc-schedule-header-dayname-curr {
  color: #000000;
}
.mbsc-ios.mbsc-schedule-date-header {
  background: #f7f7f7;
  border-color: #cccccc;
}
.mbsc-ios.mbsc-schedule-grid-wrapper {
  background: white;
}
.mbsc-ios.mbsc-schedule-all-day-wrapper {
  border-color: #cccccc;
  background: white;
}
.mbsc-ios.mbsc-schedule-all-day-text, .mbsc-ios.mbsc-schedule-timezone-label {
  color: #8c8c8c;
}
.mbsc-ios.mbsc-timeline-day::after, .mbsc-ios.mbsc-timeline-slots, .mbsc-ios.mbsc-timeline-slot-header, .mbsc-ios.mbsc-timeline-header-month, .mbsc-ios.mbsc-timeline-header-week, .mbsc-ios.mbsc-timeline-footer-week, .mbsc-ios.mbsc-timeline-header-date, .mbsc-ios.mbsc-timeline-header-column, .mbsc-ios.mbsc-timeline-header, .mbsc-ios.mbsc-timeline-footer-date, .mbsc-ios.mbsc-timeline-footer, .mbsc-ios.mbsc-timeline-footer-column, .mbsc-ios.mbsc-timeline-resource, .mbsc-ios.mbsc-timeline-resource-header-cont, .mbsc-ios.mbsc-timeline-resource-footer-cont, .mbsc-ios.mbsc-timeline-sidebar-resource, .mbsc-ios.mbsc-timeline-sidebar-header-cont, .mbsc-ios.mbsc-timeline-sidebar-footer-cont, .mbsc-ios.mbsc-timeline-column, .mbsc-ios.mbsc-timeline-row, .mbsc-ios.mbsc-schedule-resource-group, .mbsc-ios.mbsc-schedule-resource, .mbsc-ios.mbsc-schedule-all-day-item::after, .mbsc-ios.mbsc-schedule-column, .mbsc-ios.mbsc-schedule-item, .mbsc-ios.mbsc-timeline-row-group::after, .mbsc-ios.mbsc-timeline-row-date {
  border-color: #cccccc;
}
.mbsc-ios.mbsc-timeline-header-column, .mbsc-ios.mbsc-schedule-cursor-time, .mbsc-ios.mbsc-schedule-time {
  color: #8c8c8c;
}
.mbsc-ios.mbsc-schedule-event {
  color: #5ac8fa;
}
.mbsc-ios.mbsc-schedule-event:after {
  background: rgba(255, 255, 255, 0.9);
}
.mbsc-ios.mbsc-schedule-event-title, .mbsc-ios.mbsc-schedule-event-range {
  color: #000;
}
.mbsc-ios.mbsc-schedule-event-active .mbsc-schedule-event-inner, .mbsc-ios.mbsc-schedule-event-dragging .mbsc-schedule-event-inner {
  color: #fff;
}
.mbsc-ios.mbsc-schedule-event-active .mbsc-schedule-event-title,
.mbsc-ios.mbsc-schedule-event-active .mbsc-schedule-event-range, .mbsc-ios.mbsc-schedule-event-dragging .mbsc-schedule-event-title,
.mbsc-ios.mbsc-schedule-event-dragging .mbsc-schedule-event-range {
  color: inherit;
}
.mbsc-ios.mbsc-schedule-time-indicator {
  border-color: #007aff;
}
.mbsc-ios.mbsc-schedule-time-indicator-day:before {
  background: #007aff;
}
.mbsc-ios.mbsc-schedule-time-indicator-time {
  background: rgba(255, 255, 255, 0.8);
}
.mbsc-ios.mbsc-schedule-time-indicator-time {
  color: #007aff;
}
.mbsc-ios.mbsc-schedule-cursor-time {
  background: rgba(255, 255, 255, 0.8);
  color: #007aff;
}
.mbsc-ios.mbsc-schedule-invalid {
  background: rgba(239, 239, 239, 0.75);
  color: #888888;
}
.mbsc-ios.mbsc-timeline-header-bg, .mbsc-ios.mbsc-timeline-header-text, .mbsc-ios.mbsc-timeline-footer-bg, .mbsc-ios.mbsc-timeline-resource-bg, .mbsc-ios.mbsc-timeline-resource-header-cont, .mbsc-ios.mbsc-timeline-resource-footer-cont, .mbsc-ios.mbsc-timeline-sidebar-header-cont, .mbsc-ios.mbsc-timeline-sidebar-footer-cont {
  background: white;
}
.mbsc-ios.mbsc-timeline-header-active {
  color: #fff;
}
.mbsc-ios.mbsc-timeline-header-active::after {
  background-color: #007aff;
}
.mbsc-ios.mbsc-connection {
  stroke: rgba(0, 0, 0, 0.7);
}
.mbsc-ios.mbsc-connection-arrow {
  fill: #000000;
}

.mbsc-ios-dark {
  /* Calendar view */
  /* Agenda view */
  /* Popover events */
  /* Schedule view */
  /* TODO: refactor this without cascade */
  /* Header */
  /* Grid & All-day row */
  /* Event style */
  /* Invalid */
  /* Timeline */
  /* Connections */
}
.mbsc-ios-dark.mbsc-eventcalendar .mbsc-calendar-header,
.mbsc-ios-dark.mbsc-eventcalendar .mbsc-calendar-week-days {
  background: #1c1c1c;
}
.mbsc-ios-dark.mbsc-eventcalendar .mbsc-calendar-day.mbsc-disabled {
  background: #1a1a1a;
}
.mbsc-ios-dark.mbsc-event-list-empty {
  color: #8f8f8f;
}
.mbsc-ios-dark.mbsc-event-day.mbsc-list-header {
  background: #000000;
  border-color: #333333;
  color: #ffffff;
}
.mbsc-ios-dark.mbsc-event.mbsc-list-item {
  background: #000000;
  color: #ffffff;
}
.mbsc-ios-dark.mbsc-event.mbsc-list-item:before, .mbsc-ios-dark.mbsc-event.mbsc-list-item:after {
  border-color: #333333;
}
.mbsc-ios-dark.mbsc-event.mbsc-list-item.mbsc-hover:before {
  background: rgba(255, 255, 255, 0.05);
}
.mbsc-ios-dark.mbsc-event.mbsc-list-item.mbsc-focus .mbsc-list-item-background, .mbsc-ios-dark.mbsc-event.mbsc-list-item.mbsc-selected .mbsc-list-item-background {
  background: rgba(255, 255, 255, 0.15);
}
.mbsc-ios-dark.mbsc-event-color {
  background: #5ac8fa;
}
.mbsc-ios-dark.mbsc-popover-list .mbsc-ios-dark.mbsc-event {
  background: #2b2b2b;
}
.mbsc-ios-dark.mbsc-eventcalendar-schedule .mbsc-calendar-day {
  background: #1c1c1c;
}
.mbsc-ios-dark.mbsc-schedule-wrapper {
  background: #1c1c1c;
}
.mbsc-ios-dark.mbsc-schedule-header, .mbsc-ios-dark.mbsc-schedule-header-item {
  border-color: #333333;
}
.mbsc-ios-dark.mbsc-schedule-header-day-today {
  color: #ff9f0a;
}
.mbsc-ios-dark.mbsc-schedule-header-day.mbsc-hover {
  background: rgba(255, 159, 10, 0.3);
}
.mbsc-ios-dark.mbsc-schedule-header-dayname-curr, .mbsc-ios-dark.mbsc-schedule-header-day.mbsc-selected {
  color: #ff9f0a;
}
.mbsc-ios-dark.mbsc-schedule-header-day.mbsc-selected {
  background: #ff9f0a;
  color: #000000;
}
.mbsc-ios-dark.mbsc-schedule-header-dayname-curr {
  color: #ffffff;
}
.mbsc-ios-dark.mbsc-schedule-date-header {
  background: #1c1c1c;
  border-color: #333333;
}
.mbsc-ios-dark.mbsc-schedule-grid-wrapper {
  background: #000000;
}
.mbsc-ios-dark.mbsc-schedule-all-day-wrapper {
  border-color: #333333;
  background: #000000;
}
.mbsc-ios-dark.mbsc-schedule-all-day-text, .mbsc-ios-dark.mbsc-schedule-timezone-label {
  color: #ffffff;
}
.mbsc-ios-dark.mbsc-timeline-day::after, .mbsc-ios-dark.mbsc-timeline-slots, .mbsc-ios-dark.mbsc-timeline-slot-header, .mbsc-ios-dark.mbsc-timeline-header-month, .mbsc-ios-dark.mbsc-timeline-header-week, .mbsc-ios-dark.mbsc-timeline-footer-week, .mbsc-ios-dark.mbsc-timeline-header-date, .mbsc-ios-dark.mbsc-timeline-header-column, .mbsc-ios-dark.mbsc-timeline-header, .mbsc-ios-dark.mbsc-timeline-footer-date, .mbsc-ios-dark.mbsc-timeline-footer, .mbsc-ios-dark.mbsc-timeline-footer-column, .mbsc-ios-dark.mbsc-timeline-resource, .mbsc-ios-dark.mbsc-timeline-resource-header-cont, .mbsc-ios-dark.mbsc-timeline-resource-footer-cont, .mbsc-ios-dark.mbsc-timeline-sidebar-resource, .mbsc-ios-dark.mbsc-timeline-sidebar-header-cont, .mbsc-ios-dark.mbsc-timeline-sidebar-footer-cont, .mbsc-ios-dark.mbsc-timeline-column, .mbsc-ios-dark.mbsc-timeline-row, .mbsc-ios-dark.mbsc-schedule-resource-group, .mbsc-ios-dark.mbsc-schedule-resource, .mbsc-ios-dark.mbsc-schedule-all-day-item::after, .mbsc-ios-dark.mbsc-schedule-column, .mbsc-ios-dark.mbsc-schedule-item, .mbsc-ios-dark.mbsc-timeline-row-group::after, .mbsc-ios-dark.mbsc-timeline-row-date {
  border-color: #333333;
}
.mbsc-ios-dark.mbsc-timeline-header-column, .mbsc-ios-dark.mbsc-schedule-cursor-time, .mbsc-ios-dark.mbsc-schedule-time {
  color: #ffffff;
}
.mbsc-ios-dark.mbsc-schedule-event {
  color: #5ac8fa;
}
.mbsc-ios-dark.mbsc-schedule-event:after {
  background: rgba(0, 0, 0, 0.9);
}
.mbsc-ios-dark.mbsc-schedule-event-title, .mbsc-ios-dark.mbsc-schedule-event-range {
  color: #fff;
}
.mbsc-ios-dark.mbsc-schedule-event-active .mbsc-schedule-event-inner, .mbsc-ios-dark.mbsc-schedule-event-dragging .mbsc-schedule-event-inner {
  color: #fff;
}
.mbsc-ios-dark.mbsc-schedule-event-active .mbsc-schedule-event-title,
.mbsc-ios-dark.mbsc-schedule-event-active .mbsc-schedule-event-range, .mbsc-ios-dark.mbsc-schedule-event-dragging .mbsc-schedule-event-title,
.mbsc-ios-dark.mbsc-schedule-event-dragging .mbsc-schedule-event-range {
  color: inherit;
}
.mbsc-ios-dark.mbsc-schedule-time-indicator {
  border-color: #ff9f0a;
}
.mbsc-ios-dark.mbsc-schedule-time-indicator-day:before {
  background: #ff9f0a;
}
.mbsc-ios-dark.mbsc-schedule-time-indicator-time {
  background: rgba(0, 0, 0, 0.8);
}
.mbsc-ios-dark.mbsc-schedule-time-indicator-time {
  color: #ff9f0a;
}
.mbsc-ios-dark.mbsc-schedule-cursor-time {
  background: rgba(0, 0, 0, 0.8);
  color: #ff9f0a;
}
.mbsc-ios-dark.mbsc-schedule-invalid {
  background: rgba(26, 26, 26, 0.75);
  color: #787878;
}
.mbsc-ios-dark.mbsc-timeline-header-bg, .mbsc-ios-dark.mbsc-timeline-header-text, .mbsc-ios-dark.mbsc-timeline-footer-bg, .mbsc-ios-dark.mbsc-timeline-resource-bg, .mbsc-ios-dark.mbsc-timeline-resource-header-cont, .mbsc-ios-dark.mbsc-timeline-resource-footer-cont, .mbsc-ios-dark.mbsc-timeline-sidebar-header-cont, .mbsc-ios-dark.mbsc-timeline-sidebar-footer-cont {
  background: #000000;
}
.mbsc-ios-dark.mbsc-timeline-header-active {
  color: #fff;
}
.mbsc-ios-dark.mbsc-timeline-header-active::after {
  background-color: #ff9f0a;
}
.mbsc-ios-dark.mbsc-connection {
  stroke: rgba(255, 255, 255, 0.7);
}
.mbsc-ios-dark.mbsc-connection-arrow {
  fill: #ffffff;
}

.mbsc-eventcalendar {
  height: 100%;
  overflow: hidden;
}

/* Event list */
.mbsc-event-list {
  position: relative;
  z-index: 0;
  margin-top: -1px;
}

.mbsc-event-list-scroll {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.mbsc-event-list-empty {
  text-align: center;
  margin: 3em;
  font-size: 1.5em;
}

.mbsc-event-day {
  position: sticky;
  top: 0;
  z-index: 3;
}

.mbsc-event-color,
.mbsc-event-time {
  flex: 0 0 auto;
}

.mbsc-event-content {
  overflow: hidden;
}

.mbsc-event-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Popover list */
.mbsc-popover-list {
  width: 20em;
  max-width: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

.mbsc-popover-hidden {
  visibility: hidden;
}

.mbsc-calendar-dragging {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  cursor: move;
}

/* External drag element */
.mbsc-draggable {
  user-select: none;
}

.mbsc-drag-clone {
  position: fixed;
  display: table;
  z-index: 99;
  margin: 15px 0 0 0 !important;
  box-shadow: 0 0 transparent, 0 0 transparent, 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  cursor: move;
}
.mbsc-drag-clone:after {
  content: "";
  position: absolute;
  top: -50px;
  bottom: -50px;
  left: -50px;
  right: -50px;
}

/* Fake scroll rules */
.mbsc-schedule-fake-scroll-x,
.mbsc-schedule-fake-scroll-y {
  visibility: hidden;
  flex: none;
}

.mbsc-schedule-fake-scroll-x {
  overflow-x: scroll;
  width: 0;
}

.mbsc-schedule-fake-scroll-y {
  overflow-y: scroll;
  max-height: 20px;
}

.mbsc-ios.mbsc-list-item {
  margin-top: -1px;
  padding: 1em;
}
.mbsc-ios.mbsc-list-item:before, .mbsc-ios.mbsc-list-item:after {
  content: "";
  position: absolute;
  right: 0;
  left: 1em;
}
.mbsc-ios.mbsc-list-item.mbsc-rtl:before, .mbsc-ios.mbsc-list-item.mbsc-rtl:after {
  left: 0;
  right: 1em;
}
.mbsc-ios.mbsc-list-item:before {
  top: 0;
  transition: none;
}
.mbsc-ios.mbsc-list-item:after {
  bottom: 0;
}
.mbsc-ios.mbsc-list-item:first-child:before, .mbsc-ios.mbsc-list-item:last-child:after {
  left: 0;
  right: 0;
}
.mbsc-ios.mbsc-list-item.mbsc-hover, .mbsc-ios.mbsc-list-item.mbsc-active {
  z-index: 3;
}
.mbsc-ios.mbsc-list-item.mbsc-focus, .mbsc-ios.mbsc-list-item.mbsc-selected {
  z-index: 5;
}
.mbsc-ios.mbsc-list-item.mbsc-focus:before, .mbsc-ios.mbsc-list-item.mbsc-focus:after, .mbsc-ios.mbsc-list-item.mbsc-hover:before, .mbsc-ios.mbsc-list-item.mbsc-hover:after, .mbsc-ios.mbsc-list-item.mbsc-active:before, .mbsc-ios.mbsc-list-item.mbsc-active:after, .mbsc-ios.mbsc-list-item.mbsc-selected:before, .mbsc-ios.mbsc-list-item.mbsc-selected:after {
  left: 0;
  right: 0;
}
.mbsc-ios.mbsc-list-header {
  z-index: 4;
  margin-top: -1px;
  padding: 2.333334em 1.333334em 0.5em 1.333334em;
  font-size: 0.75em;
  line-height: 1.5em;
  border-top: 1px solid;
  border-bottom: 1px solid;
  text-transform: uppercase;
}
.mbsc-ios.mbsc-list-header:first-child {
  border-top: 0;
  margin-top: 0;
}
.mbsc-ios.mbsc-list-item-background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0.25em;
  border-radius: 0.5em;
  transition: background-color 0.15s ease-in-out;
  pointer-events: none;
}

.mbsc-ios.mbsc-list-item {
  background: white;
  border-color: #cccccc;
  color: #000000;
}
.mbsc-ios.mbsc-list-item:before, .mbsc-ios.mbsc-list-item:after {
  border-top: 1px solid #cccccc;
}
.mbsc-ios.mbsc-list-header {
  background: #efeff4;
  color: #6e6e72;
  border-color: #cccccc;
}
.mbsc-ios.mbsc-list-item.mbsc-active:before {
  background: rgba(0, 0, 0, 0.15);
}

.mbsc-ios-dark.mbsc-list-item {
  background: #000000;
  border-color: #333333;
  color: #ffffff;
}
.mbsc-ios-dark.mbsc-list-item:before, .mbsc-ios-dark.mbsc-list-item:after {
  border-top: 1px solid #333333;
}
.mbsc-ios-dark.mbsc-list-header {
  background: #1a1a1a;
  color: #8f8f8f;
  border-color: #333333;
}
.mbsc-ios-dark.mbsc-list-item.mbsc-active:before {
  background: rgba(0, 0, 0, 0.15);
}

.mbsc-list {
  display: block;
}

.mbsc-list-header {
  display: flex;
}

.mbsc-list-item {
  position: relative;
  z-index: 2;
  list-style: none;
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
  line-height: 1.25em;
}

.mbsc-list-item-actionable {
  cursor: pointer;
}

.mbsc-list-item:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  transition: background-color 0.2s ease-out;
}

.mbsc-list-item:focus {
  outline: 0;
}

.mbsc-list-item::-moz-focus-inner {
  border: 0;
}

.mbsc-schedule-grid-wrapper {
  overflow: hidden;
  position: relative;
}

.mbsc-timeline-resource-col,
.mbsc-timeline-date-col,
.mbsc-timeline-sidebar-col,
.mbsc-schedule-time-col {
  flex: none;
  width: 4.25em;
  box-sizing: border-box;
}

/* Schedule grid */
.mbsc-timeline-grid-scroll,
.mbsc-schedule-grid-scroll {
  overflow-x: auto;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

/* Borders */
.mbsc-schedule-all-day-item::after,
.mbsc-timeline-day-border::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.mbsc-schedule-all-day-item.mbsc-ltr::after,
.mbsc-timeline-day-border.mbsc-ltr::after {
  left: 100%;
  border-right-style: solid;
  border-right-width: 1px;
}

.mbsc-schedule-all-day-item.mbsc-rtl::after,
.mbsc-timeline-day-border.mbsc-rtl::after {
  right: 100%;
  border-left-style: solid;
  border-left-width: 1px;
}

.mbsc-schedule-column.mbsc-ltr,
.mbsc-timeline-slot-header.mbsc-rtl,
.mbsc-timeline-header-date.mbsc-rtl,
.mbsc-timeline-header-column.mbsc-rtl,
.mbsc-timeline-resource.mbsc-rtl,
.mbsc-timeline-resource-header-cont.mbsc-rtl,
.mbsc-timeline-resource-footer-cont.mbsc-rtl,
.mbsc-timeline-column.mbsc-rtl,
.mbsc-timeline-sidebar-resource.mbsc-ltr,
.mbsc-timeline-sidebar-header-cont.mbsc-ltr,
.mbsc-timeline-sidebar-footer-cont.mbsc-ltr,
.mbsc-timeline-footer-date.mbsc-rtl,
.mbsc-timeline-row-date.mbsc-rtl {
  border-left-style: solid;
  border-left-width: 1px;
}

.mbsc-schedule-column.mbsc-rtl,
.mbsc-timeline-slot-header.mbsc-ltr,
.mbsc-timeline-header-date.mbsc-ltr,
.mbsc-timeline-header-column.mbsc-ltr,
.mbsc-timeline-resource.mbsc-ltr,
.mbsc-timeline-resource-header-cont.mbsc-ltr,
.mbsc-timeline-resource-footer-cont.mbsc-ltr,
.mbsc-timeline-column.mbsc-ltr,
.mbsc-timeline-sidebar-resource.mbsc-rtl,
.mbsc-timeline-sidebar-header-cont.mbsc-rtl,
.mbsc-timeline-sidebar-footer-cont.mbsc-rtl,
.mbsc-timeline-footer-date.mbsc-ltr,
.mbsc-timeline-row-date.mbsc-ltr {
  border-right-style: solid;
  border-right-width: 1px;
}

/* Schedule event */
.mbsc-schedule-event:focus {
  outline: none;
}

.mbsc-schedule-event {
  position: absolute;
  padding: 1px 0;
  box-sizing: border-box;
  min-height: 0.75em;
  z-index: 1;
}

.mbsc-schedule-event.mbsc-ltr {
  left: 0;
}

.mbsc-schedule-event.mbsc-rtl {
  right: 0;
}

.mbsc-schedule-event-hover {
  cursor: pointer;
}

.mbsc-schedule-event-inner {
  position: relative;
  max-height: 100%;
  margin: 0 0.2em;
  line-height: 1.5em;
  font-size: 0.625em;
  padding: 0.5em;
  box-sizing: border-box;
  overflow: hidden;
}

.mbsc-schedule-event-small-height.mbsc-schedule-event-inner {
  padding-top: 0;
  padding-bottom: 0;
  line-height: 1.2em;
  margin-top: -1px;
}

.mbsc-schedule-event-small-height.mbsc-schedule-event-inner .mbsc-schedule-event-title {
  font-size: 1em;
}

.mbsc-schedule-event-background {
  position: absolute;
  top: -0.5em;
  left: 1px;
  right: 1px;
  bottom: -0.5em;
  background: currentColor;
}

.mbsc-schedule-event-start .mbsc-schedule-event-background {
  top: 1px;
}

.mbsc-schedule-event-end .mbsc-schedule-event-background {
  bottom: 1px;
}

.mbsc-schedule-event-range {
  font-size: 0.8em;
}

.mbsc-schedule-event-all-day {
  position: relative;
  display: block;
  padding: 0 0.25em;
  margin: 0.125em 0;
  min-height: 0;
  box-sizing: border-box;
  z-index: 2;
}

.mbsc-schedule-event-all-day-placeholder {
  z-index: -1;
}

.mbsc-schedule-event-all-day-inner {
  position: relative;
  height: 1.6em;
  line-height: 1.6em;
  margin: 0;
  padding: 0 0.3em;
  font-size: 0.625em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.mbsc-schedule-event-all-day-background.mbsc-schedule-event-background {
  top: 0;
  bottom: 0;
}

/* Event Drag & drop */
.mbsc-schedule-event-dragging {
  position: absolute;
  top: 0;
  z-index: 3;
}

.mbsc-schedule-event-inactive {
  opacity: 0.5;
}

.mbsc-schedule-event-hidden {
  visibility: hidden;
}

/* Event resize handles */
.mbsc-schedule-event-resize {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  height: 0.25em;
  z-index: 1;
  cursor: ns-resize;
}

.mbsc-schedule-event-resize-start {
  top: 0;
}

.mbsc-schedule-event-resize-end {
  bottom: 0;
}

.mbsc-schedule-event-resize-start:after {
  top: -0.25em;
  right: 0.5em;
}

.mbsc-schedule-event-resize-end:after {
  bottom: -0.25em;
  left: 0.5em;
}

.mbsc-schedule-event-resize:after {
  content: "";
  position: absolute;
  margin: 1px 0;
  width: 0.5em;
  height: 0.5em;
  background: #fff;
  border-radius: 50%;
  border: 1px solid currentColor;
  box-sizing: border-box;
}

.mbsc-schedule-event-resize-start-touch:before,
.mbsc-schedule-event-resize-end-touch:before {
  content: "";
  position: absolute;
  margin: 1px 0;
  width: 1.5em;
  height: 1em;
}

.mbsc-schedule-event-resize-start-touch:before {
  top: -0.5em;
  right: 0;
}

.mbsc-schedule-event-resize-end-touch:before {
  bottom: -0.5em;
  left: 0;
}

.mbsc-schedule-event-dragging .mbsc-schedule-event-resize,
.mbsc-schedule-event-hover .mbsc-schedule-event-resize {
  display: block;
}

.mbsc-schedule-drag-clone {
  max-width: 200px;
  max-height: 200px;
  opacity: 1;
}
.mbsc-schedule-drag-clone .mbsc-schedule-event-resize {
  display: none;
}

/* Blocking and colored date styles */
.mbsc-schedule-invalid,
.mbsc-schedule-color {
  position: absolute;
  top: -0.5em;
  bottom: -0.5em;
  left: 0;
  right: 0;
  box-sizing: content-box;
  z-index: 0;
  overflow: hidden;
}

.mbsc-schedule-invalid-all-day,
.mbsc-schedule-color-all-day {
  top: 0;
  bottom: 0;
  z-index: -1;
}

.mbsc-schedule-invalid-start,
.mbsc-schedule-color-start {
  padding-top: 0.5em;
  margin-top: -0.5em;
}

.mbsc-schedule-invalid-end,
.mbsc-schedule-color-end {
  padding-bottom: 0.5em;
}

.mbsc-schedule-invalid-text,
.mbsc-schedule-color-text {
  padding: 0.375em;
  font-size: 0.75em;
}

.mbsc-schedule-color-text {
  opacity: 0.8;
}

/* Time indicator */
.mbsc-schedule-time-indicator {
  position: absolute;
  z-index: 2;
  pointer-events: none;
}

.mbsc-schedule-time-indicator-x {
  border-bottom: 1px solid;
}

.mbsc-schedule-time-indicator-x.mbsc-ltr {
  left: 4.25em;
  right: 0;
}

.mbsc-schedule-time-indicator-x.mbsc-rtl {
  right: 4.25em;
  left: 0;
}

.mbsc-schedule-time-indicator-y {
  border-left: 1px solid;
  top: 2em;
  bottom: 0;
  margin-top: -0.25em;
}
.mbsc-schedule-time-indicator-y.mbsc-ltr {
  margin-left: -1px;
}
.mbsc-schedule-time-indicator-y.mbsc-rtl {
  margin-right: -1px;
}

.mbsc-schedule-time-indicator-day {
  position: absolute;
  width: 14.285714%;
}

.mbsc-schedule-time-indicator-day:before {
  content: "";
  position: absolute;
  width: 0.4375em;
  height: 0.4375em;
  margin-top: -0.1875em;
  margin-left: -0.1875em;
  margin-right: -0.1875em;
  border-radius: 50%;
  z-index: 1;
}

.mbsc-schedule-time-indicator-day.mbsc-ltr:before {
  left: 0;
}

.mbsc-schedule-time-indicator-day.mbsc-rtl:before {
  right: 0;
}

.mbsc-schedule-time-indicator-time {
  position: absolute;
  top: -1em;
  font-size: 0.625em;
  line-height: 2em;
  margin-top: 1px;
  padding: 0 0.8em;
  white-space: nowrap;
}

.mbsc-schedule-time-indicator-time-y {
  top: -1.8em;
  padding: 0 0.5em;
  margin-left: -0.5em;
  margin-right: -0.5em;
  line-height: 1.6em;
}

.mbsc-schedule-time-indicator-time-x.mbsc-ltr,
.mbsc-schedule-time-indicator-time-y.mbsc-rtl {
  right: 100%;
}

.mbsc-schedule-time-indicator-time-x.mbsc-rtl,
.mbsc-schedule-time-indicator-time-y.mbsc-ltr {
  left: 100%;
}

/* Cursor indicator */
.mbsc-schedule-cursor-time {
  position: absolute;
  z-index: 3;
  font-size: 0.625em;
  line-height: 2em;
  white-space: nowrap;
  box-sizing: border-box;
}

.mbsc-schedule-cursor-time-x {
  margin-top: -1em;
}
.mbsc-schedule-cursor-time-x.mbsc-ltr {
  text-align: right;
}
.mbsc-schedule-cursor-time-x.mbsc-rtl {
  text-align: left;
}

.mbsc-schedule-cursor-time-y {
  padding: 0 0.5em;
  top: 0.9em;
}

@supports (overflow: clip) {
  .mbsc-schedule-event-inner {
    position: sticky;
    top: 0;
  }
}
.mbsc-schedule-wrapper {
  overflow: auto;
}

/* Resource groups */
.mbsc-schedule-resource-group {
  box-sizing: border-box;
  flex-basis: 16em;
}

.mbsc-schedule-resource-group:first-child:last-child {
  flex-basis: 0;
}

.mbsc-schedule-resource-group.mbsc-ltr {
  border-left-width: 2px;
  border-left-style: solid;
}

.mbsc-schedule-resource-group.mbsc-rtl {
  border-right-width: 2px;
  border-right-style: solid;
}

.mbsc-schedule-resource-group:first-child {
  border: 0;
}

/* Schedule header */
.mbsc-schedule-date-header-text {
  line-height: 1.5em;
  text-align: center;
}

.mbsc-schedule-header {
  overflow: hidden;
}

.mbsc-schedule-all-day-group-wrapper,
.mbsc-schedule-header-wrapper {
  min-width: 0;
  overflow: hidden;
}

.mbsc-schedule-header-item {
  display: block;
  position: relative;
  text-align: center;
  line-height: 1.25em;
}

.mbsc-schedule-header-item.mbsc-hover {
  cursor: pointer;
}

.mbsc-schedule-header-dayname {
  font-size: 0.75em;
}

.mbsc-schedule-header-day {
  font-size: 1.125em;
}

/* Schedule column width for: header item, header resource, all-day item, schedule column  */
.mbsc-schedule-col-width {
  box-sizing: border-box;
  width: 2.5em;
}

.mbsc-schedule-wrapper-multi .mbsc-schedule-col-width {
  width: 6.25em;
}

/* Resource title */
.mbsc-schedule-resource {
  overflow: hidden;
}

.mbsc-schedule-resource-title {
  padding: 0.5em;
  font-size: 0.875em;
  font-weight: 600;
  text-align: center;
}

/* Schedule all day event container */
.mbsc-schedule-all-day-cont {
  position: relative;
}

.mbsc-schedule-all-day-wrapper {
  position: relative;
  max-height: 7.8125em;
  overflow-x: hidden;
  overflow-y: scroll;
}

.mbsc-schedule-all-day-wrapper-shadow:after {
  content: "";
  box-shadow: inset 0 0.5em 0.25em -0.5em rgba(48, 48, 48, 0.5);
  height: 4px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -4px;
  z-index: 4;
  pointer-events: none;
}

.mbsc-schedule-all-day-item {
  position: relative;
}

.mbsc-schedule-all-day-item:first-child,
.mbsc-schedule-column:first-child {
  border: 0;
}

.mbsc-schedule-timezone-labels {
  position: absolute;
  bottom: 0;
  z-index: 1;
  font-weight: bold;
}

.mbsc-schedule-timezone-label {
  font-size: 0.75em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mbsc-schedule-time-indicator-tz {
  width: 5.666667em;
}

.mbsc-schedule-time-indicator-tz.mbsc-ltr,
.mbsc-schedule-timezone-label.mbsc-ltr {
  text-align: right;
}

.mbsc-schedule-time-indicator-tz.mbsc-rtl,
.mbsc-schedule-timezone-label.mbsc-rtl {
  text-align: left;
}

/* Time section */
.mbsc-schedule-time-wrapper {
  position: relative;
  width: 4.25em;
  height: 3.125em;
  box-sizing: border-box;
  pointer-events: auto;
}

.mbsc-schedule-time {
  position: relative;
  line-height: 2em;
  top: -1em;
  font-size: 0.75em;
}

.mbsc-schedule-time-end {
  position: absolute;
  top: auto;
  bottom: -1em;
  left: 0;
  right: 0;
}

.mbsc-schedule-time-col.mbsc-ltr {
  text-align: right;
}

.mbsc-schedule-time-col.mbsc-rtl {
  text-align: left;
}

.mbsc-schedule-time-cont-pos {
  position: relative;
}

/* Grid */
.mbsc-schedule-time-cont-inner {
  position: absolute;
  width: 100%;
  z-index: 2;
  pointer-events: none;
  height: 100%;
  box-sizing: border-box;
  padding: 0.5em 0;
}

.mbsc-schedule-column {
  padding: 0.5em 0;
}

.mbsc-schedule-column-inner {
  position: relative;
}

.mbsc-schedule-all-day-item,
.mbsc-schedule-item {
  box-sizing: border-box;
}

.mbsc-schedule-item {
  height: 3.125em;
  position: relative;
  border-top: 1px solid;
}

.mbsc-schedule-item-last {
  border-bottom: 1px solid;
}

/* Event spacing */
.mbsc-schedule-events {
  position: absolute;
  height: 100%;
}

.mbsc-schedule-events.mbsc-ltr {
  left: 0;
  right: 0.5em;
}

.mbsc-schedule-events.mbsc-rtl {
  right: 0;
  left: 0.5em;
}

/* All day events */
.mbsc-schedule-event-all-day {
  /* Increase touch area for resize dots */
}
.mbsc-schedule-event-all-day .mbsc-schedule-event-resize {
  width: 0.25em;
  height: 100%;
  cursor: ew-resize;
}
.mbsc-schedule-event-all-day .mbsc-schedule-event-resize:after {
  top: 50%;
  right: auto;
  left: auto;
  margin-top: -0.25em;
}
.mbsc-schedule-event-all-day .mbsc-schedule-event-resize-start.mbsc-ltr,
.mbsc-schedule-event-all-day .mbsc-schedule-event-resize-end.mbsc-rtl {
  right: auto;
}
.mbsc-schedule-event-all-day .mbsc-schedule-event-resize-end.mbsc-ltr,
.mbsc-schedule-event-all-day .mbsc-schedule-event-resize-start.mbsc-rtl {
  left: auto;
}
.mbsc-schedule-event-all-day .mbsc-schedule-event-resize-start.mbsc-ltr:after,
.mbsc-schedule-event-all-day .mbsc-schedule-event-resize-end.mbsc-rtl:after {
  right: -0.25em;
}
.mbsc-schedule-event-all-day .mbsc-schedule-event-resize-end.mbsc-ltr:after,
.mbsc-schedule-event-all-day .mbsc-schedule-event-resize-start.mbsc-rtl:after {
  left: -0.25em;
}
.mbsc-schedule-event-all-day .mbsc-schedule-event-resize-start-touch:before {
  height: 1.5em;
  top: 50%;
  left: -0.75em;
  right: auto;
  margin-top: -0.75em;
}
.mbsc-schedule-event-all-day .mbsc-schedule-event-resize-end-touch:before {
  height: 1.5em;
  top: 50%;
  left: auto;
  right: -0.75em;
  margin-top: -0.75em;
}

/* Large screen */
.mbsc-calendar-width-md .mbsc-schedule-event-title {
  font-size: 1.2em;
}
.mbsc-calendar-width-md .mbsc-schedule-event-range {
  font-size: 1em;
}
.mbsc-calendar-width-md .mbsc-schedule-event-all-day-title {
  font-size: 1em;
}
.mbsc-calendar-width-md .mbsc-schedule-event-all-day-inner {
  font-size: 0.75em;
  height: 1.5em;
  line-height: 1.5em;
  padding: 0 0.333334em;
}

.mbsc-timeline {
  overflow: hidden;
  position: relative;
  z-index: 0;
}
.mbsc-timeline.mbsc-ltr {
  margin-right: -1px;
}
.mbsc-timeline.mbsc-rtl {
  margin-left: -1px;
}

/* Resource column */
.mbsc-timeline-resource-col,
.mbsc-timeline-sidebar-col {
  width: 12em;
}

.mbsc-timeline-resources,
.mbsc-timeline-sidebar {
  position: absolute;
  top: 0;
  bottom: 15px;
  z-index: 4;
  overflow: hidden;
}

.mbsc-timeline-resources.mbsc-ltr,
.mbsc-timeline-sidebar.mbsc-rtl {
  left: 0;
}

.mbsc-timeline-resources.mbsc-rtl,
.mbsc-timeline-sidebar.mbsc-ltr {
  right: 0;
}

.mbsc-timeline-resource-header-cont,
.mbsc-timeline-resource-footer-cont,
.mbsc-timeline-sidebar-header-cont,
.mbsc-timeline-sidebar-footer-cont {
  position: relative;
  z-index: 5;
  overflow: hidden;
  pointer-events: auto;
}

.mbsc-timeline-resource,
.mbsc-timeline-sidebar-resource {
  box-sizing: border-box;
  overflow: hidden;
}

/* Hierarchy */
.mbsc-timeline-parent {
  background: rgba(230, 230, 230, 0.25);
}

.mbsc-timeline-resource-icon {
  font-size: 0.875em;
  font-weight: 600;
  margin: 0.357143em 0;
  cursor: pointer;
  box-sizing: content-box;
}

.mbsc-timeline-resource-icon.mbsc-ltr {
  margin-left: 0.5em;
}

.mbsc-timeline-resource-icon.mbsc-rtl {
  margin-right: 0.5em;
}

.mbsc-timeline-resource-header,
.mbsc-timeline-resource-footer,
.mbsc-timeline-resource-title,
.mbsc-timeline-sidebar-header,
.mbsc-timeline-sidebar-resource-title,
.mbsc-timeline-sidebar-footer {
  font-size: 0.875em;
  font-weight: 600;
  padding: 0.5em;
}

/* Header and footer */
.mbsc-timeline-header,
.mbsc-timeline-footer {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 3;
  overflow: hidden;
}

.mbsc-timeline-header {
  top: 0;
}

.mbsc-timeline-footer {
  bottom: 0;
  margin-top: -1px;
}

.mbsc-timeline-header-bg,
.mbsc-timeline-footer-bg {
  position: relative;
}

.mbsc-timeline-day {
  position: relative;
  min-width: 9em;
  box-sizing: border-box;
}

.mbsc-timeline-day-month {
  min-width: 0;
}

.mbsc-timeline-header-column.mbsc-timeline-no-border,
.mbsc-timeline-column.mbsc-timeline-no-border {
  border-left: 0;
  border-right: 0;
}

.mbsc-timeline-month {
  min-width: 0;
  position: relative;
}

.mbsc-timeline-header-month,
.mbsc-timeline-header-week {
  min-height: 1.625em;
  box-sizing: border-box;
}

.mbsc-timeline-footer-date,
.mbsc-timeline-header-date {
  min-height: 1.625em;
  box-sizing: border-box;
}

.mbsc-timeline-header-month-last.mbsc-ltr,
.mbsc-timeline-header-week-last.mbsc-ltr,
.mbsc-timeline-footer-week-last.mbsc-ltr {
  border-right-width: 1px;
  border-right-style: solid;
}

.mbsc-timeline-header-month-last.mbsc-rtl,
.mbsc-timeline-header-week-last.mbsc-rtl {
  border-left-width: 1px;
  border-left-style: solid;
}

.mbsc-timeline-header-month-text,
.mbsc-timeline-header-date-text,
.mbsc-timeline-header-week-text,
.mbsc-timeline-footer-month-text,
.mbsc-timeline-footer-week-text {
  font-size: 0.875em;
  padding: 0 0.285714em;
  line-height: 1.785715em;
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.mbsc-timeline-header-month-text {
  font-weight: bold;
}

.mbsc-timeline-header-date-cont,
.mbsc-timeline-header-week-cont,
.mbsc-timeline-header-month-cont,
.mbsc-timeline-header-year-cont,
.mbsc-timeline-header-date-text,
.mbsc-timeline-header-month-text-last,
.mbsc-timeline-header-week-text-last {
  max-width: 100%;
  overflow: hidden;
}

.mbsc-timeline-header-month-cont,
.mbsc-timeline-header-week-cont {
  white-space: nowrap;
}

.mbsc-timeline-header-active {
  position: relative;
  z-index: 0;
  padding: 0 0.714286em;
}
.mbsc-timeline-header-active::after {
  content: "";
  position: absolute;
  top: 0.142857em;
  left: 0.142857em;
  right: 0.142857em;
  bottom: 0.142857em;
  border-radius: 0.75em;
  z-index: -1;
}

.mbsc-timeline-header-column,
.mbsc-timeline-footer-column {
  min-height: 1.625em;
  overflow: hidden;
}

.mbsc-timeline-header-time,
.mbsc-timeline-footer-time {
  padding: 0 0.5em;
  font-size: 0.625em;
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 2.6em;
}

.mbsc-timeline-time-indicator-cont {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 100%;
  overflow: hidden;
  padding-top: 2em;
  margin-top: -2em;
  pointer-events: none;
  box-sizing: content-box;
}

/* Sticky header */
.mbsc-timeline-header-sticky {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 6;
  pointer-events: none;
}
.mbsc-timeline-header-sticky .mbsc-timeline-header-date {
  border-left: 0;
  border-right: 0;
}

/* Sticky footer */
.mbsc-timeline-footer-sticky {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 6;
  pointer-events: none;
}

/* Grid */
.mbsc-timeline-column,
.mbsc-timeline-header-column {
  width: 4.625em;
  box-sizing: border-box;
  position: relative;
}

.mbsc-timeline-grid-scroll {
  overflow-y: auto;
  -webkit-overflow-scrolling: auto;
}

.mbsc-timeline-grid {
  position: relative;
  overflow: hidden;
}

.mbsc-timeline-row {
  height: 3.25em;
  position: relative;
  box-sizing: border-box;
}

.mbsc-timeline-hidden {
  visibility: hidden;
  position: absolute;
  left: 0;
  right: 0;
  height: 0;
  overflow: hidden;
}

.mbsc-timeline-row-group {
  position: relative;
}

.mbsc-timeline-row-group::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  z-index: 1;
}

.mbsc-timeline-row-group:last-child::after {
  display: none;
}

.mbsc-timeline-row-date {
  box-sizing: border-box;
}
.mbsc-timeline-row-date .mbsc-timeline-header-date-text {
  white-space: normal;
  line-height: 1.285715em;
  padding-top: 0.285715em;
  padding-bottom: 0.285715em;
}

.mbsc-timeline-row-resource-col {
  overflow: hidden;
}

.mbsc-timeline-row-date-col {
  width: 5.5em;
}

.mbsc-timeline-date-col {
  width: 8.75em;
}

.mbsc-timeline-row-gutter {
  height: 1em;
}

.mbsc-timeline-resource,
.mbsc-timeline-resource-header-cont,
.mbsc-timeline-sidebar-resource,
.mbsc-timeline-sidebar-header-cont,
.mbsc-timeline-header-date,
.mbsc-timeline-header-month,
.mbsc-timeline-header-week,
.mbsc-timeline-header-column,
.mbsc-timeline-row,
.mbsc-timeline-row-date,
.mbsc-timeline-row-group::after {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.mbsc-timeline-footer-date,
.mbsc-timeline-footer-week,
.mbsc-timeline-footer-column,
.mbsc-timeline-resource-footer-cont,
.mbsc-timeline-sidebar-footer-cont {
  border-top-style: solid;
  border-top-width: 1px;
}

/* Events */
.mbsc-timeline-events {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 1em;
}

.mbsc-timeline-event {
  z-index: 2;
  min-width: 0.75em;
  padding: 6px 0 0 0;
}
.mbsc-timeline-event.mbsc-schedule-event-all-day {
  padding-left: 0.25em;
  padding-right: 0.25em;
}
.mbsc-timeline-event .mbsc-schedule-event-all-day-inner {
  font-size: 0.75em;
  height: 1.5em;
  line-height: 1.5em;
  padding: 0 0.333334em;
}
.mbsc-timeline-event .mbsc-schedule-event-title,
.mbsc-timeline-event .mbsc-schedule-event-range {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.mbsc-timeline-event-background {
  margin-top: 6px;
  top: 0;
  bottom: 0;
}

.mbsc-timeline-event-resize {
  width: 0.25em;
  top: 6px;
  bottom: 0;
  right: auto;
  left: auto;
  cursor: ew-resize;
}
.mbsc-timeline-event-resize.mbsc-schedule-event-resize-start.mbsc-ltr, .mbsc-timeline-event-resize.mbsc-schedule-event-resize-end.mbsc-rtl {
  height: auto;
  left: 0;
}
.mbsc-timeline-event-resize.mbsc-schedule-event-resize-end.mbsc-ltr, .mbsc-timeline-event-resize.mbsc-schedule-event-resize-start.mbsc-rtl {
  height: auto;
  right: 0;
}
.mbsc-timeline-event-resize.mbsc-schedule-event-resize-end:after, .mbsc-timeline-event-resize.mbsc-schedule-event-resize-start:after {
  top: 50%;
  margin-top: -0.25em;
}
.mbsc-timeline-event-resize.mbsc-schedule-event-resize-end:after {
  left: -0.125em;
}
.mbsc-timeline-event-resize.mbsc-schedule-event-resize-start:after {
  right: -0.125em;
}
.mbsc-timeline-event-resize.mbsc-schedule-event-resize-start-touch:before, .mbsc-timeline-event-resize.mbsc-schedule-event-resize-end-touch:before {
  height: 1.5em;
  top: 50%;
  left: 50%;
  margin: -0.75em 0 0 -0.75em;
}

.mbsc-timeline-event-listing {
  position: absolute;
  margin: 0;
}
.mbsc-timeline-event-listing.mbsc-timeline-event-dragging {
  position: absolute;
  top: 0;
}

.mbsc-timeline-event-slot {
  position: relative;
  left: 0;
  right: 0;
  margin: 0 2px;
}

/* Invalid, color */
.mbsc-timeline-color,
.mbsc-timeline-invalid {
  top: 0;
  bottom: 0;
  padding: 0;
  margin: 0;
}

/* No time displayed */
.mbsc-timeline-header-column.mbsc-timeline-no-height,
.mbsc-timeline-footer-column.mbsc-timeline-no-height {
  border: 0;
  height: 0;
  min-height: 0;
}

/* Connections */
.mbsc-connections {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 0;
}

.mbsc-connection {
  stroke-width: 1.5px;
  fill: none;
  pointer-events: visibleStroke;
}

/* Slots */
.mbsc-timeline-slots {
  height: 1.625em;
  box-sizing: border-box;
  border-bottom: 1px solid;
}

.mbsc-timeline-slot {
  position: relative;
  width: 8em;
  box-sizing: border-box;
}

.mbsc-timeline-slot-title {
  font-size: 0.875em;
  font-weight: 600;
  line-height: 1.785715em;
  padding: 0 0.285714em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Sticky positioning */
.mbsc-has-sticky {
  /* Needed for Safari, otherwise the scrollbar appears behind the sticky elements */
}
.mbsc-has-sticky .mbsc-timeline-grid-scroll.mbsc-ltr {
  position: relative;
}
.mbsc-has-sticky .mbsc-timeline-header,
.mbsc-has-sticky .mbsc-timeline-footer {
  position: sticky;
  left: auto;
  right: auto;
  overflow: visible;
}
.mbsc-has-sticky .mbsc-timeline-resources.mbsc-ltr,
.mbsc-has-sticky .mbsc-timeline-sidebar.mbsc-ltr {
  position: sticky;
  top: auto;
  bottom: auto;
  overflow: visible;
}

@supports (overflow: clip) {
  .mbsc-timeline-grid {
    overflow: clip;
  }
  .mbsc-timeline .mbsc-schedule-event-inner {
    display: inline-block;
    max-width: 100%;
    vertical-align: top;
  }
  .mbsc-timeline.mbsc-ltr .mbsc-schedule-event-inner {
    left: 192px;
  }
  .mbsc-timeline.mbsc-rtl .mbsc-schedule-event-inner {
    right: 192px;
  }
  .mbsc-timeline-no-resource.mbsc-ltr .mbsc-schedule-event-inner {
    left: 140px;
  }
  .mbsc-timeline-no-resource.mbsc-rtl .mbsc-schedule-event-inner {
    right: 140px;
  }
  .mbsc-timeline-event-slot .mbsc-schedule-event-inner {
    position: relative;
    display: block;
  }
  .mbsc-timeline .mbsc-timeline-event-slot .mbsc-schedule-event-inner,
  .mbsc-timeline.mbsc-timeline-no-rows .mbsc-schedule-event-inner {
    left: 0;
    right: 0;
  }
}
.mbsc-ios {
  /* Range Controls */
}
.mbsc-ios.mbsc-picker .mbsc-popup-overlay-top,
.mbsc-ios.mbsc-picker .mbsc-popup-overlay-bottom {
  background: none;
}
.mbsc-ios.mbsc-datepicker-inline {
  border-top: 1px solid;
  border-bottom: 1px solid;
  margin-top: -1px;
  margin-bottom: -1px;
  z-index: 2;
  position: relative;
}
.mbsc-ios.mbsc-datepicker .mbsc-calendar-grid, .mbsc-ios.mbsc-datepicker .mbsc-calendar-cell, .mbsc-ios.mbsc-datepicker .mbsc-calendar-cell-inner {
  border-color: transparent;
}
.mbsc-ios.mbsc-datepicker .mbsc-selected .mbsc-calendar-day-text, .mbsc-ios.mbsc-datepicker .mbsc-highlighted .mbsc-calendar-day-text, .mbsc-ios.mbsc-datepicker .mbsc-range-hover-start .mbsc-calendar-day-text, .mbsc-ios.mbsc-datepicker .mbsc-range-hover-end .mbsc-calendar-day-text, .mbsc-ios.mbsc-datepicker .mbsc-hover .mbsc-calendar-day-text {
  width: 1.444445em;
  height: 1.444445em;
  margin: 0.16667em;
  font-size: 1.125em;
  line-height: 1.444445em;
}
.mbsc-ios.mbsc-picker-header {
  line-height: 1.25em;
  padding: 0.75em 4.375em;
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid;
}
.mbsc-ios.mbsc-range-control-wrapper {
  padding: 0 0.75em;
  overflow: hidden;
}
.mbsc-ios.mbsc-range-control-wrapper .mbsc-segmented {
  width: 17em;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  box-sizing: border-box;
}
.mbsc-ios.mbsc-datepicker-control-calendar.mbsc-datepicker-control-timegrid .mbsc-range-control-wrapper .mbsc-segmented, .mbsc-ios.mbsc-datepicker-control-calendar.mbsc-datepicker-control-time .mbsc-range-control-wrapper .mbsc-segmented, .mbsc-ios.mbsc-datepicker-control-date.mbsc-datepicker-control-time .mbsc-range-control-wrapper .mbsc-segmented, .mbsc-ios.mbsc-datepicker-control-datetime .mbsc-range-control-wrapper .mbsc-segmented {
  width: 25em;
}
.mbsc-ios.mbsc-range-control-wrapper .mbsc-segmented-button {
  display: block;
  padding: 0 0.5em;
}
.mbsc-ios.mbsc-range-control-wrapper .mbsc-range-value-nonempty .mbsc-segmented-button {
  padding: 0 2.0625em 0 0.5em;
}
.mbsc-ios.mbsc-range-control-wrapper .mbsc-range-value-nonempty .mbsc-segmented-button.mbsc-rtl {
  padding: 0 0.5em 0 2.0625em;
}
.mbsc-ios.mbsc-range-control-label, .mbsc-ios.mbsc-range-control-value {
  text-align: left;
  line-height: 1.538462em;
}
.mbsc-ios.mbsc-range-control-label.active, .mbsc-ios.mbsc-range-control-value.active {
  font-weight: 600;
}
.mbsc-ios.mbsc-range-control-label.mbsc-rtl, .mbsc-ios.mbsc-range-control-value.mbsc-rtl {
  text-align: right;
}
.mbsc-ios.mbsc-range-control-label {
  padding: 0.615385em 0.615385em 0 0.615385em;
}
.mbsc-ios.mbsc-range-control-value {
  padding: 0 0.615385em 0.615385em 0.615385em;
  text-overflow: ellipsis;
  overflow: hidden;
}
.mbsc-ios.mbsc-range-control-value.active {
  padding: 0 0.571429em 0.571429em 0.571429em;
  font-size: 1.076923em;
  line-height: 1.428572em;
}
.mbsc-ios.mbsc-range-label-clear {
  margin-top: -0.692308em;
  width: 1.307693em;
  height: 1.307693em;
}
.mbsc-ios.mbsc-range-label-clear.mbsc-ltr {
  right: 1em;
}
.mbsc-ios.mbsc-range-label-clear.mbsc-rtl {
  left: 1em;
}

.mbsc-ios {
  /* Range Control */
}
.mbsc-ios.mbsc-picker .mbsc-popup-arrow,
.mbsc-ios.mbsc-picker .mbsc-popup-body {
  background: white;
}
.mbsc-ios.mbsc-picker-header {
  border-color: #cccccc;
}
.mbsc-ios.mbsc-datepicker .mbsc-calendar,
.mbsc-ios.mbsc-datepicker .mbsc-calendar-slide,
.mbsc-ios.mbsc-datepicker .mbsc-calendar-cell {
  background: white;
}
.mbsc-ios.mbsc-datepicker-top.mbsc-datepicker-control-date, .mbsc-ios.mbsc-datepicker-bottom.mbsc-datepicker-control-date {
  background: #d1d5db;
}
.mbsc-ios.mbsc-datepicker-inline {
  background: white;
  border-color: #cccccc;
  color: #000000;
}
.mbsc-ios.mbsc-range-control-value.active {
  color: #007aff;
}
.mbsc-ios.mbsc-range-control-text-empty, .mbsc-ios.mbsc-range-control-text-empty.active {
  color: rgba(0, 0, 0, 0.4);
}
.mbsc-ios.mbsc-range-label-clear {
  color: #cccccc;
}
.mbsc-ios.mbsc-range-label-clear:hover {
  color: #b2b2b2;
}

.mbsc-ios-dark {
  /* Range Control */
}
.mbsc-ios-dark.mbsc-picker .mbsc-popup-arrow,
.mbsc-ios-dark.mbsc-picker .mbsc-popup-body {
  background: #1c1c1c;
}
.mbsc-ios-dark.mbsc-picker-header {
  border-color: #333333;
}
.mbsc-ios-dark.mbsc-datepicker .mbsc-calendar,
.mbsc-ios-dark.mbsc-datepicker .mbsc-calendar-slide,
.mbsc-ios-dark.mbsc-datepicker .mbsc-calendar-cell {
  background: #1c1c1c;
}
.mbsc-ios-dark.mbsc-datepicker-top.mbsc-datepicker-control-date, .mbsc-ios-dark.mbsc-datepicker-bottom.mbsc-datepicker-control-date {
  background: #1c1c1c;
}
.mbsc-ios-dark.mbsc-datepicker-inline {
  background: #1c1c1c;
  border-color: #333333;
  color: #ffffff;
}
.mbsc-ios-dark.mbsc-range-control-value.active {
  color: #ff9f0a;
}
.mbsc-ios-dark.mbsc-range-control-text-empty, .mbsc-ios-dark.mbsc-range-control-text-empty.active {
  color: rgba(255, 255, 255, 0.4);
}
.mbsc-ios-dark.mbsc-range-label-clear {
  color: #333333;
}
.mbsc-ios-dark.mbsc-range-label-clear:hover {
  color: #4d4d4d;
}

/* Needed for angular */
mbsc-datetime {
  display: block;
  width: 100%;
}

.mbsc-datepicker-inline {
  height: 100%;
}

.mbsc-datepicker .mbsc-calendar {
  height: 100%;
  max-width: 100%;
  padding-bottom: 0.5em;
  box-sizing: border-box;
  display: block;
}

.mbsc-datepicker .mbsc-calendar-header .mbsc-calendar-week-days {
  padding: 0 0.5em;
}

/* Start/end controls */
.mbsc-range-control-wrapper .mbsc-segmented-input {
  width: 1px;
}

.mbsc-range-label-clear {
  position: absolute;
  top: 50%;
}

/* Tabs */
.mbsc-datepicker-tab-wrapper {
  position: relative;
  flex-wrap: wrap;
  justify-content: center;
  overflow: hidden;
}

.mbsc-datepicker-tab {
  visibility: hidden;
  max-width: 100%;
  align-items: center;
  justify-content: center;
}

.mbsc-datepicker-tab-expand {
  height: 100%;
}

.mbsc-datepicker-tab-active {
  visibility: visible;
}

.mbsc-datepicker-time-modal {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  max-width: none;
  height: 100%;
}

.mbsc-datepicker .mbsc-calendar-slide {
  padding: 0 0.5em;
}

.mbsc-ios.mbsc-datetime-year-wheel .mbsc-scroller-wheel-item {
  min-width: 3.8em;
}
.mbsc-ios.mbsc-datetime-month-wheel .mbsc-scroller-wheel-item {
  text-align: left;
}
.mbsc-ios.mbsc-datetime-day-wheel .mbsc-scroller-wheel-item, .mbsc-ios.mbsc-datetime-hour-wheel .mbsc-scroller-wheel-item, .mbsc-ios.mbsc-datetime-date-wheel .mbsc-scroller-wheel-item {
  text-align: right;
}

.mbsc-ios.mbsc-timegrid-item {
  line-height: 2em;
  margin: 0.5em 0.625em;
  border-radius: 2em;
  font-size: 0.875em;
}
.mbsc-ios.mbsc-timegrid-item.mbsc-selected, .mbsc-ios.mbsc-timegrid-item:hover, .mbsc-ios.mbsc-timegrid-item:focus {
  font-size: 1em;
  margin: 0 0.25em;
  outline: none;
}

.mbsc-ios.mbsc-timegrid-item:hover, .mbsc-ios.mbsc-timegrid-item:focus {
  background: rgba(0, 122, 255, 0.3);
}
.mbsc-ios.mbsc-timegrid-item.mbsc-selected {
  background: #007aff;
  color: white;
}

.mbsc-ios-dark.mbsc-timegrid-item:hover, .mbsc-ios-dark.mbsc-timegrid-item:focus {
  background: rgba(255, 159, 10, 0.3);
}
.mbsc-ios-dark.mbsc-timegrid-item.mbsc-selected {
  background: #ff9f0a;
  color: white;
}

.mbsc-datepicker-control-calendar .mbsc-datepicker-tab-timegrid {
  min-height: 10em;
}

.mbsc-datepicker-tab-timegrid {
  min-height: 19em;
}

.mbsc-timegrid-item.mbsc-disabled {
  pointer-events: none;
  opacity: 0.2;
}

.mbsc-datepicker-tab-timegrid.mbsc-datepicker-tab {
  position: relative;
  overflow: auto;
  align-items: flex-start;
  width: 18.5em;
}

.mbsc-timegrid-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: table;
  table-layout: fixed;
  width: 100%;
}

.mbsc-timegrid-row {
  display: table-row;
}

.mbsc-timegrid-cell {
  display: table-cell;
  position: relative;
}
.mbsc-timegrid-cell.mbsc-disabled {
  cursor: not-allowed;
}

.mbsc-timegrid-item {
  vertical-align: middle;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
}